<template lang="pug">
	.form-group.fg-publisher
		.d-flex.justify-content-between
			label Publishers ({{ visiblePublisherOptions.length }})
				b-checkbox(switch="", v-model="publisherOptionsArchive")
			button.btn(id="popover-legal-pub", ref="button", :style="legalAvailable ? 'padding: 0 5px' : 'display: none'", type="button")
				i.la.la-gavel.text-muted
		multiselect(:multiple="multiple", :options="visiblePublisherOptions",
			:value="value", @input="onChange",
			track-by="v", label="t", deselect-label="", select-label="", :show-labels="multiple")
		b-popover(target="popover-legal-pub", v-if="multiple", triggers="click", :show.sync="popoverShow", placement="auto", container="my-container", ref="popover")
			template(#title)
				b-button(@click="onClosePopover", class="close", aria-label="Close")
					span.d-inline-block(aria-hidden="true") &times;
				| Legal Entity
			.form-group.no-label
				multiselect(:multiple="false", :options="legalOptions", :value="legal_entity_id", track-by="v", label="t", deselect-label="", select-label="", @input="selectLegal")

</template>
<script>
import Vue from 'vue';

export default {
  name: 'SelectPublisher',
  props: {
    value: {
      type: [Array],
    },
    multiple: {
      type: [Boolean],
      default() {
        return true;
      },
    },
  },
  computed: {
    visiblePublisherOptions() {
      return this.publisherOptions.filter((a) => {
        return this.publisherOptionsArchive || a.status;
      });
    },
  },
  data() {
    return {
      publisherOptions: [],
      publisherOptionsArchive: false,
      legalOptions: [],
      legal_entity_id: null,
      legalAvailable: false,
      popoverShow: false,
    };
  },
  methods: {
    onChange(value) {
      this.$emit('input', value);
    },
    selectLegal(legal) {
      let selected = this.publisherOptions.filter((o) => o.legal_entity_id === legal.v);
      this.$emit('input', selected);
      this.popoverShow = false;
    },
    onClosePopover() {
      this.popoverShow = false;
    },
  },
  async mounted() {
    this.publisherOptions = await Vue.ovData.publisher.getOptions(true);
    this.legalOptions = await Vue.ovData.legalEntity.getOptions(true);
    this.legalAvailable = this.legalOptions.length > 0;
  },
};
</script>
