<script>
import { isObject } from '../lib/objects';
import { formatDate } from '../lib/datetime';

export default {
  name: 'Filters',
  props: {
    currentFilters: {
      type: Object,
      default: () => ({}),
    },
    defaultFilters: {
      type: Object,
      default: () => ({}),
    },
    pageName: {
      type: String,
      default: '',
    },
    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const advertisersList = {
      status: {
        undefined: 'All',
        0: 'Inactive',
        1: 'Active Only',
      },
    };
    const offersList = {
      status: {
        undefined: 'All',
        draft: 'Draft',
        live: 'Live',
        paused: 'Paused',
        cancelled: 'Cancelled',
      },
      validation_status: {
        undefined: 'All',
        optional: 'Optional',
        all_open: 'All Open',
        valid: 'Valid',
        api_error: 'API Error',
        required_valid: 'Required (Valid)',
        required_invalid: 'Required (Invalid)',
        required_new: 'Required (New)',
      },
      platform: {
        undefined: 'All',
        android: 'Android',
        ios: 'iOS',
        mobile_web: 'Mobile Web',
        desktop: 'Desktop',
        empty: 'Empty',
      },
      // need more fields here
    };
    const externalOffersList = {};
    const publishersList = {
      status: {
        undefined: 'All',
        0: 'Inactive',
        1: 'Active Only',
      },
      media_type: {
        undefined: 'All',
        dsp: 'DSP',
        rewarded: 'REWARDED',
      },
    };
    const placementsList = {
      placement_status: {
        undefined: 'All',
        draft: 'Draft',
        live: 'Live',
        paused: 'Paused',
        cancelled: 'Cancelled',
      },
      offer_status: {
        undefined: 'All',
        draft: 'Draft',
        live: 'Live',
        paused: 'Paused',
        cancelled: 'Cancelled',
      },
    };
    const conversions = {};
    const publisherConversions = {
      report_status: {
        undefined: 'All',
        reported: 'Reported Only',
        not_reported: 'Not Reported',
      },
    };
    const events = {};
    const postbackLog = {
      status: {
        undefined: 'All',
        0: 'Pending',
        1: 'Success',
        2: 'Retry',
        3: 'Error',
        5: 'Invalid Secret',
      },
      advanced_privacy: {
        '-1': 'All',
        0: 'Non-AP Only',
        1: 'Advanced Privacy Only',
      },
      type: {
        all: 'All',
        conversion: 'Conversions',
        event: 'Events',
        reject: 'Rejected',
      },
    };
    const publisherPostbackLog = {
      status: {
        undefined: 'All',
        0: 'Pending',
        1: 'Sent',
        2: 'Retry',
        3: 'Failed',
      },
      type: {
        all: 'All',
        conversion: 'Conversions Only',
        event: 'Events Only',
        reject: 'Rejected Only',
      },
    };
    const supportTicketsList = {
      generalStatus: {
        null: 'All',
        new: 'New',
        pending_client: 'Awaiting Reply',
        open: 'Open',
        closed: 'Closed',
        cancelled: 'Cancelled',
      }
    }
    const visibleValuesMap = {
      advertisersList,
      offersList,
      externalOffersList,
      publishersList,
      placementsList,
      conversions,
      publisherConversions,
      events,
      postbackLog,
      publisherPostbackLog,
      supportTicketsList,
    };
    return { visibleValuesMap };
  },
  methods: {
    onSubmit() {
      this.$emit('submit');
      this.onClose();
    },
    onClose() {
      this.$emit('close-filters');
    },
    /** @param {string} key, @param {any} value */
    removeFilter(key, value) {
      /**
       * @param {string} key
       * @returns {(item: { v: string | number; t: string }) => boolean}
       */
      const filterAccordingKey = (key) => (item) => {
        switch (key) {
          case 'placement_id':
            return item.v !== value.id;
          default:
            return item.v !== value;
        }
      };

      if (Array.isArray(this.currentFilters[key])) {
        const newValue = this.currentFilters[key].filter(filterAccordingKey(key));

        if (newValue.length === 0) {
          this.$set(this.currentFilters, key, this.defaultFilters[key]);
          this.$emit('update:currentFilters', this.currentFilters);
          this.$emit('submit');
          return;
        }

        this.$set(this.currentFilters, key, newValue);
        this.$emit('update:currentFilters', this.currentFilters);
        this.$emit('submit');
        return;
      }

      this.$set(this.currentFilters, key, this.defaultFilters[key]);
      this.$emit('update:currentFilters', this.currentFilters);
      this.$emit('submit');
    },
  },
  computed: {
    /** Difference between filters and their default values */
    changedFilters() {
      /**
       * Label returned in each filter just in case for now as not used,
       * but might be used later if we wanna to add it to the active filters pills.
       */

      /**
       * Some of the array filter got different structure
       * @param {string} key
       * @param {any} value
       */
      const getEntriesInArray = (key, value) => {
        switch (key) {
          case 'placement_id':
            return {
              value: value.id,
              label: value.name,
            }
          default:
            return {
              value: value.v,
              label: value.t,
            }
        }
      };

      /**
       * Some of the object filter got different structure
       * @param {string} key
       * @param {Record<string, unknown>} value
       */
      const getEntriesInObject = (key, value) => {
        switch (key) {
          case 'datePicker': {
            const { dateRange: { startDate, endDate }, timezone } = value;
            const startDateFormatted = formatDate(startDate);
            const endDateFormatted = formatDate(endDate);
            const timezoneFormatted = parseInt(timezone) > 0 ? `+${timezone}` : timezone;
            return {
              value: `${timezoneFormatted} ${startDateFormatted} - ${endDateFormatted}`,
              label: 'Date Range',
            }
          }
          case 'country': {
            return {
              value: value.name,
              label: 'Country',
            }
          }
          default:
            throw new Error('Unknown object filter');
        }
      };

      /**
       * Returns multiple values from array field filter
       * @param {string} key
       * @param {Array<{ v: string | number; t: string }>} array
       */
      const getFromArray = (key, array) => {
        return array.map((item) => {
          const { value, label } = getEntriesInArray(key, item);
          const visibleValue = getVisibleValue(key, label);
          return {
            k: key,
            v: value,
            vv: visibleValue,
            l: label,
          };
        });
      };

      /**
       * 
       * @param {string} key 
       * @param {Record<string, unknown>} obj 
       */
      const getFromObject = (key, obj) => {
        const { value, label } = getEntriesInObject(key, obj);
        const visibleValue = getVisibleValue(key, value);
        return {
          k: key,
          v: value,
          vv: visibleValue,
          l: label,
        };
      };

      /**
       * Returns text that will be displayed in the active filters
       * @param {string} key
       * @param {any} value
       */
      const getVisibleValue = (key, value) => {
        const pageMap = this.visibleValuesMap[this.pageName];
        if (!pageMap) {
          return value;
        }
        const visibleValueKey = pageMap[key];
        if (visibleValueKey) {
          return visibleValueKey[value];
        }
        return value;
      };

      return Object.keys(this.currentFilters)
        .filter((key) => this.currentFilters[key] !== this.defaultFilters[key])
        .map((key) => {
          const value = this.currentFilters[key];

          if (Array.isArray(value)) {
            return getFromArray(key, value);
          }

          if (isObject(value)) {
            return getFromObject(key, value);
          }

          const visibleValue = getVisibleValue(key, value);
          const label = key;
          return {
            k: key,
            v: value,
            vv: visibleValue,
            l: label,
          }
        }).flat();
    },
  },
};
</script>

<template lang="pug">
div
  .mobile.mb-2
    .background-overlay.fixed-top.w-100.h-100(@click="onClose()", :class="{ opened: isOpened, closed: !isOpened }")

    .outer-elements.d-flex
      .active-filters.d-flex.gap-1.flex-wrap
        .active-filter-pill.d-flex.gap-1(v-for="filter in changedFilters" :key="filter.k")
          i.la.la-lg.la-times-circle(@click="removeFilter(filter.k, filter.v)")
          span {{ filter.vv }}

    .inner-elements
      .filters-content.fixed-bottom.w-100.bg-white.overflow-auto(:class="{ opened: isOpened, closed: !isOpened }")
        .heading.d-flex.align-items-center.justify-content-center.mb-2.bg-white.border-bottom
          h5.m-0 Filters
          .position-absolute.close-circle-icon
            i.la.la-2x.la-times-circle(@click="onClose()")
        form.form.form-inline.ov-filters.expanded.pl-2.pr-2.pt-2(@submit.prevent="onSubmit")
          slot

  .desktop
    form.form.form-inline.ov-filters(@submit.prevent="onSubmit")
      slot
</template>

<style lang="scss" scoped>
@keyframes slideInUp {
  0% {
    bottom: -75%;
    opacity: 0;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes slideOutDown {
  0% {
    bottom: 0;
    opacity: 1;
  }

  100% {
    bottom: -75%;
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.background-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;

  &.opened {
    animation: fadeIn 0.3s;
  }

  &.closed {
    animation: fadeOut 0.3s;
    display: none;
  }
}

.heading {
  position: sticky;
  top: 0;
  height: 50px;
  z-index: 2;

  .close-circle-icon {
    top: 0;
    right: 0;
    padding: 13px;
  }
}

.active-filter-pill {
  background-color: #28a745;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  color: white;
  height: 27px;

  i {
    line-height: 1.15em;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
}

.filters-content {
  -webkit-box-shadow: 0px -5px 15px 4px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0px -5px 15px 4px rgb(0 0 0 / 20%);
  box-shadow: 0px -5px 15px 4px rgb(0 0 0 / 20%);
  border-radius: 10px 10px 0 0;
  max-height: 80%;
  overflow-x: hidden !important;

  &.opened {
    animation: slideInUp 0.3s;
  }

  &.closed {
    animation: slideOutDown 0.5s;
    display: none;
  }
}

.submit-filters-button {
  @media (max-width: 768px) {
    display: flex !important;
    flex-direction: row-reverse;
  }
}
</style>
