<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false", loader="spinner")
		h4 {{ title }}
		.inner-form-full-width
			ov-date-time-picker(v-model="filters.datePicker")
			.form-group.no-label
				button.btn.btn-primary(type="button", @click="loadData()")
					i.la.la-refresh
		apexchart(:type="type", :options="chartOptions", :series="chartSeries")
</template>
<script>
import moment from 'moment';

export default {
  name: 'ReportGraph',

  props: ['title', 'type', 'data'],
  computed: {
    chartOptions() {
      if (!this.queryResponse) {
        return {};
      }
      let options = {
        responsive: [
          {
            breakpoint: undefined,
            options: {},
          },
        ],
      };
      switch (this.type) {
        case 'donut':
          options.labels = this.queryResponse.records.map((r) =>
            r[this.data.dimensions[0]] ? r[this.data.dimensions[0]].name : '',
          );
          break;
        case 'line':
          if (this.data.group_timeframe === 'day') {
            options.xaxis = {
              categories: this.queryResponse.records.map((r) => r.group_date),
            };
          } else if (this.data.group_timeframe === 'hour') {
            console.log('Not supported yet');
          } else {
            options.xaxis = {
              categories: this.queryResponse.records.map((r) =>
                r[this.data.dimensions[0]] ? r[this.data.dimensions[0]].name : '',
              ),
            };
          }
          break;
      }
      return options;
    },
    chartSeries() {
      if (!this.queryResponse) {
        return [];
      }
      this.queryResponse.records.map((r) => {
        if (this.data.transformData && this.data.transformData.metrics) {
          for (let k in this.data.transformData.metrics) {
            r[k].v = this.data.transformData.metrics[k](r[k].v);
          }
        }
      });
      switch (this.type) {
        case 'donut': {
          let values = this.queryResponse.records.map((r) => r[this.data.metrics[0]].v);
          return values;
        }
        case 'line': {
          let series = [];
          this.data.metrics.forEach((m) => {
            let lbl = m;
            if (
              this.data.transformLabel &&
              this.data.transformLabel.metrics &&
              this.data.transformLabel.metrics[m]
            ) {
              lbl = this.data.transformLabel.metrics[m](lbl);
            }
            let serie = {
              name: lbl,
              data: [],
            };
            this.queryResponse.records.forEach((r) => {
              serie.data.push(r[m].v);
            });
            series.push(serie);
          });
          return series;
        }
      }
      return [];
    },
  },
  data() {
    return {
      busy: false,
      queryResponse: null,
      filters: {
        datePicker: {
          timezone: 0,
          dateRange: {
            startDate: null,
            endDate: null,
          },
        },
      },
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.data.date_start) {
        this.filters.datePicker.dateRange.startDate = this.data.date_start;
      }
      if (this.data.date_end) {
        this.filters.datePicker.dateRange.endDate = this.data.date_end;
      }
      this.loadData();
    }, 1000);
  },
  methods: {
    async loadData() {
      this.busy = true;
      let params = {
        export: 0,
        page: 1,
        page_size: this.data.limit,
        group_timeframe: this.data.group_timeframe || '',
        timezone: this.filters.datePicker.timezone,
        date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
        sort_col: this.data.sort[0],
        sort_dir: this.data.sort[1],
        metrics: this.data.metrics,
        dimensions: this.data.dimensions,
        filters: {
          subsource_data: 0,
          has_conversions: 0,
          ...this.data.filters,
        },
      };
      let resp = await this.$ovReq.post('reports/query', params);
      this.queryResponse = resp;
      this.busy = false;
    },
  },
};
</script>
