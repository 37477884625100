<template lang="pug">
	.form-group(:class="{'fg-offer': !complex, 'fg-complex-offer': complex}")
		.d-flex.justify-content-between
			label {{ complex ? 'Complex Offers' : 'Offers' }}
		multiselect(:multiple="multiple", :options="offerOptions", :value="value",
			:limit="1", group-label="name", group-values="items", :group-select="false", @input="onChange",
			track-by="id", label="name", deselect-label="", select-label="", @search-change="searchOffer",
			:loading="offerOptionsLoading", :internal-search="false", @open="selectOffersFilter")

</template>
<script>
import Vue from 'vue';
import SelectOffersModal from '../../views/modals/SelectOffersModal';

export default {
  name: 'SelectOffer',
  props: {
    value: {
      type: [Array],
    },
    complex: {
      type: [Boolean],
      default() {
        return false;
      },
    },
    multiple: {
      type: [Boolean],
      default() {
        return true;
      },
    },
  },

  data() {
    return {
      offerOptionsLoading: false,
      offerOptions: [],
    };
  },
  methods: {
    onChange(value) {
      this.$emit('input', value);
    },
    async searchOffer(query) {
      this.offerOptionsLoading = true;
      try {
        let filters = { keyword: query };
        if (this.complex) {
          filters.offer_type = 'complex';
        }
        this.offerOptions = await Vue.ovData.offer.getOptions(filters);
      } catch (e) {
        this.$ovNotify.error(e);
      }
      this.offerOptionsLoading = false;
    },
    selectOffersFilter() {
      let selectedOffers = this.value;
      this.$modal.show(
        SelectOffersModal,
        {
          selectedOffers,
          complex: this.complex,
          multiple: this.multiple,
        },
        {
          height: '90%',
          width: '90%',
          minWidth: 500,
          //maxWidth: '100%',
        },
        {
          'before-close': (/*event*/) => {
            this.$emit('input', selectedOffers);
          },
        },
      );
    },
  },
};
</script>
