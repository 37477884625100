<template lang="pug">
	.icon
		img(:alt="mmp", :src="iconUrl", v-if="iconUrl", :class="iconClass")
		span.mmp-text(v-else) {{ mmp }}
</template>
<style lang="scss">
.icon {
  text-align: center;
  /*max-width: 100px;*/
  width: 60px;
  img {
    max-width: 100%;
    &.svg {
      filter: invert(61%) sepia(59%) saturate(4996%) hue-rotate(153deg) brightness(93%)
        contrast(82%);
    }
  }
  .mmp-text {
    text-transform: uppercase;
    font-weight: bold;
  }
  @media (max-width: 767px) {
    padding: 10px 5px 0 5px;
    img {
      max-height: 50px;
    }
  }
}
</style>
<script>
const basePath = '/assets/misc/mmp/';
export default {
  name: 'MmpIcon',
  props: {
    mmp: {
      type: String,
    },
  },
  computed: {
    iconClass() {
      return ['adjust', 'singular', 'branch'].includes(this.mmp) ? 'svg' : '';
    },
    iconUrl() {
      switch (this.mmp) {
        case 'appsflyer':
          return basePath + 'appsflyer-logo.png';
        case 'kochava':
          return basePath + 'kochava-logo.png';
        case 'adjust':
          return basePath + 'adjust-logo-white.svg';
        case 'singular':
          return basePath + 'singular-logo-white.svg';
        case 'branch':
          return basePath + 'branch-logo.svg';
        default:
          return '';
      }
    },
  },
};
</script>
