import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY');
  }
});

Vue.filter('formatDateEU', function (value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY');
  }
});

Vue.filter('formatDateWithTime', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm A');
  }
});
