<template lang="pug">
	th
		.th-body(@click="toggleSort()")
			div.th-body-inner
				slot
			i.la(:class="iconClass")
</template>
<script>
import Vue from 'vue';
export default {
  name: 'SortColumn',
  props: {
    field: String,
    sorter: Object,
  },
  computed: {
    iconClass() {
      let s =
        'la-sort-' +
        (this.sorter.field == this.field && this.sorter.direction === 'asc'
          ? 'up'
          : this.sorter.field == this.field && this.sorter.direction === 'desc'
            ? 'down'
            : '');
      return s;
    },
  },
  data() {
    return {};
  },
  methods: {
    toggleSort() {
      if (this.sorter.field === this.field) {
        Vue.set(this.sorter, 'direction', this.sorter.direction === 'asc' ? 'desc' : 'asc');
      }
      Vue.set(this.sorter, 'field', this.field);
      this.$emit('sort');
    },
  },
};
</script>
