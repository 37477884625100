import Spinner from './spinner.vue';
import Dots from './dots.vue';
import Bars from './bars.vue';
import Ovalio from './ovalio.vue';

export default {
  Spinner,
  Dots,
  Bars,
  Ovalio,
};
