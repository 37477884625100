<template lang="pug">
  .template-container(v-if="value")
    toggle-button(:value="value.mode !== 'disabled'", @change="toggleMode",
        :labels="{checked: 'Active', unchecked: 'Inactive'}",
        :width="100", :height="26", :font-size="9")
    div(v-if="value.mode !== 'disabled'")
      frequency-cap-obj(type="click", :value="value.click", @change="updateValue", v-if="!combinedMode", @mode="updateMode")
      frequency-cap-obj(type="impression", :value="value.impression", @change="updateValue", v-if="!combinedMode", @mode="updateMode")
      frequency-cap-obj(type="combined", :value="value.combined", @change="updateValue", v-if="combinedMode", @mode="updateMode")
</template>

<style lang="scss"></style>
<script>
import FrequencyCapObj from './FrequencyCapObj';
import Vue from 'vue';

export default {
  name: 'FrequencyCap',
  components: {
    FrequencyCapObj,
  },
  props: ['value'],
  methods: {
    toggleMode() {
      const { mode } = this.value;
      if (mode !== 'disabled') {
        this.updateMode('disabled');
      } else {
        this.updateMode('combined');
      }
    },
    updateMode(mode) {
      Vue.set(this.value, 'mode', mode);
    },

    updateValue(value) {
      const { cap, timeframe, fingerprint, type } = value;
      const typeNewValue = { cap, timeframe, fingerprint };
      const newValue = JSON.parse(JSON.stringify(this.value));
      newValue[type] = typeNewValue;
      this.$emit('input', newValue);
    },
  },

  computed: {
    combinedMode() {
      return this.value && this.value.mode === 'combined';
    },
  },
};
</script>
