/** @typedef {import('../components/SupplyManagement/SupplyManagement.vue').Data} Data */
/** @typedef {import('../components/SupplyManagement/SupplyManagement.vue').MediaTypeSummaryData} MediaTypeSummaryData */
/** @typedef {import('../components/SupplyManagement/SupplyManagement.vue').SummaryData} SummaryData */
/** @typedef {Data | MediaTypeSummaryData | SummaryData} Record */

/** @typedef {'cr' | 'cvr' | 'pa_rejections_pct' | 'reject_pct' | 'installs' | 'conversions' | 'passed_clicks' | 'passed_impressions'} MetricName */

/** @param {Pick<Record, 'installs' | 'passed_clicks'>} data */
export function getCr({ installs, passed_clicks }) {
  const result = (installs / passed_clicks) * 100;
  return Number.isFinite(result) ? result : 0;
}

/** @param {Pick<Record, 'installs' | 'passed_mmp_clicks'>} data */
export function getMmpCr({ installs, passed_mmp_clicks }) {
  const result = (installs / passed_mmp_clicks) * 100;
  return Number.isFinite(result) ? result : 0;
}

/** @param {Pick<Record, 'installs' | 'conversions'>} data */
export function getCvr({ installs, conversions }) {
  const result = (conversions / installs) * 100;
  return Number.isFinite(result) ? result : 0;
}

/** @param {Pick<Record, 'reject_events' | 'installs'>} data */
export function getRejectedPercentage({ reject_events, installs }) {
  const result = (reject_events / (reject_events + installs)) * 100;
  return Number.isFinite(result) ? result : 0;
}

/** @param {Pick<Record, 'pa_rejections' | 'installs'>} data */
export function getPaRejectedPercentage({ pa_rejections, installs }) {
  const result = (pa_rejections / installs) * 100;
  return Number.isFinite(result) ? result : 0;
}

/** @param {Pick<Record, 'reject_events' | 'installs'>} data */
export function getRejectedAndInstalls({ reject_events, installs }) {
  return reject_events + installs;
}

/** @param {Pick<Record, 'blocked_clicks' | 'passed_clicks' | 'passed_impressions'>} data */
export function getBlockedPercentage({ blocked_clicks, passed_clicks, passed_impressions }) {
  const result = (blocked_clicks / (passed_clicks + passed_impressions)) * 100;
  return Number.isFinite(result) ? result : 0;
}

const metricToFormulaMap = {
  cr: getCr,
  cvr: getCvr,
  mmp_cr: getMmpCr,
  reject_pct: getRejectedPercentage,
  pa_rejections_pct: getPaRejectedPercentage,
  rejected_and_install: getRejectedAndInstalls,
  blocked_percent: getBlockedPercentage,
};

/**
 * Get the formula for the given metric or just return the value of the metric
 * @param {MetricName} metric
 * @returns {(record: Record) => number}
 */
export function getMetricFormula(metric) {
  return metricToFormulaMap[metric] || ((record) => record[metric]);
}
