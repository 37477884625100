<template lang="pug">
  .widget-container
    .widget(v-if="!isReadOnly")
      .widget-body
        .form-group
          label Geo Library
          select.form-control(v-model="geo_targeting_mode", v-on:change="emitChange")
            option(v-for="o in geoTargetingModeOptions" :value="o.v") {{ o.t }}

    .widget(v-if="geo_targeting_mode==='ip2location'")
      .widget-header
        h2.title GEO Restrictions (IP2Location)
      .widget-body
        h3.widget-section-title Geo Whitelist
        .form-group
          label Countries
          multiselect(:multiple="true", :options="wl_countryOptions_i2l", v-model="targeting_i2l.countries.whitelist",
            track-by="code", label="name", deselect-label="", select-label="", @input="targetingOnChangeCountryWL()")

        div.targeting-country-block(v-for="cn in targeting_i2l.countries.whitelist")
          h4 [{{cn.code}}] {{ cn.name }}
          .upload-spinner(v-if="!wl_cityOptions_i2l[cn.code] || !wl_regionOptions_i2l[cn.code]")
            i.la.la-refresh.la-spin
          div(v-if="wl_cityOptions_i2l[cn.code] && wl_regionOptions_i2l[cn.code]")
            .form-group
              label Regions / States
              multiselect(:multiple="true", :options="wl_regionOptions_i2l[cn.code]", v-model="cn.regions",
                track-by="id", label="name", deselect-label="", select-label="", @input="onRegionSelect($event, cn.code, 'wl', 'i2l')")
            .form-group
              label Cities
              multiselect(:multiple="true", :options="wl_regionCityOptions_i2l[cn.code] || wl_cityOptions_i2l[cn.code]", v-model="cn.cities",
                track-by="id", label="name", deselect-label="", select-label="", v-on:change="emitChange")

        hr

        h3.widget-section-title Geo Blacklist
        .form-group
          label Countries
          multiselect(:multiple="true", :options="bl_countryOptions_i2l", v-model="targeting_i2l.countries.blacklist",
            track-by="code", label="name", deselect-label="", select-label="", @input="targetingOnChangeCountryBL()")

        div.targeting-country-block(v-for="cn in targeting_i2l.countries.blacklist")
          h4 [{{cn.code}}] {{ cn.name }}
          .upload-spinner(v-if="!bl_cityOptions_i2l[cn.code] || !bl_regionOptions_i2l[cn.code]")
            i.la.la-refresh.la-spin
          div(v-if="bl_cityOptions_i2l[cn.code] && bl_regionOptions_i2l[cn.code]")
            .form-group
              label Regions / States
              multiselect(:multiple="true", :options="bl_regionOptions_i2l[cn.code]", v-model="cn.regions",
                track-by="id", label="name", deselect-label="", select-label="", @input="onRegionSelect($event, cn.code, 'bl', 'i2l')", v-on:change="emitChange")
            .form-group
              label Cities
              multiselect(:multiple="true", :options="bl_regionCityOptions_i2l[cn.code] || bl_cityOptions_i2l[cn.code]", v-model="cn.cities",
                track-by="id", label="name", deselect-label="", select-label="", v-on:change="emitChange")

    .widget(v-if="geo_targeting_mode==='digitalelement'")
      .widget-header
        h2.title GEO Restrictions (DigitalElement)
        i.la.la-info-circle.ml-1(v-if="isReadOnly", v-b-tooltip.hover.right, title="Merged geo targeting of the underlying offers. Currently only whitelist merging is supported.", style="font-size: 2rem; display: flex; align-items: center;")
      .widget-body
        b-form-checkbox(v-model="lock_geo_targeting", @change="emitChange", v-if="isAutomaticOffer") Lock geo targeting
        h3.widget-section-title Geo Whitelist
        .form-group
          label Countries
          multiselect(:multiple="true", :options="wl_countryOptions_de", v-model="targeting_de.countries.whitelist",
            track-by="code2", label="name", deselect-label="", select-label="", @input="geoTargetingOnChangeCountryWL()",
            :disabled="isReadOnly")

        div.targeting-country-block(v-for="cn in targeting_de.countries.whitelist")
          h4 [{{cn.code2}}] {{ cn.name }}
          .upload-spinner(v-if="!wl_cityOptions_de[cn.code2] || !wl_regionOptions_de[cn.code2]")
            i.la.la-refresh.la-spin
          div(v-if="wl_cityOptions_de[cn.code2] && wl_regionOptions_de[cn.code2]")
            .form-group
              label Regions / States
              multiselect(:multiple="true", :options="wl_regionOptions_de[cn.code2]", v-model="cn.regions",
                track-by="id", label="name", deselect-label="", select-label="", @input="onRegionSelect($event, cn.code2, 'wl', 'de')",
                :disabled="isReadOnly")
            .form-group
              label Cities
              multiselect(:multiple="true", :options="wl_regionCityOptions_de[cn.code2] || wl_cityOptions_de[cn.code2]", v-model="cn.cities",
                track-by="id", label="name", deselect-label="", select-label="", :disabled="isReadOnly")

        hr

        h3.widget-section-title Geo Blacklist
        .form-group
          label Countries
          multiselect(:multiple="true", :options="bl_countryOptions_de", v-model="targeting_de.countries.blacklist",
            track-by="code2", label="name", deselect-label="", select-label="", @input="geoTargetingOnChangeCountryBL()",
            :disabled="isReadOnly")

        div.targeting-country-block(v-for="cn in targeting_de.countries.blacklist")
          h4 [{{cn.code2}}] {{ cn.name }}
          .upload-spinner(v-if="!bl_cityOptions_de[cn.code2] || !bl_regionOptions_de[cn.code2]")
            i.la.la-refresh.la-spin
          div(v-if="bl_cityOptions_de[cn.code2] && bl_regionOptions_de[cn.code2]")
            .form-group
              label Regions / States
              multiselect(:multiple="true", :options="bl_regionOptions_de[cn.code2]", v-model="cn.regions",
                track-by="id", label="name", deselect-label="", select-label="", @input="onRegionSelect($event, cn.code2, 'bl', 'de')",
                :disabled="isReadOnly")
            .form-group
              label Cities
              multiselect(:multiple="true", :options="bl_regionCityOptions_de[cn.code2] || bl_cityOptions_de[cn.code2]", v-model="cn.cities",
                track-by="id", label="name", deselect-label="", select-label="", :disabled="isReadOnly")


</template>

<style lang="scss">
.targeting-country-block {
  margin-bottom: 15px;
  background: #f6f6f6;
  border-radius: 15px;
  padding: 20px;

  h4 {
    font-size: 14px;
  }
}
</style>

<script>
import Vue from 'vue';

export default {
  name: 'geo-targeting',
  props: {
    params: {
      type: Object,
      required: true,
    },
    isAutomaticOffer: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      targeting_de: null,
      targeting_i2l: null,
      geo_targeting_mode: '',
      lock_geo_targeting: false,

      wl_countryOptions_i2l: [],
      wl_regionOptions_i2l: {},
      wl_cityOptions_i2l: {},
      wl_regionCityOptions_i2l: {},

      bl_countryOptions_i2l: [],
      bl_regionOptions_i2l: {},
      bl_cityOptions_i2l: {},
      bl_regionCityOptions_i2l: {},

      wl_countryOptions_de: [],
      wl_regionOptions_de: {},
      wl_cityOptions_de: {},
      wl_regionCityOptions_de: {},

      bl_countryOptions_de: [],
      bl_regionOptions_de: {},
      bl_cityOptions_de: {},
      bl_regionCityOptions_de: {},

      geoTargetingModeOptions: [
        { v: 'ip2location', t: 'IP2Location' },
        { v: 'digitalelement', t: 'DigitalElement' },
      ],
    };
  },

  async mounted() {
    this.targeting_i2l = this.params.targeting_i2l;
    this.targeting_de = this.params.targeting_de;
    this.geo_targeting_mode = this.params.geo_targeting_mode;
    this.lock_geo_targeting = this.params.lock_geo_targeting;

    if (!this.params.targeting_i2l || !this.params.targeting_i2l.countries) {
      this.targeting_i2l = {
        countries: { whitelist: [], blacklist: [] },
        regions: { whitelist: [], blacklist: [] },
        cities: { whitelist: [], blacklist: [] },
      };
    }
    if (!this.params.targeting_de || !this.params.targeting_de.countries) {
      this.targeting_de = {
        countries: { whitelist: [], blacklist: [] },
        regions: { whitelist: [], blacklist: [] },
        cities: { whitelist: [], blacklist: [] },
      };
    }
    await this.targetingOnChangeCountryWL();
    await this.targetingOnChangeCountryBL();
    await this.geoTargetingOnChangeCountryWL();
    await this.geoTargetingOnChangeCountryBL();

    await this.loadOptions();
  },
  methods: {
    async loadOptions() {
      let countryOptions_i2l = await Vue.ovData.country.getOptions(true);
      let countryOptions_de = await Vue.ovData.geo.country.getOptions();
      this.wl_countryOptions_i2l = countryOptions_i2l;
      this.bl_countryOptions_i2l = JSON.parse(JSON.stringify(countryOptions_i2l));
      this.wl_countryOptions_de = countryOptions_de;
      this.bl_countryOptions_de = JSON.parse(JSON.stringify(countryOptions_de));

      /** init city options */
      let lib = this.geo_targeting_mode === 'ip2location' ? 'i2l' : 'de';
      let targetingObj =
        this.geo_targeting_mode === 'ip2location' ? this.targeting_i2l : this.targeting_de;
      ['bl', 'wl'].forEach((mode) => {
        let field = mode === 'bl' ? 'blacklist' : 'whitelist';
        let countries = targetingObj.countries[field];
        countries.forEach((c) => {
          let countryCode = lib === 'i2l' ? c.code : c.code2;
          this.onRegionSelect(c.regions, countryCode, mode, lib);
        });
      });
    },

    onRegionSelect(selectedRegions, countryCode, mode, lib) {
      let regionHash = {};
      selectedRegions.forEach((r) => (regionHash[r.id] = true));
      Vue.set(
        this[mode + '_regionCityOptions_' + lib],
        countryCode,
        selectedRegions.length === 0
          ? null
          : this[mode + '_cityOptions_' + lib][countryCode].filter(
              (c) => !!regionHash[c.region_id],
            ),
      );
      this.emitChange();
    },

    async targetingOnChangeCountryWL() {
      for (let i = 0; i < this.targeting_i2l.countries.whitelist.length; i++) {
        let cn = this.targeting_i2l.countries.whitelist[i];
        if (!cn.regions) {
          Vue.set(cn, 'regions', []);
        }
        if (!cn.cities) {
          Vue.set(cn, 'cities', []);
        }
        if (!this.wl_regionOptions_i2l[cn.code]) {
          Vue.set(
            this.wl_regionOptions_i2l,
            cn.code,
            await this.$ovData.region.getOptions(true, cn.code),
          );
        }
        if (!this.wl_cityOptions_i2l[cn.code]) {
          Vue.set(
            this.wl_cityOptions_i2l,
            cn.code,
            await this.$ovData.city.getOptions(true, cn.code),
          );
        }
      }
      this.emitChange();
    },

    async targetingOnChangeCountryBL() {
      for (let i = 0; i < this.targeting_i2l.countries.blacklist.length; i++) {
        let cn = this.targeting_i2l.countries.blacklist[i];
        if (!cn.regions) {
          Vue.set(cn, 'regions', []);
        }
        if (!cn.cities) {
          Vue.set(cn, 'cities', []);
        }
        if (!this.bl_regionOptions_i2l[cn.code]) {
          Vue.set(
            this.bl_regionOptions_i2l,
            cn.code,
            await this.$ovData.region.getOptions(true, cn.code),
          );
        }
        if (!this.bl_cityOptions_i2l[cn.code]) {
          Vue.set(
            this.bl_cityOptions_i2l,
            cn.code,
            await this.$ovData.city.getOptions(true, cn.code),
          );
        }
      }
      this.emitChange();
    },

    async geoTargetingOnChangeCountryWL() {
      for (let i = 0; i < this.targeting_de.countries.whitelist.length; i++) {
        let cn = this.targeting_de.countries.whitelist[i];
        if (!cn.regions) {
          Vue.set(cn, 'regions', []);
        }
        if (!cn.cities) {
          Vue.set(cn, 'cities', []);
        }
        if (!this.wl_regionOptions_de[cn.code2]) {
          Vue.set(
            this.wl_regionOptions_de,
            cn.code2,
            await this.$ovData.geo.region.getOptions(true, cn.code2),
          );
        }
        if (!this.wl_cityOptions_de[cn.code2]) {
          Vue.set(
            this.wl_cityOptions_de,
            cn.code2,
            await this.$ovData.geo.city.getOptions(true, cn.code2),
          );
        }
      }
      this.emitChange();
    },

    async geoTargetingOnChangeCountryBL() {
      for (let i = 0; i < this.targeting_de.countries.blacklist.length; i++) {
        let cn = this.targeting_de.countries.blacklist[i];
        if (!cn.regions) {
          Vue.set(cn, 'regions', []);
        }
        if (!cn.cities) {
          Vue.set(cn, 'cities', []);
        }
        if (!this.bl_regionOptions_de[cn.code2]) {
          Vue.set(
            this.bl_regionOptions_de,
            cn.code2,
            await this.$ovData.geo.region.getOptions(true, cn.code2),
          );
        }
        if (!this.bl_cityOptions_de[cn.code2]) {
          Vue.set(
            this.bl_cityOptions_de,
            cn.code2,
            await this.$ovData.geo.city.getOptions(true, cn.code2),
          );
        }
      }
      this.emitChange();
    },

    emitChange() {
      this.$emit('update-geo', {
        targeting_i2l: this.targeting_i2l,
        targeting_de: this.targeting_de,
        geo_targeting_mode: this.geo_targeting_mode,
        lock_geo_targeting: this.lock_geo_targeting,
      });
    },
  },
};
</script>
