<script>
export default {
  name: 'ActivityLogTableDataPipe',
  props: {
    type: {
      type: [String],
    },
    value: {
      type: [String, Number, Array, Boolean, Object],
    },
  },
  computed: {
    pipeComponent() {
      if (!this.type) return null;
      return () => import(`./${this.type}.vue`);
    },
  },
};
</script>

<template lang="pug">
div
  span(v-if="!type") {{ value }}
  component(v-if="type" :is="pipeComponent" :value="value")
</template>
