<script>
export default {
  name: 'ClickRatio',
  props: {
    value: { type: [String, Number], },
    useClicksRatio: { type: Boolean, required: true }
  },
  data() {
    return {
      isInputActive: false,
      isInputInvalid: false,
    };
  },
  computed: {
    displayValue: {
      get: function () {
        if (this.isInputActive) {
          /** Cursor is inside the input field. unformat display value for user */
          return this.value.toString();
        } else {
          /** User is not modifying now. Format display value for user interface */
          return this.value + '%';
        }
      },
      /** @param {string} modifiedValue */
      set: function (modifiedValue) {
        /** Recalculate value after ignoring "$" and "," in user input */
        // eslint-disable-next-line no-useless-escape
        let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ''));
        /** Ensure that it is not NaN */
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.isInputInvalid = newValue < 0 || newValue > 100;

        /** Do not support more than 2 decimal places */ 
        newValue = parseFloat(newValue.toFixed(2));

        /** Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component */
        /** $emit the event so that parent component gets it */
        this.$emit('input', newValue);
      },
    },
    ratioText() {
      const percentage = this.value;
      if (!percentage) {
        return 'No change';
      }
      if (percentage < 0 || percentage > 100) {
        return 'Invalid value';
      }
      if (percentage === 100) {
        return 'Only Non-RW traffic';
      }
      if (percentage > 50) {
        const dspRatio = 1 / ((100 - percentage) / 100) - 1;
        return `${parseFloat(dspRatio.toFixed(2))} Non-RW clicks per RW click`;
      } else {
        const rwRatio = 1 / (percentage / 100) - 1;
        return `${parseFloat(rwRatio.toFixed(2))} RW clicks per Non-RW click`;
      }
    },
  },
  methods: {
    disable() {
      this.$emit('disable');
      this.$emit('input', 0);
    },
  }
};
</script>
<template lang="pug">
.form-group
  .d-flex.justify-content-between
    label Non-RW / Total Clicks
    b-checkbox(switch, :checked="useClicksRatio", @input="disable", v-b-tooltip, title="Caps should be fixed or automatic based on a traffic")
  input.form-control(type="text", placeholder="No change", v-model="displayValue", @blur="isInputActive=false", @focus="isInputActive=true")
  p.form-text.text-muted(:class="{invalid: isInputInvalid}") {{ ratioText }}
</template>

<style lang="scss">
p.form-text.invalid {
  color: #9b0000 !important;
}
</style>