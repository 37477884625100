<template lang="pug">
	div(v-if="value > 0") {{ value }}%
		div.text-muted(v-b-tooltip.hover.right, :title="getOddsRatioText") {{ getOddsRatio }}
</template>
<style lang="scss"></style>
<script>
export default {
  name: 'ImpressionRatioDisplay',
  props: {
    value: {
      type: [String, Number],
    },
  },
  computed: {
    getOddsRatioText() {
      let percentage = this.value;
      if (percentage === 100) {
        return 'Only impressions';
      }
      if (percentage > 50) {
        let clickRatio = 1 / ((100 - percentage) / 100) - 1;
        return `${parseFloat(clickRatio.toFixed(2))} impressions per click`;
      } else {
        let impressionRatio = 1 / (percentage / 100) - 1;
        return `${parseFloat(impressionRatio.toFixed(2))} clicks per impression`;
      }
    },

    getOddsRatio() {
      let percentage = this.value;
      if (!percentage || percentage <= 0 || percentage >= 100) {
        return '';
      }
      if (percentage > 50) {
        let clickRatio = 1 / ((100 - percentage) / 100) - 1;
        return `1/${parseFloat(clickRatio.toFixed(2))}`;
      } else {
        let impressionRatio = 1 / (percentage / 100) - 1;
        return `${parseFloat(impressionRatio.toFixed(2))}/1`;
      }
    },
  },
};
</script>
