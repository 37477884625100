<template lang="pug">
	.form-group.fg-placement
		label Placements
		multiselect(:multiple="true", :options="placementOptions", :value="value", @input="onChange",
			:limit="1", group-label="name", group-values="items", :group-select="false",
			track-by="id", label="name", deselect-label="", select-label="", @search-change="searchPlacement",
			:loading="placementOptionsLoading", :internal-search="false")

</template>
<script>
import Vue from 'vue';

export default {
  name: 'SelectPlacement',
  props: ['value'],

  data() {
    return {
      placementOptionsLoading: false,
      placementOptions: [],
    };
  },
  methods: {
    onChange(value) {
      this.$emit('input', value);
    },
    async searchPlacement(query) {
      this.placementOptionsLoading = true;
      try {
        this.placementOptions = await Vue.ovData.placement.getOptions({ keyword: query });
      } catch (e) {
        console.error(e);
      }
      this.placementOptionsLoading = false;
    },
  },
};
</script>
