import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

function storeBuilder(data) {
  return new Vuex.Store({
    state: {
      toggledSidebar: false,
      authToken: data.token || null,
      user: data.user || null,
    },
    getters: {
      user: (state) => {
        return state.user;
      },
    },
    mutations: {
      toggle(state, key) {
        console.log('TOGGLE');
        state[key] = !state[key];
      },
      SET_USER: (state, newValue) => {
        console.log('SET_USER', newValue);
        if (newValue) {
          state.user = newValue.user;
          state.authToken = newValue.token;
          localStorage.setItem('user', newValue.user);
          localStorage.setItem('authToken', newValue.token);
        } else {
          state.user = null;
          state.authToken = null;
          localStorage.removeItem('user');
          localStorage.removeItem('authToken');
        }
      },
    },
    actions: {
      setUser: ({ commit, state }, newValue) => {
        commit('SET_USER', newValue);
        return state.user;
      },
      closeSidebar: ({ state }) => {
        state.toggledSidebar = false;
        return state.toggledSidebar;
      },
    },
  });
}

export default storeBuilder;
