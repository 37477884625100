<template lang="pug">
span
  label Year
  select.form-control(v-model="year")
    option(v-for="year in years", :value="year") {{ year }}
</template>
<script>
export default {
  name: 'OvDateTimePicker',
  props: {
    value: {
      type: Number,
    },
  },
  data() {
    const currentYear = new Date().getFullYear();
    const startYear = 1900;
    const years = [startYear];
    for (let i = startYear; i <= currentYear; i++) {
      years.push(i);
    }

    return { years };
  },
  computed: {
    year: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  }
};
</script>
