<template lang="pug">
	div(v-if="showModal")
		.modal-mask
			.modal-wrapper
				.modal-dialog
					.modal-content
						div test
						component(:is="modalContentComponent")
</template>
<script>
export default {
  name: 'OvModal',
  props: {
    modalContentComponent: Object,
  },
  data() {
    return {
      showModal: true,
    };
  },
};
</script>
