<script>
import Vue from 'vue';

export default {
  name: 'SupplyManagementCapsTableSummaryCell',
  props: {
    target: { type: Number, required: true },
    actual: { type: Number, required: true },
    expected: { type: Number, required: false },
  },
  computed: {
    visibleActual() {
      return Vue.filter('shortNumber')(this.actual);
    },
    visibleTarget() {
      if (this.target === 0) {
        return '♾️'
      }
      return Vue.filter('shortNumber')(this.target);
    },
    visibleExpected() {
      return Vue.filter('shortNumber')(this.expected);
    },
    progress() {
      if (this.target === 0) {
        return 0;
      }
      return Math.min((this.actual / this.target) * 100, 100);
    },
    showProgressBar() {
      return {
        background: `linear-gradient(to right, #ccffcc ${this.progress}%, transparent ${this.progress}%)`
      };
    },
  },
  data() {
    return {
      shouldShowTarget: false,
    };
  },
};
</script>

<template lang="pug">
.d-flex.gap-1
  input.cell-input.p-0.form-control.font-weight-bold(
    :value="visibleActual", 
    :style="showProgressBar", 
    :class="{'text-muted': visibleActual === 0}"
    readonly
    @mouseover="shouldShowTarget = true"
    v-if="!shouldShowTarget"
  )
  input.cell-input.p-0.form-control.font-weight-bold(
    :value="visibleTarget",
    :style="showProgressBar",
    readonly
    @mouseleave="shouldShowTarget = false"
    v-if="shouldShowTarget"
  )
  input.cell-input.p-0.form-control.font-weight-bold(v-if="expected !== undefined", :value="visibleExpected", readonly)
</template>

<style lang="scss" scoped>
.cell-input {
  text-align: center;
  flex: 1;
  background-color: transparent;
}
</style>