<template lang="pug">
	div.default-modal
		loading(:active.sync="busy", :is-full-page="false")
		.modal-header
			.modal-title Create A New Preset
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			.modal-body-inner
				form(@submit.prevent="submit", v-if="preset")
					.row
						.col-sm-12
							.form-group
								label Name
								input.form-control(type="text", placeholder="My Custom Preset 1", v-model="preset.name")
					.row
						.col-sm-12
							.form-group
								label Date Range
								select.form-control(v-model="preset.date_range")
									option(value="") None
									option(v-for="o in dateRangeOptions", :value="o") {{ o }}

					p
						i.la.la-info-circle
						| &nbsp;This preset is private and will be accessible only for your user

					p.clearfix &nbsp;
					.buttons.text-right
						button.btn.btn-primary(type="submit") Save
							i.la.la-fw.la-save
</template>
<script>
import Vue from 'vue';

export default {
  name: 'CreatePresetModal',
  props: ['preset', 'dateRangeOptions'],
  methods: {
    async submit() {
      if (this.busy) {
        return;
      }
      let data = Vue.util.extend({}, this.preset);
      this.busy = true;
      try {
        await this.$ovReq.post('reportPreset/save', data);
        this.$ovNotify.success('preset has been saved');
        this.$emit('close');
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
  data() {
    return {
      busy: false,
    };
  },
};
</script>
