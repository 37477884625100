<template lang="pug">
	div.default-modal
		.modal-header
			.modal-title Generated Reports
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			loading(:active.sync="busy", :is-full-page="true")
			.modal-body-inner
				p
					button.btn.btn-primary(type="button", @click="loadData()")
						i.la.la-refresh
						| &nbsp; Refresh
				paginate(:paginator="paginate", @update-page-size="loadData")
				.ov-table-wrapper
					table.table.table-bordered.table-offer-list
						thead
							tr
								th ID
								th Created At
								th Status
								th Download
						tbody
							tr(v-if="records.length === 0")
								td(colspan="4") No matching records were found
							tr(v-for="r in records", v-bind:key="r.id")
								td {{ r.id }}
								td {{ r.created_at }}
								td {{ r.status === 'new' ? 'Generating...' : r.status }}
								td
									a(v-if="r.status === 'complete' && r.path", href="#", @click="download(r)") Download
</template>
<script>
import { downloadFile } from '../../lib/files';

export default {
  name: 'GeneratedReportsModal',
  props: [],
  data() {
    return {
      busy: false,
      records: [],
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadData();
        },
      },
    };
  },
  methods: {
    async loadData() {
      this.busy = true;
      let params = {
        page: this.paginate.page,
        page_size: this.paginate.limit,
      };
      try {
        let resp = await this.$ovReq.get('reports/getGeneratedList', { params });
        this.records = resp.records;
        this.paginate.total = resp.total;
        this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    async download(report) {
      const filename = `background_report_${report.id}.csv`;
      await downloadFile(report.path, filename);
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
