<template lang="pug">
	.container
		.form(v-if="ready")
			.row
				.col-sm-6
					.form-group
						toggle-button(v-model="params.restrict_timeframe",
							:labels="{checked: 'Active', unchecked: 'Inactive'}",
							:width="100", :height="32", :font-size="12", v-on:change="emitChange")
				.col-sm-6.text-right
					.form-inline
						.form-group(style="margin-left: auto;")
							label(style="padding-right: 5px") Timezone
							select.form-control(v-model="params.restrict_timeframe_timezone", v-on:change="emitChange")
								option(v-for="t in timeZoneOptions", :value="t.v") {{ t.t }}

			.row.block-row
				table.schedule-table
					thead
						tr
							th &nbsp;
							th(v-for="(h,i) in scheduleTable[0].hr")
								a(@click="selectCol(i)", href="javascript:void(0)") {{ (i < 10 ? '0':'') + i }}
					tbody
						tr(v-for="(d, j) in scheduleTable")
							td.d
								a(@click="selectRow(j)", href="javascript:void(0)") {{ d.d }}
							td.c(v-for="(i,h) in d.hr")
								input(type="checkbox", v-model="d.hr[h]", :disabled="d.hr[h]===2", v-on:change="emitChange")

			a.btn.btn-secondary.rounded-pill.mr-2(href="javascript:void(0)", @click="selectTimeframePreset('all')") All
			a.btn.btn-secondary.rounded-pill.mr-2(href="javascript:void(0)", @click="selectTimeframePreset('us_day')") US Day
			a.btn.btn-secondary.rounded-pill.mr-2(href="javascript:void(0)", @click="selectTimeframePreset('us_evening')") US Evening

</template>

<style lang="scss">
.schedule-table {
  width: 100%;
  position: relative;

  td,
  th {
    text-align: center;
  }

  td.d {
    font-weight: bold;
  }

  td.c {
    vertical-align: middle;
    width: 30px;
    height: 22px;

    &:hover {
      :after {
        position: absolute;
        content: ' ';
        left: 0;
        right: 0;
        height: 23px;
        border: 1px solid #ffb047;
        border-radius: 3px;
        margin-top: -5px;
        pointer-events: none;
      }

      :before {
        position: absolute;
        content: ' ';
        top: 0;
        bottom: 0;
        width: 23px;
        border: 1px solid #ffb047;
        border-radius: 3px;
        margin-left: -5px;
        pointer-events: none;
      }
    }
  }

  tr {
  }
}
</style>

<script>
const ACTIVE_BY_PARENT = 2;
import Vue from 'vue';

export default {
  name: 'timeframe-restriction',
  props: {
    params: {
      type: Object,
      required: true,
    },
    parentSettings: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      ready: false,
      timeZoneOptions: Vue.ovData.general.timezoneOptions,
      toggledSchedCol: {},
      toggledSchedRow: {},
      scheduleTimezone: 0,
      scheduleTable: [],
    };
  },

  mounted() {
    let dayChars = 'SMTWTFS';
    let days = [];
    for (let i = 0; i < dayChars.length; i++) {
      days.push({ d: dayChars.charAt(i), hr: [] });
    }
    let selected, activeByParent;
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < dayChars.length; j++) {
        selected =
          this.params.restrict_timeframe_schedule &&
          this.params.restrict_timeframe_schedule[j] &&
          this.params.restrict_timeframe_schedule[j].hr[i];
        activeByParent =
          this.parentSettings &&
          this.parentSettings.restrict_timeframe_schedule &&
          this.parentSettings.restrict_timeframe_schedule[j] &&
          this.parentSettings.restrict_timeframe_schedule[j].hr[i];
        /** if active by parent - disable with ACTIVE_BY_PARENT, otherwise use 1/0 */
        days[j].hr.push(activeByParent ? ACTIVE_BY_PARENT : selected ? 1 : 0);
      }
    }
    this.scheduleTable = days;
    this.ready = true;
  },
  methods: {
    selectTimeframePreset(preset) {
      let tz = this.params.restrict_timeframe_timezone;
      let hrPreset = [];
      switch (preset) {
        case 'all':
          for (let i = 0; i < 24; i++) {
            hrPreset.push(1);
          }
          break;
        case 'us_day':
          tz += 8;
          for (let i = 0; i < 24; i++) {
            let s = i >= 10 && i < 20;
            hrPreset.push(s);
          }
          break;
        case 'us_evening':
          tz += 8;
          for (let i = 0; i < 24; i++) {
            let s = i >= 18 && i < 24;
            hrPreset.push(s);
          }
          break;
        case 'eu_evening':
          for (let i = 0; i < 24; i++) {
            let s = i >= 18 && i < 24;
            hrPreset.push(s);
          }
          break;
      }

      if (tz > 0) {
        for (let i = 0; i < tz; i++) {
          hrPreset.push(hrPreset.shift());
        }
      } else if (tz < 0) {
        for (let i = 0; i < -tz; i++) {
          hrPreset.unshift(hrPreset.pop());
        }
      }

      for (let i = 0; i < this.scheduleTable.length; i++) {
        for (let j = 0; j < this.scheduleTable[i].hr.length; j++) {
          if (this.scheduleTable[i].hr[j] !== ACTIVE_BY_PARENT) {
            Vue.set(this.scheduleTable[i].hr, j, !!hrPreset[j]);
          }
        }
      }
      this.emitChange();
    },

    selectCol(col) {
      this.toggledSchedCol[col] = !this.toggledSchedCol[col];
      this.scheduleTable.forEach((d) => {
        if (d.hr[col] !== ACTIVE_BY_PARENT) {
          Vue.set(d.hr, col, this.toggledSchedCol[col]);
        }
      });
      this.emitChange();
    },

    selectRow(row) {
      this.toggledSchedRow[row] = !this.toggledSchedRow[row];
      for (let i = 0; i < this.scheduleTable[row].hr.length; i++) {
        if (this.scheduleTable[row].hr[i] !== ACTIVE_BY_PARENT) {
          Vue.set(this.scheduleTable[row].hr, i, this.toggledSchedRow[row]);
        }
      }
      this.emitChange();
    },

    emitChange() {
      this.$emit('update-timeframe', {
        restrict_timeframe: this.params.restrict_timeframe,
        restrict_timeframe_timezone: this.params.restrict_timeframe_timezone,
        restrict_timeframe_schedule: this.scheduleTable,
      });
    },
  },
};
</script>
