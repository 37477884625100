<template lang="pug">
.row(v-if="object.mode === 'range'")
  .col-md-5
    .form-group
      label Mode
      select.form-control(v-model="object.mode", @change="updateMode()")
        option(v-for="o in modes" :value="o.value") {{ o.label }}
  .col-md-3
    .form-group
      label Minimum version
      input.form-control(v-model="object.min", placeholder="No limit")
  .col-md-3
    .form-group
      label Maximum version
      input.form-control(v-model="object.max", placeholder="No limit")
  .col-md-1.d-flex.align-items-center.justify-content-end
    i.clickable.la.la-minus-circle(@click="remove()", style="font-size: 1.5rem;")
.row(v-else)
  .col-md-5 
    .form-group
      label Mode
      select.form-control(v-model="object.mode", @change="updateMode()")
        option(v-for="o in modes" :value="o.value") {{ o.label }}
  .col-md-6
    .form-group
      label Version
      input.form-control(v-model="object.value", placeholder="No limit")
  .col-md-1.d-flex.align-items-center.justify-content-end
    i.clickable.la.la-minus-circle(@click="remove()", style="font-size: 1.5rem;")
</template>

<style lang="scss"></style>

<script>
export default {
  name: 'os-targeting-version-blacklist-object',
  props: {
    object: {
      type: [Object],
      required: true,
    },
    index: {
      type: [Number],
      required: true,
    },
  },
  data() {
    return {
      modes: [
        { value: 'range', label: 'Range' },
        { value: 'above', label: 'Above' },
        { value: 'below', label: 'Below' },
      ],
    };
  },
  methods: {
    remove() {
      this.$emit('remove', this.index);
    },
    updateMode() {
      if (this.object.mode === 'range') {
        delete this.object.value;
        this.object.min = null;
        this.object.max = null;
      } else {
        delete this.object.min;
        delete this.object.max;
        this.object.value = null;
      }
    },
  },
};
</script>
