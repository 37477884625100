/**
 * @template {any} T
 * @param {T} value
 * @returns {T}
 */
export const copy = (value) => {
  return JSON.parse(JSON.stringify(value));
};

/** @param {unknown} value */
export const isObject = (value) => {
  return value !== null && typeof value === 'object';
};

/**
 * @param {Record<string, unknown> | any[]} a
 * @param {Record<string, unknown> | any[]} b
 */
export const isEqual = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
}