<template lang="pug">
	div.default-modal
		loading(:active.sync="busy", :is-full-page="false")
		.modal-header
			.modal-title Cancel Offer \#{{ id }}
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			.modal-body-inner
				//h6.mb-3 Change this offer's status to "Cancelled"

				form(@submit.prevent="submit")
					.form-group
						label Cancellation Reason
						select.form-control(v-model="cancelReason")
							option(v-for="o in cancelOptions", :value="o") {{ o }}
					.form-group
						label Free text (optional)
						textarea.form-control(rows="6", v-model="freeText")

					.buttons.text-right
						button.btn.btn-danger(type="submit") Cancel Offer

</template>
<script>
export default {
  name: 'CancelOfferModal',
  props: ['id'],
  methods: {
    async submit() {
      if (this.busy) {
        return;
      }
      let data = {
        id: this.id,
        reason: this.cancelReason,
        text: this.freeText,
      };
      try {
        await this.$ovReq.post('offer/quickCancel', data);
        this.$ovNotify.success('Offer has been cancelled');
        this.$emit('close');
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
  data() {
    return {
      busy: false,
      cancelReason: 'Other',
      freeText: '',
      cancelOptions: ['RJ Spike', 'Blocked', 'Adv Paused', 'Other'],
    };
  },
};
</script>
