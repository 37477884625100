<script>
import Vue from 'vue';

export default {
  name: 'SupplyManagementCapsTableCell',
  props: {
    target: { type: Number, required: true },
    actual: { type: Number, required: true },
    expected: { type: Number },
    initial: { type: Number, required: true },
    onSaveTarget: { type: Function, required: true },
  },
  computed: {
    visibleActual() {
      return Vue.filter('shortNumber')(this.actual);
    },
    visibleTarget: {
      get() {
        if (this.target === 0) {
          return '♾️'
        }
        return Vue.filter('shortNumber')(this.target);
      },
      set(value) {
        this.onSaveTarget(parseInt(value));
      },
    },
    visibleExpected() {
      return Vue.filter('shortNumber')(this.expected);
    },
    progress() {
      if (this.target === 0) {
        return 0;
      }
      return Math.min((this.actual / this.target) * 100, 100);
    },
    showProgressBar() {
      return {
        background: `linear-gradient(to right, #ccffcc ${this.progress}%, #f2f2f2 ${this.progress}%)`
      };
    },
    couldBeReseted() {
      return this.initial !== this.target;
    },
  },
  data() {
    return {
      isEditing: false,
    };
  },
  methods: {
    async saveTarget() {
      const value = this.$refs.targetInput.value;
      await this.onSaveTarget(parseInt(value));
      this.isEditing = false;
    },
    async reset() {
      await this.onSaveTarget(parseInt(this.initial));
      this.isEditing = false;
    }
  },
};
</script>

<template lang="pug">
.d-flex.gap-1
  input.cell-input.p-0.form-control(
    v-if="!isEditing", 
    @click="isEditing = true", 
    :value="visibleActual", 
    :style="showProgressBar", 
    :class="{'text-muted': visibleActual === 0}"
  )
  input.cell-input.p-0.form-control(v-else, @blur="saveTarget", :value="target", class="editing", ref="targetInput")
  i.la.la-xl.la-times.clickable.reset-icon(v-if="couldBeReseted", @click="reset")

  input.cell-input.p-0.form-control(v-show="expected !== undefined", :value="visibleExpected", readonly, :class="{'text-muted': visibleExpected === 0}")
</template>

<style lang="scss" scoped>
.cell-input {
  text-align: center;
  flex: 1;
}

.reset-icon {
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: small;
}

.cell-input:read-only {
  background-color: transparent;
}
</style>