<script>
import eventBus from '../../lib/eventBus';
import { copy } from '../../lib/objects';

export default {
  name: 'SupplyManagementConnectPublishersModal',
  props: {
    mediaType: {
      type: String,
      required: true
    },
    placementStatusList: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      showActiveOnly: true,
      filteredPlacementStatusList: copy(this.placementStatusList
        .filter((p) => {
          const shouldShow = !this.showActiveOnly || p.publisher_status;
          const neededMediaType = p.publisher_media_type === this.mediaType;
          return shouldShow && neededMediaType;
        })),
    }
  },
  methods: {
    setConnectedPublishers() {
      const mediaTypePublishersIds = this.filteredPlacementStatusList
        .reduce((acc, p) => {
          if (p.create_placement) {
            acc.push(p.publisher_id);
          }
          return acc;
        }, []);
      const otherMediaTypePublishersIds = this.placementStatusList
        .filter((p) => p.publisher_media_type !== this.mediaType && p.create_placement)
        .map((p) => p.publisher_id);
      eventBus.send(eventBus.Events.ConnectPublishersToOffer, [
        ...mediaTypePublishersIds, 
        ...otherMediaTypePublishersIds
      ]);
      this.$emit('close');
    },
  },
};
</script>

<template lang="pug">
div.default-modal
  .modal-header.d-flex.align-items-center.gap-2
    .modal-title Connect Publishers
    b-form-checkbox.mt-1(v-model="showActiveOnly") Show active only
    a.close(href="javascript:void(0);", @click="$emit('close')")
      i.la.la-times

  .modal-body
    .modal-body-inner
      .row
        .col-sm-4(v-for="p in filteredPlacementStatusList", :key="p.publisher_id", v-if="!showActiveOnly || p.publisher_status")
          .form-text
            b-form-checkbox(:class="'cstatus-'+p.status", v-model="p.create_placement", :disabled="p.disabled")
              i.la.la-robot.mr-1(v-if="p.disabled", v-b-tooltip.hover.right, title="Updating via Advertiser's Auto-Connect settings")
              span {{ p.publisher_name }}
      
      .form-group.d-flex.justify-content-end.m-0
        button.btn.btn-primary(@click="setConnectedPublishers") Save
</template>
