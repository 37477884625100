import Notifications from 'vue-notification';

export default {
  install(Vue /*, options*/) {
    Vue.use(Notifications);

    let api = {
      success: function (m, title) {
        Vue.notify({
          group: 'default',
          title: title || 'Success',
          type: 'success',
          text: m,
        });
      },
      error: function (m, title) {
        Vue.notify({
          group: 'default',
          title: title || 'Error',
          type: 'error',
          text: m || 'There has been an error',
        });
      },
    };

    Vue.ovNotify = api;
    Vue.prototype.$ovNotify = api;
  },
};
