import moment from 'moment-timezone';

export const timezones = [
  { v: -11, t: '-11 Pacific/Midway' },
  { v: -10, t: '-10 Pacific/Honolulu' },
  { v: -9, t: '-9 America/Anchorage' },
  { v: -8, t: '(PST) -8 America/Los Angeles' },
  { v: -7, t: '-7 America/Denver' },
  { v: -6, t: '-6 America/Chicago' },
  { v: -5, t: '(EST) -5 America/New York' },
  { v: -4, t: '-4 America/Caracas' },
  { v: -3, t: '-3 America/Sao Paulo' },
  { v: -2, t: '-2 Atlantic/South Georgia' },
  { v: -1, t: '-1 Atlantic/Azores' },
  { v: 0, t: '(GMT) 0 Europe/London' },
  { v: 1, t: '+1 Europe/Paris' },
  { v: 2, t: '+2 Europe/Athens' },
  { v: 3, t: '+3 Europe/Moscow' },
  { v: 4, t: '+4 Asia/Dubai' },
  { v: 5, t: '+5 Asia/Karachi' },
  { v: 6, t: '+6 Asia/Dhaka' },
  { v: 7, t: '+7 Asia/Jakarta' },
  { v: 8, t: '+8 Asia/Shanghai' },
  { v: 9, t: '+9 Asia/Tokyo' },
  { v: 10, t: '+10 Australia/Sydney' },
  { v: 11, t: '+11 Pacific/Noumea' },
  { v: 12, t: '+12 Pacific/Auckland' },
];

/**
 * @param {Date} date
 * @param {boolean} withSeconds
 */
export const formatDate = (date, withSeconds = false) => {
  return moment(date).format(withSeconds ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY HH:mm');
};

/** @param {number} month */
export const numToMonth = (month) => {
  if (!month || Number.isNaN(month) || month > 12) return '';
  const MonthsFull = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return MonthsFull[month - 1];
};
