<template lang="pug">
	div.file-list-wrapper(@drop.prevent="onDrop", :class="{'drag-over':dragOver}", @dragover.prevent="onDragOver",
		@dragleave="onDragLeave")
		span.upload-spinner(v-if="busy")
			i.la.la-refresh.la-spin
		div.file-list-item(v-for="(f, index) in files", v-b-tooltip.bottom.hover, :title="f.filename")
			a.link-remove(href="javascript:void(0);", @click="removeFile(f, index)")
				i.la.la-times
			a.link-view(target="_blank", :href="f.full_path")
				i.la.la-search
			span {{ f.filename }}
		div.add-file
			i.la.la-plus
			input(type="file", ref="file", v-on:change="handleSelectFile()")
</template>
<style lang="scss">
.file-list-wrapper {
  border: 2px dashed #ccc;
  background: #f6f6f6;
  width: 325px;
  max-width: 100%;
  min-height: 150px;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  &:after {
    position: absolute;
    left: 0;
    content: ' ';
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0);
    transition: background 0.2s ease-in-out;
    pointer-events: none;
    /*cur*/
  }

  &.drag-over {
    border: 2px dashed #aaa;

    &:after {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  .upload-spinner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);
    transition: background 0.2s ease-in-out;
    text-align: center;
    align-items: center;
    display: flex;

    i {
      font-size: 80px;
      vertical-align: center;
      margin: auto;
    }
  }

  .add-file {
    width: 70px;
    height: 100px;
    line-height: 100px;
    border-radius: 10px;
    background: #eee;
    margin: 5px;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.4);
    position: relative;
    text-align: center;
    font-size: 40px;
    border: none;
    padding: 0;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      background: #ddd;
    }

    input {
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      cursor: pointer;
      -webkit-appearance: none;
      pointer-events: all;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .file-list-item {
    width: 70px;
    height: 100px;
    border-radius: 10px;
    background: #fff;
    margin: 5px;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.4);
    position: relative;

    .link-remove {
      position: absolute;
      top: 0;
      right: 0;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      font-size: 22px;
      color: #aaa;
    }

    .link-view {
      margin-top: 30px;
      display: block;
      text-align: center;
      font-size: 34px;
      line-height: 34px;
      color: #ccc;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: #777;
      }
    }

    span {
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 2px;
      font-size: 10px;
      position: absolute;
      bottom: 5px;
      left: 0;
      background: #fff;
      border-radius: 5px;
      color: #ccc;
    }
  }
}
</style>
<script>
export default {
  name: 'FileList',
  props: {
    files: Array,
  },
  data() {
    return {
      busy: 0,
      dragOver: false,
    };
  },
  methods: {
    onDrop(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles || droppedFiles.length === 0) {
        return;
      }
      [...droppedFiles].forEach((f) => {
        this.uploadFile(f);
      });
    },
    onDragOver() {
      this.dragOver = true;
    },
    onDragLeave() {
      this.dragOver = false;
    },
    removeFile(f, index) {
      this.files.splice(index, 1);
    },
    handleSelectFile() {
      let k = 'file';
      console.log(this.$refs.file.files);
      let droppedFiles = this.$refs[k].files;
      if (!droppedFiles || droppedFiles.length === 0) {
        return;
      }
      [...droppedFiles].forEach((f) => {
        this.uploadFile(f);
      });
    },
    addFile() {
      this.files.push({
        file: null,
      });
    },
    async uploadFile(f) {
      let formData = new FormData();
      formData.append('file', f);
      try {
        this.busy++;
        let resp = await this.$http.post('general/uploadFile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        let file = {
          filename: resp.data.data.filename,
          full_path: resp.data.data.full_path,
        };
        this.files.push(file);
      } catch (e) {
        console.error(e);
      }
      this.dragOver = false;
      this.busy--;
    },
  },
};
</script>
