<template lang="pug">
	div(:class="{'edit-inline': !wrapped, 'edit-mode':editModeActive}")
		span.mr-2(v-if="!notext && !r['_edit_'+field]") {{ selectedOptionText }}
		select.form-control.form-control-sm(type="text", v-model="r['_'+field]", v-if="r['_edit_' + field]")
			option(v-for="o in options", :value="o.v") {{ o.t }}
		span.quickedit-buttons(v-if="!readonly")
			a.edit-button(href="javascript:void(0)", @click="editRecord(r, field)", v-if="!r['_edit_' + field]", v-b-tooltip.hover.right, title="Edit")
				i.la.la-pencil
			a(href="javascript:void(0)", @click="cancelEditRecord(r, field)", v-if="r['_edit_' + field]", v-b-tooltip.hover.bottom, title="Cancel")
				i.la.la-times
			a(href="javascript:void(0)", @click="saveRecord(r, field)", v-if="r['_edit_' + field]", v-b-tooltip.hover.bottom, title="Save Changes")
				i.la.la-save
</template>
<script>
import Vue from 'vue';

export default {
  name: 'quick-edit-select',
  props: {
    notext: {
      type: Boolean,
      default() {
        return false;
      },
    },
    wrapped: Boolean,
    r: {
      type: Object,
    },
    field: {
      type: String,
    },
    options: {
      type: Array,
    },
  },
  computed: {
    editModeActive: function () {
      return this.editMode;
    },
    readonly: function () {
      return this.r && this.r.reserved;
    },
    selectedOptionText: function () {
      let selectedOption = this.options.find((o) => o.v === this.r[this.field]);
      return selectedOption ? selectedOption.t : '';
    },
  },
  data() {
    return {
      editMode: false,
    };
  },
  methods: {
    editRecord(r, field) {
      if (r.reserved) return;
      this.editMode = true;
      Vue.set(r, '_' + field, r[field]);
      Vue.set(r, '_edit_' + field, true);
      r = r[field];
    },
    cancelEditRecord(r, field) {
      this.editMode = false;
      Vue.set(r, '_edit_' + field, false);
    },
    async saveRecord(r, field) {
      if (r.reserved) return;
      this.$emit('on-save', r, field);
    },
  },
};
</script>
