<template lang="pug">
  span(v-if="value")
    .form-group
      label Dates
      date-range-picker(:localeData="datePickerOptions.locale", :ranges="datePickerOptions.ranges",
        :auto-apply="true", :date-range="value.dateRange", @update="(v) => onInput('dateRange', v)", :time-picker="!singleDateOnly", :time-picker24-hour="!singleDateOnly",
        :time-picker-increment="1", opens="right", :date-format="dateFormat",
        :single-date-picker="singleDateOnly")
      span(v-if='limitThreeMonths')
        i.la.la-info-circle.la-lg.ml-2(v-b-tooltip.hover.right, title="Dates range limited to last 3 months due to selected dimensions.")
    .form-group(v-if="!value.hideTZ", :class="{'mobile-hidden': minimized && !value.timezone}")
      label Timezone
      select.form-control(:value="value.timezone", @input="(e) => onInput('timezone', e.target.value)")
        option(v-for="t in timeZoneOptions", :value="t.v") {{ t.t }}
</template>
<script>
import Vue from 'vue';
import moment from 'moment';
import { copy } from '../../lib/objects';

export default {
  name: 'OvDateTimePicker',
  props: {
    value: {
      type: Object,
      required: true,
    },
    limitThreeMonths: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    minimized: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    singleDateOnly: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    }
  },
  data() {
    return {
      timeZoneOptions: Vue.ovData.general.timezoneOptions,
      datePickerOptions: {
        timePicker: true,
        timePicker24Hour: true,
        locale: {
          format: 'DD/MM/YYYY HH:mm',
        },
        alwaysShowCalendars: true,
        autoApply: true,
        ranges: this.$props.singleDateOnly ? false : {},
      },
    };
  },
  methods: {
    /**
     * @param {string} key
     * @param {any} value
     */
    onInput(key, value) {
      const newValue = copy(this.value);
      newValue[key] = value;
      this.$emit('input', newValue);
    },
    dateFormat(classes, date) {
      if (this.limitThreeMonths && !classes.disabled) {
        const threeMontshBefore = moment().subtract(3, 'months');
        classes.disabled = date < threeMontshBefore;
      }
      return classes;
    },
    updateRanges() {
      if (this.$props.singleDateOnly) return false;

      let tz = this.value ? this.value.timezone || 0 : 0;
      let localOffset = new Date().getTimezoneOffset() / 60;
      let tzGap = -localOffset - tz;

      this.datePickerOptions.ranges = {
        'This Hour': [
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY HH') + ':00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY HH') + ':59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'Last Hour': [
          moment(
            moment().subtract(tzGap, 'hours').subtract(1, 'hours').format('DD/MM/YYYY HH') + ':00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').subtract(1, 'hours').format('DD/MM/YYYY HH') + ':59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        Today: [
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        Yesterday: [
          moment(
            moment().subtract(tzGap, 'hours').subtract(1, 'days').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').subtract(1, 'days').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'Last 7 Days': [
          moment(
            moment().subtract(tzGap, 'hours').subtract(6, 'days').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'Last 30 Days': [
          moment(
            moment().subtract(tzGap, 'hours').subtract(29, 'days').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'This Month': [
          moment(
            moment().subtract(tzGap, 'hours').startOf('month').format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment().subtract(tzGap, 'hours').endOf('month').format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
        'Last Month': [
          moment(
            moment()
              .subtract(tzGap, 'hours')
              .subtract(1, 'month')
              .startOf('month')
              .format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          moment(
            moment()
              .subtract(tzGap, 'hours')
              .subtract(1, 'month')
              .endOf('month')
              .format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        ],
      };
    },
    setRangeByKey(rangeKey) {
      let selectedRange = this.datePickerOptions.ranges[rangeKey];
      if (selectedRange) {
        this.$nextTick().then(() => {
          this.value.dateRange.startDate = selectedRange[0];
          this.value.dateRange.endDate = selectedRange[1];
        });
      }
    },
  },
  mounted() {
    if (!this.value.dateRange.startDate) {
      this.value.dateRange.startDate = moment(
        moment()
          .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
          .format('DD/MM/YYYY') + ' 00:00',
        'DD/MM/YYYY HH:mm',
      );
    }
    if (!this.value.dateRange.endDate) {
      this.value.dateRange.endDate = moment(
        moment()
          .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
          .format('DD/MM/YYYY') + ' 23:59',
        'DD/MM/YYYY HH:mm',
      );
    }
    this.updateRanges();
    let intv = setInterval(() => {
      this.updateRanges();
    }, 60 * 1000);
    this.$once('hook:beforeDestroy', () => {
      clearInterval(intv);
    });
  },
};
</script>
