import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from './views/Dashboard.vue';
import Layout from './components/Layout';

import DocumentationLayout from './components/DocumentationLayout.vue';
import DocumentationGettingStarted from './views/documentation/GettingStarted.vue';

import Login from './views/Login';
import AppPreview from './views/AppPreview';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
// import AdminList from './views/admins/List';
// import AdminForm from './views/admins/Form';

Vue.use(Router);

const withPrefix = (prefix, routes) =>
  routes.map((route) => {
    route.path = prefix + route.path;
    return route;
  });

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
    },
    {
      path: '/forgot-pass',
      name: 'forgot-password',
      component: ForgotPassword,
    },
    {
      path: '/reset-pass/:token',
      name: 'reset-password',
      component: ResetPassword,
    },
    {
      path: '/app',
      name: 'app',
      component: Layout,
      children: [
        {
          path: '',
          redirect: 'dashboard',
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          component: Dashboard,
        },
        {
          path: 'app-preview',
          name: 'app-preview',
          component: AppPreview,
        },
        {
          path: 'about',
          name: 'about',
          component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
        },
        {
          path: 'external-offers',
          name: 'external-offers',
          component: () =>
            import(/* webpackChunkName: "external-offers" */ './views/external-offers/List.vue'),
        },
        // {
        // 	path: 'statistics',
        // 	name: 'statistics',
        // 	component: () => import(/* webpackChunkName: "statistics" */ './views/reports/Statistics.vue')
        // },
        {
          path: 'reports',
          name: 'reports',
          component: () => import(/* webpackChunkName: "reports" */ './views/reports/Reports.vue'),
        },
        {
          path: 'clicks',
          name: 'clicks',
          component: () =>
            import(/* webpackChunkName: "click-list" */ './views/reports/ClickList.vue'),
        },
        {
          path: 'click/:id',
          name: 'click-view',
          component: () =>
            import(/* webpackChunkName: "click-view" */ './views/reports/ClickView.vue'),
        },
        {
          path: 'conversions',
          name: 'conversions',
          component: () =>
            import(/* webpackChunkName: "conversions" */ './views/reports/Conversions.vue'),
        },
        {
          path: 'pub_conversions',
          name: 'pub_conversions',
          component: () =>
            import(
              /* webpackChunkName: "conversions" */ './views/reports/PublisherConversions.vue'
            ),
        },
        {
          path: 'subsources',
          name: 'subsources',
          component: () => import(/* webpackChunkName: "subsources" */ './views/Subsources.vue'),
        },
        {
          path: 'click-search',
          name: 'click-search',
          component: () =>
            import(/* webpackChunkName: "click-search" */ './views/reports/ClickSearch.vue'),
        },
        {
          path: 'pa-reports',
          name: 'pa-reports',
          component: () =>
            import(/* webpackChunkName: "click-search" */ './views/reports/PostAttribution.vue'),
        },
        {
          path: 'url-scanner',
          name: 'url-scanner',
          component: () =>
            import(/* webpackChunkName: "click-search" */ './views/UrlScanner.vue'),
        },
        {
          path: 'events',
          name: 'events',
          component: () => import(/* webpackChunkName: "events" */ './views/reports/Events.vue'),
        },
        {
          path: 'rejected',
          name: 'rejected',
          component: () => import(/* webpackChunkName: "events" */ './views/reports/Rejected.vue'),
        },
        {
          path: 'postback-log',
          name: 'postback-log',
          component: () =>
            import(/* webpackChunkName: "postback-log" */ './views/monitor/PostbackLog.vue'),
        },
        {
          path: 'publisher-postbacks',
          name: 'publisher-postbacks',
          component: () =>
            import(
              /* webpackChunkName: "publisher-postbacks" */ './views/monitor/PublisherPostback.vue'
            ),
        },
        {
          path: 'activity-log',
          name: 'activity-log',
          component: () =>
            import(/* webpackChunkName: "activity-log" */ './views/monitor/ActivityLog.vue'),
        },
        {
          path: 'kpi-rule-log',
          name: 'kpi-rule-log',
          component: () =>
            import(/* webpackChunkName: "activity-log" */ './views/monitor/KpiRuleLog.vue'),
        },
        {
          path: 'settings',
          name: 'settings',
          component: () => import(/* webpackChunkName: "settings" */ './views/Settings.vue'),
        },
        {
          path: 'devices-blacklist',
          name: 'devices-blacklist',
          component: () =>
            import(/* webpackChunkName: "devices-blacklist" */ './views/DevicesBlacklist.vue'),
        },
        {
          path: 'kpi-rules',
          name: 'kpi-rules',
          component: () => import(/* webpackChunkName: "settings" */ './views/KpiRules'),
        },
        {
          path: 'countries',
          name: 'countries',
          component: () => import(/* webpackChunkName: "countries" */ './views/data/Countries.vue'),
        },
        {
          path: 'regions',
          name: 'regions',
          component: () => import(/* webpackChunkName: "regions" */ './views/data/Regions.vue'),
        },
        {
          path: 'cities',
          name: 'cities',
          component: () => import(/* webpackChunkName: "cities" */ './views/data/Cities.vue'),
        },
        {
          path: 'ipinfo',
          name: 'ipinfo',
          component: () => import(/* webpackChunkName: "ipinfo" */ './views/data/IPInfo.vue'),
        },

        {
          path: 'tags',
          name: 'tags',
          component: () => import(/* webpackChunkName: "tags" */ './views/data/Tags.vue'),
        },

        {
          path: 'notifications',
          name: 'notifications',
          component: () => import('./views/notifications/List.vue'),
        },
        {
          path: 'updates',
          name: 'updates',
          component: () => import('./views/notifications/Updates.vue'),
        },
        {
          path: 'rule-notifications',
          name: 'rule-notifications',
          component: () => import('./views/notifications/RuleNotifications'),
        },
        {
          name: 'profile',
          path: 'profile',
          component: () => import('./views/users/Profile.vue'),
        },

        ...withPrefix('admins', [
          {
            name: 'admin-list',
            path: '',
            component: () => import('./views/admins/List.vue'),
          },
          {
            name: 'admin-add',
            path: '/add',
            component: () => import('./views/admins/Form.vue'),
          },
          {
            name: 'admin-edit',
            path: '/edit/:id',
            component: () => import('./views/admins/Form.vue'),
          },
        ]),
        ...withPrefix('users', [
          {
            name: 'user-list',
            path: '',
            component: () => import('./views/users/List.vue'),
          },
          {
            name: 'user-add',
            path: '/add',
            component: () => import('./views/users/Form.vue'),
          },
          {
            name: 'user-edit',
            path: '/edit/:id',
            component: () => import('./views/users/Form.vue'),
          },
        ]),

        ...withPrefix('advertisers', [
          {
            name: 'advertiser-list',
            path: '',
            component: () => import('./views/advertisers/List.vue'),
          },
          {
            name: 'advertiser-add',
            path: '/add',
            component: () => import('./views/advertisers/Form.vue'),
          },
          {
            name: 'advertiser-edit',
            path: '/edit/:id',
            component: () => import('./views/advertisers/Form.vue'),
          },
        ]),

        ...withPrefix('publishers', [
          {
            name: 'publisher-list',
            path: '',
            component: () => import('./views/publishers/List.vue'),
          },
          {
            name: 'publisher-add',
            path: '/add',
            component: () => import('./views/publishers/Form.vue'),
          },
          {
            name: 'publisher-edit',
            path: '/edit/:id',
            component: () => import('./views/publishers/Form.vue'),
          },
          {
            name: 'publisher-api',
            path: '/api/:id',
            component: () => import('./views/publishers/API.vue'),
          },
        ]),

        ...withPrefix('offers', [
          {
            name: 'offer-list',
            path: '',
            component: () => import('./views/offers/List.vue'),
          },
          {
            name: 'offer-fallback',
            path: '/fallback',
            component: () => import('./views/offers/FallbackForm.vue'),
          },
          {
            name: 'offer-add',
            path: '/add',
            component: () => import('./views/offers/Form.vue'),
          },
          {
            name: 'offer-edit',
            path: '/edit/:id',
            component: () => import('./views/offers/Form.vue'),
          },
          {
            name: 'complex-add',
            path: '/add-complex',
            component: () => import('./views/offers/ComplexForm.vue'),
          },
          {
            name: 'complex-edit',
            path: '/edit-complex/:id',
            component: () => import('./views/offers/ComplexForm.vue'),
          },
          {
            name: 'offer-targeting',
            path: '/targeting/:id',
            component: () => import('./views/offers/Targeting.vue'),
          },
          {
            name: 'offer-default-settings',
            path: '/default-settings',
            component: () => import('./views/offers/DefaultSettings.vue'),
          },
          {
            name: 'offer-supply-management',
            path: '/supply-management/:id',
            component: () => import('./views/offers/SupplyManagementForm/SupplyManagementForm.vue'),
          },
        ]),

        ...withPrefix('placements', [
          {
            name: 'placement-list',
            path: '',
            component: () => import('./views/placements/List.vue'),
          },
          {
            name: 'placement-add',
            path: '/add',
            component: () => import('./views/placements/Form.vue'),
          },
          {
            name: 'placement-edit',
            path: '/edit/:id',
            component: () => import('./views/placements/Form.vue'),
          },
          {
            name: 'placement-targeting',
            path: '/targeting/:id',
            component: () => import('./views/placements/Targeting.vue'),
          },
          {
            name: 'placement-default-settings',
            path: '/default-settings',
            component: () => import('./views/placements/DefaultSettings.vue'),
          },
          {
            name: 'placement-test',
            path: '/test/:id',
            component: () => import('./views/placements/Test.vue'),
          },
        ]),

        ...withPrefix('mmp-accounts', [
          {
            name: 'mmp-account-list',
            path: '',
            component: () => import('./views/mmp-accounts/List.vue'),
          },
          {
            name: 'mmp-account-add',
            path: '/add',
            component: () => import('./views/mmp-accounts/Form.vue'),
          },
          {
            name: 'mmp-account-edit',
            path: '/edit/:id',
            component: () => import('./views/mmp-accounts/Form.vue'),
          },
          {
            path: '/reports',
            name: 'mmp-account-reports',
            component: () => import('./views/reports/MmpAccountReports.vue'),
          },
        ]),
        ...withPrefix('devices', [
          {
            name: 'device-list',
            path: '',
            component: () => import('./views/devices/List.vue'),
          },
          {
            name: 'device-add',
            path: '/add',
            component: () => import('./views/devices/Form.vue'),
          },
          {
            name: 'device-edit',
            path: '/edit/:id',
            component: () => import('./views/devices/Form.vue'),
          },
        ]),
        ...withPrefix('monitor', [
          {
            path: '/clicks',
            name: 'monitor-clicks',
            component: () =>
              import(/* webpackChunkName: "monitor-clicks" */ './views/monitor/Clicks.vue'),
          },
          // {
          // 	path: '/request-storage',
          // 	name: 'monitor-request-storage',
          // 	component: () => import(/* webpackChunkName: "monitor-request-storage" */ './views/monitor/RequestStorage.vue')
          // },
        ]),
        ...withPrefix('countries', [
          {
            path: '',
            name: 'countries',
            component: () => import('./views/data/Countries.vue'),
          },
        ]),
        ...withPrefix('regions', [
          {
            path: '',
            name: 'regions',
            component: () => import('./views/data/Regions.vue'),
          },
        ]),
        ...withPrefix('cities', [
          {
            path: '',
            name: 'cities',
            component: () => import('./views/data/Cities.vue'),
          },
        ]),
        ...withPrefix('finance', [
          {
            name: 'finance-summary',
            path: '/summary',
            component: () => import('./views/finance/MonthSummary'),
          },
          {
            name: 'finance-list',
            path: '/summary-list',
            component: () => import('./views/finance/Summary.vue'),
          },
          // {
          // 	name: 'finance-month-summary',
          // 	path: '/summary/month/:month',
          // },
          {
            name: 'legal-entity-list',
            path: '/legal-entities',
            component: () => import('./views/legal-entities/List.vue'),
          },
          {
            name: 'legal-entity-add',
            path: '/legal-entities/add',
            component: () => import('./views/legal-entities/Form.vue'),
          },
          {
            name: 'legal-entity-edit',
            path: '/legal-entities/edit/:id',
            component: () => import('./views/legal-entities/Form.vue'),
          },
          {
            name: 'finance-advertisers',
            path: '/advertisers',
            component: () => import('./views/finance/Advertisers.vue'),
          },
          {
            name: 'finance-publishers',
            path: '/publishers',
            component: () => import('./views/finance/Publishers'),
          },
          {
            name: 'documents',
            path: '/documents',
            component: () => import('./views/finance/Documents'),
          },
        ]),
        ...withPrefix('support', [
          {
            name: 'support-tickets-list',
            path: '',
            component: () => import('./views/support/List.vue'),
          },
          {
            name: 'support-ticket-edit',
            path: '/edit/:id',
            component: () => import('./views/support/Form.vue'),
          },
          {
            name: 'support-ticket-add',
            path: '/add',
            component: () => import('./views/support/Form.vue'),
          },
        ]),
        // {
        // 	path: 'advertisers',
        // 	name: 'advertisers',
        // 	component: {
        // 		render (c) { return c('router-view') }
        // 	},
        // 	children: [
        //
        // 	]
        // },
      ],
    },
    // {
    // 	path: '/about',
    // 	name: 'about',
    // 	meta: {layout: 'default'},
    // 	// route level code-splitting
    // 	// this generates a separate chunk (about.[hash].js) for this route
    // 	// which is lazy-loaded when the route is visited.
    // 	component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    // }
    {
      name: 'docs',
      path: '/docs',
      component: DocumentationLayout,
      children: [
        {
          path: '',
          redirect: 'getting-started',
        },
        {
          name: 'getting-started',
          path: 'getting-started',
          component: DocumentationGettingStarted,
        },
        {
          name: 'article',
          path: ':slug',
          component: () => import('./views/documentation/Article.vue'),
        },
      ],
    },
  ],
});

export default router;
