<template lang="pug">
	.effective-status-icons(v-if="offer", v-b-tooltip.hoveoffer.right, :title="getPausedReasonText")
		span.btn.btn-danger(v-if="!active")
		span.btn.btn-success(v-if="active")
		span.paused-icon(v-if="getPausedReasonIcon")
			i.la(:class="getPausedReasonIcon")
</template>
<style lang="scss">
.effective-status-icons {
  min-height: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .btn {
    height: 12px;
  }
  .paused-icon {
    font-size: 20px;
    color: #852721;
  }
}
</style>
<script>
const OFFER_PAUSED_REASON = {
  DAILY_CLICK_CAP: 1,
  DAILY_CONVERSION_CAP: 2,
  SCAN_STATUS: 3,
  TIME_RESTRICTION: 4,
  DAILY_BUDGET: 5,
  MONTHLY_BUDGET: 6,
  WEEKLY_CONVERSION_CAP: 7,
  DAILY_INSTALL_CAP: 11,
};

const PLACEMENT_PAUSED_REASON = {
  DAILY_CLICK_CAP: 1,
  DAILY_CONVERSION_CAP: 2,
  TIME_RESTRICTION: 4,
  DAILY_INSTALL_CAP: 11,
};

export default {
  name: 'OfferPausedReason',
  props: {
    offer: {
      type: Object,
    },
    placement: {
      type: Object,
    },
  },
  computed: {
    active() {
      return (
        this.offer.effective_status === 'active' &&
        (!this.placement || this.placement.effective_status === 'active')
      );
    },
    getPausedReasonText() {
      if (this.active) {
        return 'Active';
      }
      if (this.offer.advertiser_status === 0) {
        return 'Advertiser is inactive';
      }
      if (this.offer.status === 'paused') {
        return 'Offer is paused';
      }
      if (this.offer.status === 'cancelled') {
        return 'Offer is cancelled';
      }
      if (this.offer.status === 'draft') {
        return 'Offer is in draft mode';
      }
      // if (offer.status === 'ended') {
      // 	return 'Offer has ended';
      // }
      if (this.placement) {
        if (this.placement.publisher_status === 0) {
          return 'Publisher is inactive';
        }
        if (this.placement.status === 'paused') {
          return 'Placement is paused';
        }
        if (this.placement.status === 'cancelled') {
          return 'Placement is cancelled';
        }
        if (this.placement.status === 'draft') {
          return 'Placement is in draft mode';
        }
        switch (this.placement.paused_reason) {
          case PLACEMENT_PAUSED_REASON.DAILY_CLICK_CAP:
            return 'Placement Click/Impression Cap overflow';
          case PLACEMENT_PAUSED_REASON.DAILY_CONVERSION_CAP:
            return 'Placement Daily Conversion Cap overflow';
          case PLACEMENT_PAUSED_REASON.TIME_RESTRICTION:
            return 'Placement Time restriction';
          case PLACEMENT_PAUSED_REASON.DAILY_INSTALL_CAP:
            return 'Placement Daily Install overflow';
          default:
          // no-op
        }
      }
      switch (this.offer.paused_reason) {
        case OFFER_PAUSED_REASON.DAILY_CLICK_CAP:
          return 'Offer Click/Impression Cap overflow';
        case OFFER_PAUSED_REASON.DAILY_CONVERSION_CAP:
          return 'Offer Daily Conversion Cap overflow';
        case OFFER_PAUSED_REASON.WEEKLY_CONVERSION_CAP:
          return 'Offer Weekly Conversion Cap overflow';
        case OFFER_PAUSED_REASON.SCAN_STATUS:
          return 'Offer Scan Failed';
        case OFFER_PAUSED_REASON.TIME_RESTRICTION:
          return 'Offer Time restriction';
        case OFFER_PAUSED_REASON.DAILY_BUDGET:
          return 'Offer Daily Budget overflow';
        case OFFER_PAUSED_REASON.MONTHLY_BUDGET:
          return 'Offer Monthly Budget overflow';
        case OFFER_PAUSED_REASON.DAILY_INSTALL_CAP:
          return 'Offer Daily Install overflow';
        default:
          if (this.offer.type === 'complex') {
            return 'All connected offers are inactive';
          }
          return '';
      }
    },

    getPausedReasonIcon() {
      if (this.active) {
        return '';
      }
      if (this.offer.advertiser_status === 0 || this.offer.status !== 'live') {
        return 'la-pause-circle';
      }
      if (this.placement) {
        if (this.placement.publisher_status === 0 || this.placement.status !== 'live') {
          return 'la-pause-circle';
        }
        switch (this.placement.paused_reason) {
          case PLACEMENT_PAUSED_REASON.DAILY_CLICK_CAP:
          case PLACEMENT_PAUSED_REASON.DAILY_INSTALL_CAP:
            return 'la-shield-alt';
          case PLACEMENT_PAUSED_REASON.DAILY_CONVERSION_CAP:
            return 'la-coins';
          case PLACEMENT_PAUSED_REASON.TIME_RESTRICTION:
            return 'la-clock';
          default:
          // no-op
        }
      }
      switch (this.offer.paused_reason) {
        case OFFER_PAUSED_REASON.DAILY_CLICK_CAP:
        case OFFER_PAUSED_REASON.DAILY_INSTALL_CAP:
          return 'la-shield-alt';
        case OFFER_PAUSED_REASON.SCAN_STATUS:
          return 'la-broadcast-tower';
        case OFFER_PAUSED_REASON.TIME_RESTRICTION:
          return 'la-clock';
        case OFFER_PAUSED_REASON.DAILY_CONVERSION_CAP:
        case OFFER_PAUSED_REASON.WEEKLY_CONVERSION_CAP:
        case OFFER_PAUSED_REASON.DAILY_BUDGET:
        case OFFER_PAUSED_REASON.MONTHLY_BUDGET:
          return 'la-coins';
        default:
          return 'la-pause-circle';
      }
    },
  },
};
</script>
