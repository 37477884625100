/**
 * Downloads file and saves it with the desired name
 * @param {string} path - File download path
 * @param {string} name - Name used to save downloaded file
 */
export const downloadFile = async (path, name) => {
  const blob = await fetch(path).then((r) => r.blob());
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.click();
}