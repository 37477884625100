<template lang="pug">
.form-group
  label.font-weight-bold OS Version Blacklist
  os-targeting-version-blacklist-object(
    v-for="(item, index) in value",
    :object="item",
    :index="index"
    @remove="removeRule(index)"
  )
  div.d-flex.justify-content-center
    i.clickable.la.la-plus-circle(@click="addRule()", style="font-size: 1.5rem;")
</template>

<style lang="scss"></style>

<script>
export default {
  name: 'os-targeting-version-blacklist',
  props: {
    value: {
      type: [Array],
      required: true,
    },
  },
  methods: {
    addRule() {
      this.value.push({
        mode: 'range',
        min: null,
        max: null,
      });
    },
    removeRule(index) {
      this.value.splice(index, 1);
    },
  },
};
</script>
