<template lang="pug">
  div.page-login

    .widget
      .widget-body
        h1 Reset Password
        .logo
          img(alt="Logo", :src="'/assets/'+platformName+'/logo-black.png'", :onerror="fallbackLogo")
        form(@submit.prevent="submit")
          .requirements
            h6 Requirements
            p(:class="hasEnoughChars ? 'valid' : 'invalid'") Has minimum 8 characters in length
            p(:class="hasUpperCase ? 'valid' : 'invalid'") At least one uppercase English letter
            p(:class="hasLowerCase ? 'valid' : 'invalid'") At least one lowercase English letter
            p(:class="hasDigit ? 'valid' : 'invalid'") At least one digit
            p(:class="hasSpecialChar ? 'valid' : 'invalid'") At least one special character (!@#$%^&*()_+{}"'<>,.?\|/:;{}[])
            p(:class="arePasswordsMatching ? 'valid' : 'invalid'") Passwords must match
          .form-group
            password-input(v-model="password", placeholder="Password")
          .form-group
            password-input(v-model="password2", placeholder="Confirm Password")
          .alert.alert-danger(v-if="errorText") {{ errorText }}
          .alert.alert-success(v-if="successText") {{ successText }}
          .form-group.text-right
            button.btn.btn-sm.btn-primary(type="submit", :disabled="busy || disabled || !canSubmit")
              | Submit
              =' '
              .la(:class="{'la-arrow-right':!busy,'la-spinner la-spin':busy}")

</template>
<style lang="scss">
.requirements {
  margin-bottom: 20px;
  p {
    margin: 0;
  }

  .valid {
    color: green;
  }

  .invalid {
    color: red;
  }
}

.page-login {
  padding-top: 20vh;
  text-align: center;

  .widget {
    max-width: 460px;
    width: 100%;
    margin: auto;

    h1 {
      font-size: 30px;
      font-weight: 300;
      margin: 0 0 15px 0;
    }
  }

  .logo {
    text-align: center;
    padding: 20px;
    img {
      max-width: 100%;
      max-height: 200px;
    }
    @media (max-width: 767px) {
      padding: 10px 0;
      img {
        max-height: 50px;
      }
    }
  }
}
</style>
<script>
import Vue from 'vue';

export default {
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.token = to.params.token;
      return vm;
    });
  },
  data() {
    return {
      disabled: false,
      token: '',
      errorText: '',
      successText: '',
      busy: false,
      password: '',
      password2: '',
      platformName: Vue.prototype.$platformName,
    };
  },
  mounted() {
    /** validate token */
    if (!this.token) {
      this.errorText = 'Invalid Reset Link';
      this.disabled = true;
      return;
    }
    let parsed = this.token.split('T');
    if (parsed.length < 3) {
      this.errorText = 'Invalid Reset Link';
      this.disabled = true;
      return;
    }
    let nowUnix = new Date().getTime();
    let tokenUnix = +parsed[1];
    if (tokenUnix + 2 * 3600 * 1000 < nowUnix) {
      this.errorText = 'Link has expired';
      this.disabled = true;
    }
  },
  computed: {
    fallbackLogo() {
      return 'this.src="/assets/' + Vue.prototype.$platformName + '/logo.png";this.onerror=""';
    },
    hasEnoughChars() {
      return this.password.length >= 8;
    },
    hasUpperCase() {
      return /[A-Z]/.test(this.password);
    },
    hasLowerCase() {
      return /[a-z]/.test(this.password);
    },
    hasDigit() {
      return /\d/.test(this.password);
    },
    hasSpecialChar() {
      return /[!@#$%^&*()_+{}"'<>,.?\\|\/:;{}\[\]]/.test(this.password);
    },
    arePasswordsMatching() {
      return this.password && this.password === this.password2;
    },
    canSubmit() {
      return this.hasEnoughChars && this.hasUpperCase && this.hasLowerCase && this.hasDigit && this.hasSpecialChar && this.arePasswordsMatching;
    },
  },
  methods: {
    submit() {
      if (this.busy) {
        return;
      }
      this.errorText = '';
      this.successText = '';
      if (!this.password) {
        return;
      }
      if (!this.arePasswordsMatching) {
        this.errorText = 'Repeat the same password!';
        return;
      }
      let data = {
        password: this.password,
        token: this.token,
      };
      this.busy = true;
      let resetPassUrl = process.env.VUE_APP_API_BASE_URL + 'api/auth/reset-password';
      this.$http.post(resetPassUrl, data).then(
        (response) => {
          this.busy = false;
          this.errorText = '';
          if (response.body && response.body.err) {
            this.errorText = response.body.errdesc;
          } else {
            this.successText = 'New password has been saved';
            setTimeout(() => {
              this.$router.push({ name: 'login' });
            }, 1000);
          }
        },
        (response) => {
          this.busy = false;
          // error callback
          let errdesc = '';
          if (response.body && response.body.err) {
            errdesc = response.body.errdesc;
          } else {
            errdesc = 'There has been an error';
          }
          this.errorText = errdesc;
        },
      );
    },
  },
};
</script>
