<script>
export default {
  name: 'DocumentationGettingStarted',
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<template lang="pug">
.h-100.w-100
  h1.display-1 Getting started
  p This page is under construction.
  p Please fill me with some usefull information.
</template>
