<template lang="pug">
.widget
  .widget-header
    h2.title Device Type Restrictions
      new-feature(v-if="isNew")
  .widget-body
    .form-group
      label Restricted Devices
      b-form-checkbox(
        v-for="deviceType in deviceTypes"
        :checked="shouldBlock(deviceType)",
        :key="deviceType"
        @input="() => updateValue(deviceType)"
        ) Block {{ deviceType }} devices
</template>

<style lang="scss"></style>

<script>
export default {
  name: 'device-type-targeting',
  props: {
    value: {
      type: [Array],
      required: true,
      default: () => [],
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      deviceTypes: ['desktop', 'mobile', 'tablet'],
    };
  },
  methods: {
    shouldBlock(option) {
      return this.value.includes(option);
    },
    updateValue(option) {
      if (this.value.includes(option)) {
        this.$emit(
          'input',
          this.value.filter((item) => item !== option),
        );
      } else {
        this.$emit('input', [...this.value, option]);
      }
    },
  },
};
</script>
