<script>
import MenuItem from './MenuItem.vue';

const byOrder = (a, b) => {
  if (a.order > b.order) return 1;
  if (a.order < b.order) return -1;
  return 0;
};

const findArticleChildrens = (article, articlesList) => {
  const childrens = articlesList.filter((item) => item.parent_id === article.id);

  for (const children of childrens) {
    const childrenChildrens = findArticleChildrens(children, articlesList).sort(byOrder);

    if (childrenChildrens.length) {
      children.children = childrenChildrens;
    }
  }

  return childrens;
};

export default {
  name: 'DocumentationSideMenu',
  components: { MenuItem },
  props: {
    articles: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    articlesTree() {
      const tree = [];

      for (const article of this.$props.articles) {
        if (!article.parent_id) {
          const children = findArticleChildrens(article, this.$props.articles).sort(byOrder);

          if (children.length) {
            article.children = children;
          }

          tree.push(article);
        }
      }

      return tree.sort(byOrder);
    },
  },
  data() {
    return {
      gettingStarted: {
        title: 'Getting started',
        slug: 'getting-started',
      },
      backToApp: {
        title: '← OCTAVA',
        slug: '/app/dashboard',
      },
    };
  },
  methods: {},
};
</script>

<template lang="pug">
.h-100.menu-container
  .d-flex.gap-2.fs-1.px-4.pt-3.menu-header.justify-content-center.align-items-center
    i.la.la-book
    p.m-0 Documentation
  ul.list-unstyled.menu-list
    MenuItem(:item="gettingStarted")
    MenuItem(v-for="item in articlesTree" :item="item" :key="item.slug")
    MenuItem.fixed-bottom.pb-3(:item="backToApp")
</template>

<style lang="scss" scoped>
.menu-container {
  width: 224px;
  max-width: 224px;
  background-color: #1e1e2d;

  .menu-list {
    width: 224px;
    max-width: 224px;
  }
  .menu-header {
    color: white;
  }
}
</style>
