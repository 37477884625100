<script>
export default {
  name: 'TableEditableCell',
  props: {
    value: { type: [Number, String], required: true },
    onSaveTarget: { type: Function, required: true },
  },
  data() {
    return {
      isEditing: false,
    };
  },
  methods: {
    async saveTarget() {
      const value = this.$refs.targetInput.value;
      await this.onSaveTarget(value);
      this.isEditing = false;
    },
  },
};
</script>

<template lang="pug">
div.d-flex
  div(v-if="!isEditing") {{ value }}
  input.quick-edit-input(v-else, :value="value", ref="targetInput")

  div.quickedit-buttons.ml-1.d-flex
    a.edit-button(v-if="!isEditing", href="javascript:void(0)", @click="isEditing = true")
      i.la.la-pencil
    a(v-if="isEditing", href="javascript:void(0)", @click="isEditing = false")
      i.la.la-times
    a(v-if="isEditing", href="javascript:void(0)", @click="saveTarget")
      i.la.la-save
</template>

<style lang="scss" scoped>
.quick-edit-input {
  line-height: 20px;
  height: 24px;
  background: transparent;
  border: none;
  border-bottom: 2px solid #ffb047;
  border-radius: 0;
  max-width: 100%;
}
</style>