<script>
import Vue from 'vue';
import DocumentationSideMenu from './DocumentationSideMenu';

export default {
  name: 'DocumentationLayout',
  async beforeRouteEnter(to, from, next) {
    const articles = await Vue.ovReq.get('article/getMenuList');
    next((vm) => {
      vm.articles = articles;
      return vm;
    });
  },
  components: { DocumentationSideMenu },
  computed: {},
  data() {
    return { articles: [] };
  },
  methods: {},
};
</script>

<template lang="pug">
.d-flex.h-100.docs-module
  DocumentationSideMenu(:articles="articles")
  .p-4.overflow-auto.content-container
    router-view
</template>

<style lang="scss" scoped></style>
