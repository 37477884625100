<template lang="pug">
	div.doc-list-wrapper(@drop.prevent="onDrop", :class="{'drag-over':dragOver}", @dragover.prevent="onDragOver",
		@dragleave="onDragLeave")
		span.upload-spinner(v-if="busy")
			i.la.la-refresh.la-spin
		table.doc-list
			tbody
				tr(v-for="(f, index) in files")
					td.col-name
						input.form-control(type="text", v-model="f.name")
					td.actions
						a.link-view(target="_blank", :href="f.full_path")
							i.la.la-search
						a.link-remove(href="javascript:void(0);", @click="removeFile(f, index)",
							v-b-tooltip.bottom.hover, :title="f.filename")
							i.la.la-times
		div.add-file(v-if="!single || files.length < 1")
			i.la.la-plus
			input(type="file", ref="file", multiple, v-on:change="handleSelectFile()")
</template>
<style lang="scss">
.doc-list-wrapper {
  width: 100%;
  max-width: 100%;
  position: relative;
  margin-bottom: 20px;

  &:after {
    position: absolute;
    left: 0;
    content: ' ';
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0);
    transition: background 0.2s ease-in-out;
    pointer-events: none;
    /*cur*/
  }

  &.drag-over {
    border: 2px dashed #aaa;

    &:after {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  table.doc-list {
    width: 100%;
    border: none;
    td.col-name {
      width: 100%;
      .form-control {
        height: 30px;
      }
    }
    td.actions {
      white-space: nowrap;
      a {
        width: 30px;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        text-align: center;
        padding: 0;
        border-radius: 5px;
        background: #f6f6f6;
        margin: 0 1px;
        color: #aaa;
      }
    }
  }
  .upload-spinner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);
    transition: background 0.2s ease-in-out;
    text-align: center;
    align-items: center;
    display: flex;

    i {
      font-size: 80px;
      vertical-align: center;
      margin: auto;
    }
  }

  .add-file {
    width: 100%;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    background: #eee;

    position: relative;
    text-align: center;
    font-size: 24px;
    border: none;
    padding: 0;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      background: #ddd;
    }

    input {
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      cursor: pointer;
      -webkit-appearance: none;
      pointer-events: all;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
<script>
export default {
  name: 'DocList',
  props: {
    files: Array,
    single: null,
    cb: null,
    type: String,
    metadata: Object,
  },
  data() {
    return {
      busy: 0,
      dragOver: false,
    };
  },
  methods: {
    onDrop(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles || droppedFiles.length === 0) {
        return;
      }
      [...droppedFiles].forEach((f) => {
        this.uploadFile(f);
      });
    },
    onDragOver() {
      this.dragOver = true;
    },
    onDragLeave() {
      this.dragOver = false;
    },
    removeFile(f, index) {
      this.files.splice(index, 1);
      // TODO - DOM not updating
      // this.files = [...this.files];
    },
    handleSelectFile() {
      let k = 'file';
      console.log(this.$refs.file.files);
      let droppedFiles = this.$refs[k].files;
      if (!droppedFiles || droppedFiles.length === 0) {
        return;
      }
      [...droppedFiles].forEach((f) => {
        this.uploadFile(f);
      });
    },
    addFile() {
      console.log('addFile');
      // this.files.push({
      // 	file: null
      // });
    },
    async uploadFile(f) {
      let formData = new FormData();
      formData.append('file', f);
      formData.append('type', this.type);
      formData.append('metadata', JSON.stringify(this.metadata));
      try {
        this.busy++;
        let resp = await this.$http.post('general/uploadFile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (resp.data.err) {
          throw new Error(resp.data.errdesc);
        }

        let file = {
          name: resp.data.data.original_name,
          filename: resp.data.data.filename,
          full_path: resp.data.data.full_path,
        };
        // this.files.push(file);
        if (this.cb) {
          this.cb(file);
          this.files.push(file);
        } else {
          this.files.push(file);
        }
      } catch (e) {
        this.$ovNotify.error(e);
      }
      this.dragOver = false;
      this.busy--;
    },
  },
};
</script>
