<template lang="pug">
	.form-group
		label.d-flex.justify-content-between
			.label {{ label }}
				i.la.la-magic.ml-1(v-if="clicks.length && (!formValid || invalidInputs.length)", @click="parseClicks(); clearInvalid()", v-b-tooltip.hover.right, title="Click to fix")
				i.la.la-check.ml-1(v-if="formValid && clicks.length")
			.input-result
				b-badge.ml-1(v-if="clicks.length && formValid", variant="success", pill, v-b-tooltip.hover.right, :title="clicks.length + ' Valid Click Ids'", @click="parseClicks") {{ clicks.length }}
				b-badge.ml-1(v-if="clicks.length && !formValid", variant="warning", pill, v-b-tooltip.hover.right, title="Click to clean invalid data", @click="parseClicks") {{ clicks.length }}
				b-badge.ml-1(v-if="invalidInputs.length", variant="danger", pill, v-b-tooltip.hover.right, :title="invalidInputs.length + ' Invalid Inputs'", @click="clearInvalid") {{ invalidInputs.length }}
		textarea.form-control(v-model="displayValue", rows="1", style="width: 340px")
		.invalid-summary(v-if="invalidInputs.length")
			.text-danger(v-for="r in invalidInputs") {{ r }}

</template>
<style lang="scss">
i.la.la-check {
  color: #0e6b0e;
}
i.la.la-magic {
  color: #00aaaa;
}
</style>
<script>
export default {
  name: 'ClickList',
  props: {
    label: String,
    value: String,
  },
  data() {
    return {
      clicks: [],
      invalidInputs: [],
      formValid: false,
    };
  },
  methods: {
    /**
     * @param {string} str
     */
    validClickId(str) {
      const v1Regex = /^(?:[0-9a-f]{24}|[0-9a-f]{24}_\d{3,10}_\d{3,10})$/i;
      // eslint-disable-next-line no-useless-escape
      const v3Regex = /[0-9a-f]{24}_[0-9a-zA-Z\-]+_[0-9a-zA-Z\-]+_[0-9a-zA-Z\-]+_[0-9a-zA-Z\-]+/i;
      return v1Regex.test(str) || v3Regex.test(str);
    },
    isUrl(str) {
      return str.match(/^(http(s)?:\/\/)/);
    },
    parseClicks() {
      this.formValid = true;
      this.invalidInputs = [];
      this.$emit('input', this.clicks.join('\n'));
    },
    clearInvalid() {
      this.invalidInputs = [];
    },
  },
  computed: {
    displayValue: {
      get: function () {
        return this.value;
      },
      set: function (modifiedValue) {
        let rows = modifiedValue ? modifiedValue.split('\n') : [];
        this.invalidInputs = [];
        this.formValid = true;
        this.clicks = rows
          .map((r) => {
            if (this.isUrl(r)) {
              this.formValid = false;
              const params = new URL(r).searchParams;
              if (params.has('clickid')) {
                r = params.get('clickid');
              }
            }
            return r;
          })
          .filter((c) => {
            let isValid = this.validClickId(c);
            if (!isValid) {
              this.invalidInputs.push(c);
              return false;
            }
            return true;
          });
        this.$emit('input', modifiedValue);
      },
    },
  },
};
</script>
