<template>
  <div class="page-wrapper" :class="pageClass" v-if="user">
    <notifications group="default" />
    <div id="sidebar">
      <router-link :to="{ name: 'dashboard' }">
        <div class="logo">
          <img alt="Logo" :src="'/assets/' + platformName + '/logo.png'" />
        </div>
      </router-link>
      <SideMenu />
      <div class="octava" v-if="poweredByOvalio">
        <img
          alt="Logo"
          src="/assets/ovalio-icon.png"
          width="50px"
          class="mb-1"
          style="opacity: 0.75"
        />
        <h3>
          OCTAVA
          <span style="font-size: 1rem">V2.1</span>
        </h3>
        <div class="text-muted">
          <small>Powered by</small>
          <div>Ovalio Solutions LTD</div>
        </div>
      </div>
    </div>
    <div class="wrap">
      <header class="layout-header">
        <a href="javascript:void(0);" class="nav-btn" @click="toggleSidebar">
          <i class="la la-bars la-lg"></i>
        </a>
        <div class="user-info">
          <span class="mr-3 name">{{ user.name }}</span>
          <span class="avatar-container">
            <b-avatar
              button
              @click="toggleProfileMenu()"
              :badge="newTotalNotifications"
              variant="info"
              badge-variant="warning"
            ></b-avatar>
            <ul
              role="menu"
              class="profile-dropdown-menu dropdown-menu dropdown-menu-right"
              :class="{ show: showProfileMenu }"
            >
              <li role="presentation">
                <a class="dropdown-item" @click="goToProfileSettings()" href="javascript:void(0);">
                  <i class="la la-cog mr-2"></i>
                  Profile Settings
                </a>
              </li>
              <li role="presentation">
                <a class="dropdown-item" @click="goToNotifications()" href="javascript:void(0);">
                  <i class="la la-bell mr-2"></i>
                  General Notifications
                  <b-badge
                    style="margin-left: 10px"
                    v-if="notification.general"
                    variant="warning"
                    pill
                    >{{ notification.general }}</b-badge
                  >
                </a>
              </li>
              <!--							<li role="presentation">-->
              <!--								<a class="dropdown-item" @click="goToUpdates()" href="javascript:void(0);">-->
              <!--									<i class="la la-star mr-2"></i>-->
              <!--									Updates <b-badge style="margin-left: 10px" v-if="newUpdates" variant="warning" pill>{{ newUpdates }}</b-badge>-->
              <!--								</a>-->
              <!--							</li>-->
              <li role="presentation">
                <a
                  class="dropdown-item"
                  @click="goToRuleNotifications()"
                  href="javascript:void(0);"
                >
                  <i class="la la-star mr-2"></i>
                  Rule Notifications
                  <b-badge
                    style="margin-left: 10px"
                    v-if="notification.rules"
                    variant="warning"
                    pill
                    >{{ notification.rules }}</b-badge
                  >
                </a>
              </li>
              <li role="presentation">
                <hr role="separator" aria-orientation="horizontal" class="dropdown-divider" />
              </li>
              <li role="presentation">
                <a class="dropdown-item" @click="logout()" href="javascript:void(0);">
                  <i class="la la-sign-out mr-2"></i>
                  Sign out
                </a>
              </li>
            </ul>
          </span>
        </div>
      </header>
      <div id="content">
        <router-view />
      </div>
    </div>

    <vue-progress-bar></vue-progress-bar>
  </div>
</template>
<script>
import Vue from 'vue';
import SideMenu from '../components/SideMenu';

export default {
  name: 'Layout',
  components: { SideMenu },
  computed: {
    pageClass() {
      return (
        (this.toggledSidebar ? 'sidebar-toggled' : 'sidebar-default') +
        ' theme-' +
        this.platformName
      );
    },
    user() {
      return this.$store.state.user;
    },
    toggledSidebar() {
      return this.$store.state.toggledSidebar;
    },
  },
  data() {
    return {
      platformName: Vue.prototype.$platformName,
      poweredByOvalio: Vue.prototype.$poweredByOvalio,
      showProfileMenu: false,
      notification: {
        rules: false,
        general: false,
        updates: false,
      },
      newTotalNotifications: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch('setUser', null);
      this.$router.push({ name: 'login' });
    },
    toggleSidebar() {
      this.$store.commit('toggle', 'toggledSidebar');
    },
    toggleProfileMenu() {
      this.showProfileMenu = !this.showProfileMenu;
    },
    goToNotifications() {
      this.showProfileMenu = false;
      this.$router.push({ name: 'notifications' });
    },
    goToUpdates() {
      this.showProfileMenu = false;
      this.$router.push({ name: 'updates' });
    },
    goToRuleNotifications() {
      this.showProfileMenu = false;
      this.$router.push({ name: 'rule-notifications' });
    },
    goToProfileSettings() {
      this.showProfileMenu = false;
      this.$router.push({ name: 'profile' });
    },
    async countNewNotifications() {
      try {
        let resp = await this.$ovReq.get('notification/countNew', null);
        this.notification.general = resp.notifications ? '' + resp.notifications : false;
        this.notification.updates = resp.updates ? '' + resp.updates : false;
        this.notification.rules = resp.rules ? '' + resp.rules : false;
        this.newTotalNotifications =
          Object.values(this.notification).reduce((acc, n) => acc + (+n || 0), 0) || false;
      } catch (e) {
        // this.$ovNotify.error(e);
      }
    },
  },
  mounted() {
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$Progress.finish();

    this.countNewNotifications();
  },
  created() {
    // let user = localStorage.getItem('user');
    // let toggleSidebar = this.$store.state.toggledSidebar;
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start();
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        // parse meta tags
        this.$Progress.parseMeta(meta);
      }
      //  start the progress bar
      this.$Progress.start();
      //  continue to next page
      next();
    });
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach((/*to, from*/) => {
      //  finish the progress bar
      this.$Progress.finish();
      this.countNewNotifications();
    });
  },
};
</script>
