<template lang="pug">
ckeditor(:editor="editor", v-model="model", :config="editorConfig")
</template>

<script>
import 'ckeditor5-custom-build/build/ckeditor';

const authToken = localStorage.getItem('authToken');
const uploadUrl = process.env.VUE_APP_API_BASE_URL + 'api/admin/files/upload';

export default {
  name: 'Editor',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    editor() {
      return window.ClassicEditor;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  data() {
    return {
      editorConfig: {
        fontFamily: {
          supportAllValues: true,
        },
        language: 'en',
        mediaEmbed: {
          previewsInData: true,
        },
        simpleUpload: {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          uploadUrl,
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
        toolbar: [
          'heading',
          'fontFamily',
          '|',
          'bold',
          'italic',
          'underline',
          'bulletedList',
          'numberedList',
          '|',
          'insertTable',
          '|',
          'link',
          'uploadImage',
          'mediaEmbed',
        ],
      },
    };
  },
};
</script>
