<template lang="pug">
  .template-container
    .row
      .col-sm-4
        .form-group
          .d-flex.justify-content-between
            label {{ capLabel }}
            a.btn.btn-link(style="padding-top:0", @click="toggleMode()", v-b-tooltip.hover.right, :title="type==='combined' ? 'Split click & impression cap' : 'Combine caps'")
              i.la(:class="{'la-times': type!=='combined', 'la-columns': type==='combined'}")

          number-input(:value="value.cap", @input="(newValue) => emitChange(newValue, KEYS.CAP)")
          p.form-text Single user {{ visitsLabel }} / {{value.timeframe}} hrs

      .col-sm-4
        .form-group
          label Timeframe
          select.form-control(:value="value.timeframe", @input="(event) => emitChange(event.target.value, KEYS.TIMEFRAME)")
            option(v-for="o in frequencyCapTimeframeOptions" :value="o.v") {{ o.t }}

      .col-sm-4
        .form-group
          label Identify User Via
          select.form-control(:value="value.fingerprint", @input="(event) => emitChange(event.target.value, KEYS.FINGERPRINT)")
            option(value="ip_ua") IP & UA
            option(value="device") Device ID
            option(value="ip") IP only


</template>

<style lang="scss"></style>

<script>
export default {
  name: 'frequency-cap-obj',
  props: ['value', 'type'],
  data() {
    return {
      frequencyCapTimeframeOptions: [
        { v: 24, t: '24 Hours' },
        { v: 12, t: '12 Hours' },
        { v: 6, t: '6 Hours' },
        { v: 3, t: '3 Hours' },
        { v: 2, t: '2 Hours' },
        { v: 1, t: '1 Hour' },
      ],
      KEYS: {
        CAP: 'cap',
        TIMEFRAME: 'timeframe',
        FINGERPRINT: 'fingerprint',
      },
    };
  },

  methods: {
    toggleMode() {
      this.$emit('mode', this.type !== 'combined' ? 'combined' : 'split');
    },

    emitChange(value, key) {
      const newValue = JSON.parse(JSON.stringify(this.value));
      newValue[key] = value;
      this.$emit('change', { ...newValue, type: this.type });
    },
  },

  computed: {
    capLabel() {
      switch (this.type) {
        case 'click':
          return 'Click Frequency Cap';
        case 'impression':
          return 'Impression Frequency Cap';
      }
      return 'Frequency Cap';
    },
    visitsLabel() {
      switch (this.type) {
        case 'click':
          return 'clicks';
        case 'impression':
          return 'impressions';
      }
      return 'visits';
    },
  },
};
</script>
