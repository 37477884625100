<template lang="pug">
	transition(name="slide-fade")
		.home
			//.widget
				.widget-header
					h1.title Dashboard

			.row
				.col-md-6
					.widget
						.widget-body
							report-graph(type="line", :data="graphPerformance", title="General Performance")
					.widget
						.widget-body
							report-graph(type="donut", :data="graphTopGeo", title="Geo Distribution")

				.col-md-6
					.widget
						.widget-body
							report-graph(type="donut", :data="graphTopAdvertisers", title="Top Advertisers")
					.widget
						.widget-body
							report-graph(type="donut", :data="graphTopPublishers", title="Top Publishers")

				//.col-md-6
					.widget
						.widget-body
							report-graph(type="line", :data="graph3Data", title="Top Campaigns")

</template>

<script>
import moment from 'moment';

export default {
  name: 'Dashboard',
  data() {
    return {
      graphTopGeo: {
        metrics: ['revenue'],
        dimensions: ['country'],
        sort: ['revenue', 'desc'],
        limit: 5,
      },
      graphTopPublishers: {
        metrics: ['revenue'],
        dimensions: ['publisher'],
        sort: ['revenue', 'desc'],
        limit: 10,
      },
      graphTopAdvertisers: {
        metrics: ['revenue'],
        dimensions: ['advertiser'],
        sort: ['revenue', 'desc'],
        limit: 10,
      },
      graphPerformance: {
        metrics: [
          'revenue',
          'cost',
          'profit',
          'profit_percent',
          'clicks',
          'installs',
          'conversions',
          'reported_conversions',
          'events',
          'reject_events',
        ],
        transformData: {
          metrics: {
            clicks: function (v) {
              return Math.round(v / 1000);
            },
          },
        },
        transformLabel: {
          metrics: {
            clicks: function (v) {
              return v + ' (k)';
            },
          },
        },
        dimensions: [],
        group_timeframe: 'day',
        date_start: moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm'),
        date_end: moment().format('YYYY-MM-DD HH:mm'),
        sort: ['day', 'desc'],
        limit: 10,
      },
      graph3Data: {
        metrics: ['revenue', 'cost', 'profit'],
        dimensions: ['offer'],
        sort: ['revenue', 'desc'],
        limit: 10,
      },
    };
  },
};
</script>
