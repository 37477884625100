<template lang="pug">

div
  loading(:active.sync="busy", :is-full-page="true")

  .d-flex.align-items-end
    .form-group.mr-2
      label Log Type
      select.form-control(v-model="logType")
        option(value="") All
        option(value="user") User
        option(value="api") API
        option(value="system") System

    .form-group.no-label
      .button.btn.btn-primary(type="button", @click="loadData()") Go

  .ov-table-wrapper
    paginate(:paginator="paginate", @update-page-size="loadData")

    table.table.table-bordered
      thead
        tr
          th Date
          th(width="33%") Field
          th(width="33%") Before
          th(width="33%") After
          th Type
          th User
      tbody
        tr(v-if="records.length===0")
          td(colspan="11") No matching records were found
        tr(v-for="r in records", v-bind:key="r.id")
          td {{ r.created_at }}

          td.nested-table-container(colspan="3")
            table.nested-table(v-if="shouldShowChanges(r)")
              tbody
                tr(v-for="c in r.data.changes", v-if="shouldShowField(c.f)", v-bind:key="c.f")
                  td.nested-table-cell <strong>{{ getFieldLabel(c.f) }}</strong>
                  td.nested-table-cell
                    activity-log-table-pipe(:type="getFieldType(c.f)", :value="c.o || c.old")
                  td.nested-table-cell
                    activity-log-table-pipe(:type="getFieldType(c.f)", :value="c.n || c.new")
            div(v-else, class="no-changes-container d-flex align-items-center pl-2")
              p(class="font-weight-bold m-0") {{ getNoChangesMessage(r) }}

          td {{ getTypeText(r.type) }}

          td {{ r.user ? r.user.name : r.user_id }}

    paginate(:paginator="paginate", @update-page-size="loadData")
  
  </template>

<style lang="scss">
.nested-table-container {
  padding: 0 !important;
  border: 0 !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.nested-table {
  width: 100%;
  height: 55px;
}

.nested-table-cell {
  width: 33%;
  max-width: 1px; // strange hack to prevent cells to stretch with a non-breaking content like a long link
  border: 0 !important;
  border-right: 1px solid #dee2e6 !important;
  box-shadow: 0px 1px 0 0 #dee2e6;
  word-wrap: break-word;

  &:last-child {
    border-right: 0 !important;
  }
}

.no-changes-container {
  height: 54px;
}
</style>

<script>
import { getFieldLabel, getFieldType, shouldShowField } from './activity-log-fields-map';

/** @typedef {'offer.create' | 'offer.edit' | 'offer.status' | 'placement.create' | 'placement.edit' | 'placement.status' | 'advertiser.create' | 'advertiser.edit' | 'publisher.create' | 'publisher.edit'} ActivityLogType */
/**
 * @typedef {Object} ActivityLogTableRecord
 * @property {number} id
 * @property {number} advertiser_id
 * @property {number} offer_id
 * @property {number} placement_id
 * @property {number} publisher_id
 * @property {ActivityLogType} type
 * @property {number} user_id
 * @property {{ name: string }} user
 * @property {string} created_at
 * @property {string} description
 * @property {{ changes: { f: string; n: any; o: any | null }[] }} data
 */

export default {
  name: 'ActivityLogTable',
  props: {
    type: {
      type: [String],
    },
    id: {
      type: [Number],
    },
    publishers: {
      type: [Array],
    },
  },
  data() {
    return {
      busy: false,
      records: [],
      logType: '',
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadData();
        },
      },
    };
  },
  methods: {
    getFieldLabel,

    getFieldType,

    shouldShowField,

    async loadData() {
      const params = {
        page: this.paginate.page,
        page_size: this.paginate.limit,
        action: `${this.$props.type}%`,
        type: this.logType,
      };

      params[`${this.$props.type}_id`] = this.$props.id;

      try {
        const resp = await this.$ovReq.get('activityLog/getList', { params });
        this.records = resp.records;
        this.paginate.total = resp.total;
        this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
      } catch (e) {
        console.error(e);
      } finally {
        this.busy = false;
      }
    },

    /** @param {ActivityLogTableRecord} record */
    getNoChangesMessage(record) {
      const getNewStatus = () => {
        return record.data.changes[0].new || record.data.changes[0].n;
      };
      const getPublisherConnectMessage = () => {
        const publisher = this.$props.publishers.find((p) => p.id === record.publisher_id);
        if (!publisher) return 'Publisher not found';
        const { name } = publisher;
        if (record.action === 'placement.create') return `Connect to publisher ${name}`;
        if (record.action === 'placement.status') {
          if (getNewStatus() === 'new') return `Connect to publisher ${name}`;
          if (getNewStatus() === 'live') return `Connect to publisher ${name}`;
          if (getNewStatus() === 'paused') return `Disconnect from publisher ${name}`;
        }
      };

      /** Detect publisher connect and disconnect */
      if (this.isOfferPublisherConnect(record)) {
        return getPublisherConnectMessage();
      }

      const [entity, action] = record.action.split('.');
      const entityLabel = entity.charAt(0).toUpperCase() + entity.slice(1);
      let actionLabel = action.charAt(0).toUpperCase() + action.slice(1);
      if (actionLabel === 'Create') {
        actionLabel = 'Created';
      } else if (actionLabel === 'Edit') {
        actionLabel = 'Edited';
      }
      return `${entityLabel} was ${actionLabel}`;
    },

    /** @param {ActivityLogTableRecord} record */
    isOfferPublisherConnect(record) {
      return (
        this.$props.type === 'offer' &&
        (record.action === 'placement.create' || record.type === 'placement.status')
      );
    },

    /** @param {ActivityLogTableRecord} record */
    shouldShowChanges(record) {
      if (this.isOfferPublisherConnect(record)) {
        return false;
      }
      return record.data.changes;
    },

    /** @param {'user' | 'api' | 'system'} type */
    getTypeText(type) {
      if (type === 'api') return 'API';
      return type.charAt(0).toUpperCase() + type.slice(1);
    },
  },
  created() {
    this.loadData();
  },
};
</script>
