<template lang="pug">
	.form-group
		label Impressions rate
		input.form-control(type="text", placeholder="No change", v-model="displayValue", @blur="isInputActive=false", @focus="isInputActive=true")
		p.form-text.text-muted(:class="{invalid: inputInvalid}") {{ ratioText }}
</template>
<style lang="scss">
p.form-text.invalid {
  color: #9b0000 !important;
}
</style>
<script>
export default {
  name: 'ImpressionRatio',
  props: {
    value: {
      type: [String, Number],
    },
  },
  data() {
    return {
      isInputActive: false,
      inputInvalid: false,
    };
  },
  computed: {
    displayValue: {
      get: function () {
        if (this.isInputActive) {
          /** Cursor is inside the input field. unformat display value for user */
          return this.value.toString();
        } else {
          /** User is not modifying now. Format display value for user interface */
          return this.value + '%';
        }
      },
      set: function (modifiedValue) {
        /** Recalculate value after ignoring "$" and "," in user input */
        // eslint-disable-next-line
        let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ''));
        /** Ensure that it is not NaN */
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.inputInvalid = newValue < 0 || newValue > 100;

        /** Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component */
        /** $emit the event so that parent component gets it */
        this.$emit('input', newValue);
      },
    },
    ratioText() {
      let percentage = this.value;
      if (!percentage) {
        return 'No change (as sent by publisher)';
      }
      if (percentage < 0 || percentage > 100) {
        return 'Invalid value';
      }
      if (percentage === 100) {
        return 'Only impressions';
      }
      if (percentage > 50) {
        let clickRatio = 1 / ((100 - percentage) / 100) - 1;
        return `${parseFloat(clickRatio.toFixed(2))} impressions per click`;
      } else {
        let impressionRatio = 1 / (percentage / 100) - 1;
        return `${parseFloat(impressionRatio.toFixed(2))} clicks per impression`;
      }
    },
  },
};
</script>
