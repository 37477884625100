<template lang="pug">
.widget
  .widget-header
    h2.title OS, ISP & Carrier Restrictions

  .widget-body
    b-form-checkbox(v-model="value.should_block_wifi") Block WiFi

    h3.widget-section-title ISP Whitelist
    .form-group
      label ISP Providers
      multiselect(:multiple="true", :options="isps", v-model="ispWhitelist",
        track-by="v", label="t", deselect-label="", select-label="", @input="emitChange")

    h3.widget-section-title Carrier Whitelist
    .form-group
      label Carrier Providers
      multiselect(:multiple="true", :options="carriers", v-model="carrierWhitelist",
        track-by="v", label="t", deselect-label="", select-label="", @input="emitChange")

    h3.widget-section-title OS Targeting
    .form-group
      label OS
      select.form-control(v-model="value.os_targeting")
        option(v-for="os in oses" :value="os.value") {{ os.label }}
    .form-group(v-if="value.os_targeting")
      label.font-weight-bold Allowed version range (DEPRECATED)
      .row
        .col-md-6
          label Minimum version
          input.form-control(v-model="value.os_targeting_version_min", placeholder="No limit")
        .col-md-6
          label Maximum version
          input.form-control(v-model="value.os_targeting_version_max", placeholder="No limit")
    os-targeting-version-blacklist(v-if="value.os_targeting", v-model="value.os_version_blacklist")

    a(href="javascript:void(0);", v-if="value.os_targeting", @click="shouldShowAdvancedFilters = !shouldShowAdvancedFilters")
      span(v-if="!shouldShowAdvancedFilters")
        i.la.la-angle-down
      span(v-if="shouldShowAdvancedFilters")
        i.la.la-angle-up
      span Advanced

    .form-group(v-if="shouldShowAdvancedFilters")
      h3.widget-section-title Advanced

      div(v-if="value.advanced_targeting_blacklist.length")
        label.font-weight-bold Blacklist
        .d-flex.flex-wrap.gap-1.mb-2
          b-badge.fs-8(
            pill,
            variant="danger",
            v-for="o in value.advanced_targeting_blacklist", 
            :key="JSON.stringify(o)"
          )
            span.mr-1 {{ o.os_version_range.min }} - {{ o.os_version_range.max }} / {{ ispsHash[o.isp_id] }} / {{ carriersHash[o.carrier_id] }} 
            i.la.la-times-circle.clickable(@click="removeAdvancedTargetingElement({type:'blacklist', index:index})")
      div(v-if="value.advanced_targeting_whitelist.length")
        label.font-weight-bold Whitelist
        .d-flex.flex-wrap.gap-1.mb-2
          b-badge.fs-8(
            pill,
            variant="success",
            v-for="o in value.advanced_targeting_whitelist",
            :key="JSON.stringify(o)"
          ) 
            span.mr-1 {{ o.os_version_range.min }} - {{ o.os_version_range.max }} / {{ ispsHash[o.isp_id] }} / {{ carriersHash[o.carrier_id] }}
            i.la.la-times-circle.clickable(@click="removeAdvancedTargetingElement({type:'whitelist', index:index})")

      .form-group
        label.font-weight-bold OS Version Range
        .row
          .col-md-6
            label Minimum version
            input.form-control(v-model="advancedTargetingElementForm.os_version_range.min", placeholder="No limit")
          .col-md-6
            label Maximum version
            input.form-control(v-model="advancedTargetingElementForm.os_version_range.max", placeholder="No limit")
      .form-group
        label.font-weight-bold ISP & Carrier
        .row 
          .col-md-6
            label ISP Provider
            multiselect(:multiple="false", :options="isps", v-model="advancedTargetingElementForm.isp",
              track-by="v", label="t", deselect-label="", select-label="")
          .col-md-6
            label Carrier Provider
            multiselect(:multiple="false", :options="carriers", v-model="advancedTargetingElementForm.carrier",
              track-by="v", label="t", deselect-label="", select-label="")
      .form-group.d-flex.gap-2
        button.btn.btn-success(type="button", @click="addAdvancedTargetingElement('whitelist')") Add to whitelist
        button.btn.btn-danger(type="button", @click="addAdvancedTargetingElement('blacklist')") Add to blacklist
</template>

<style lang="scss"></style>

<script>
import Vue from 'vue';

/**
 * @typedef {Object} Option
 * @property {number} v
 * @property {string} t
 */

export default {
  name: 'os-network-targeting',
  props: {
    value: {
      type: [Object],
      required: true,
    },
  },
  async mounted() {
    await this.loadOptions();
    this.carrierWhitelist = this.value.carrier_whitelist.map((carrierId) => {
      const carrierRecord = this.carriers.find(({ v }) => v === carrierId);
      return carrierRecord;
    });
    this.ispWhitelist = this.value.isp_whitelist.map((ispId) => {
      const ispRecord = this.isps.find(({ v }) => v === ispId);
      return ispRecord;
    });
  },
  computed: {
    carriersHash() {
      return Vue.ovData.geo.carrier._hash;
    },
    ispsHash() {
      return Vue.ovData.geo.isp._hash;
    },
  },
  data() {
    return {
      /** @type {Option[]} */
      carriers: [],
      /** @type {Option[]} */
      isps: [],
      oses: [
        { value: null, label: 'No restrictions' },
        { value: 'android', label: 'Android' },
        { value: 'ios', label: 'iOS' },
      ],
      /** @type {Option[]} */
      carrierWhitelist: [],
      /** @type {Option[]} */
      ispWhitelist: [],
      shouldShowAdvancedFilters: false,
      advancedTargetingElementForm: {
        os_version_range: {
          /** @type {string | null} */
          min: null,
          /** @type {string | null} */
          max: null,
        },
        /** @type {Option | null} */
        carrier: null,
        /** @type {Option | null} */
        isp: null,
      },
    };
  },
  methods: {
    emitChange() {
      this.$emit('input', {
        os_targeting: this.value.os_targeting,
        os_targeting_version_min: this.value.os_targeting_version_min,
        os_targeting_version_max: this.value.os_targeting_version_max,
        os_version_blacklist: this.value.os_version_blacklist,
        carrier_whitelist: this.carrierWhitelist.map(({ v }) => v),
        isp_whitelist: this.ispWhitelist.map(({ v }) => v),
        should_block_wifi: this.value.should_block_wifi,
        advanced_targeting_whitelist: this.value.advanced_targeting_whitelist,
        advanced_targeting_blacklist: this.value.advanced_targeting_blacklist,
      });
    },

    async loadOptions() {
      const [carriers, isps] = await Promise.all([
        Vue.ovData.geo.carrier.getOptions(),
        Vue.ovData.geo.isp.getOptions(),
      ]);
      this.carriers = carriers;
      this.isps = isps;
    },

    /**
     * Add an element to the advanced targeting list.
     * @param {'whitelist'|'blacklist'} type
     */
    addAdvancedTargetingElement(type) {
      const { os_version_range, carrier, isp } = this.advancedTargetingElementForm;
      if (
        os_version_range.min === null
        || os_version_range.max === null
        || carrier === null
        || isp === null
      ) {
        this.$ovNotify.error('Please fill all advanced targeting fields');
        return;
      }

      if (Number.isNaN(this.versionToNumber(os_version_range.min))) {
        return this.$ovNotify.error('OS minimum version is not a valid version number');
      }
      if (Number.isNaN(this.versionToNumber(os_version_range.max))) {
        return this.$ovNotify.error('OS maximum version is not a valid version number');
      }

      if (os_version_range.min && os_version_range.max) {
        const minNumber = this.versionToNumber(os_version_range.min);
        const maxNumber = this.versionToNumber(os_version_range.max);
        if (minNumber > maxNumber) {
          this.$ovNotify.error('OS minimum version must be less than OS maximum version');
          return;
        }
      }

      const key = `advanced_targeting_${type}`;
      this.value[key].push({
        os_version_range,
        carrier_id: carrier.v,
        isp_id: isp.v,
      });
      this.emitChange();
      this.advancedTargetingElementForm = {
        os_version_range: {
          min: null,
          max: null,
        },
        carrier: null,
        isp: null,
      };
    },

    /**
     * Remove an element from the advanced targeting list.
     * @param {{type: 'blacklist' | 'whitelist', index: number}} param
     */
    removeAdvancedTargetingElement({ type, index }) {
      const key = `advanced_targeting_${type}`;
      this.value[key].splice(index, 1);
      this.emitChange();
    },

    /**
     * Convert a version string into a number.
     * @param {string} version - The version string to convert in the format "major.minor.patch.build".
     * @returns {number} - The version number.
     */
    versionToNumber(version) {
      const numbers = version.split('.').map(Number);

      while (numbers.length < 4) {
        for (let i = 0; i < 4 - numbers.length; i++) {
          numbers.push(0);
        }
      }

      const [major, minor, patch, build] = numbers;
      return major * 1000000 + minor * 10000 + patch * 100 + build;
    },
  },
};
</script>
