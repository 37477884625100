<template lang="pug">
	div.page-login

		.widget
			.widget-body
				h1(v-if="!poweredByOvalio") Panel Access
				h1(v-if="poweredByOvalio") OCTAVA
				.logo
					img(alt="Logo", :src="'/assets/'+platformName+'/logo-black.png'", :onerror="fallbackLogo")
				h2.widget-section-title Forgot your password?

				form(@submit.prevent="submit")
					.form-group
						input.form-control(type="email", v-model="email", placeholder="Email")
					.alert.alert-danger(v-if="errorText") {{ errorText }}
					.alert.alert-success(v-if="successText") {{ successText }}
					.form-group.text-right
						button.btn.btn-sm.btn-primary(type="submit", :disabled="busy")
							| Send
							=' '
							.la(:class="{'la-arrow-right':!busy,'la-spinner la-spin':busy}")
					.form-group.text-center
						router-link(:to="{name:'login'}") Back to Login
</template>
<style lang="scss">
.page-login {
  padding-top: 20vh;
  text-align: center;

  .widget {
    max-width: 460px;
    width: 100%;
    margin: auto;

    h1 {
      font-size: 30px;
      font-weight: 300;
      margin: 0 0 15px 0;
    }
  }

  .logo {
    text-align: center;
    padding: 20px;
    img {
      max-width: 100%;
      max-height: 200px;
    }
    @media (max-width: 767px) {
      padding: 10px 0;
      img {
        max-height: 50px;
      }
    }
  }
}
</style>
<script>
import Vue from 'vue';

export default {
  data() {
    return {
      errorText: '',
      successText: '',
      busy: false,
      email: '',
      platformName: Vue.prototype.$platformName,
      poweredByOvalio: Vue.prototype.$poweredByOvalio,
    };
  },
  computed: {
    fallbackLogo() {
      return 'this.src="/assets/' + Vue.prototype.$platformName + '/logo.png";this.onerror=""';
    },
  },
  methods: {
    submit() {
      if (this.busy) {
        return;
      }
      let data = {
        email: this.email,
      };
      this.errorText = '';
      this.successText = '';
      this.busy = true;

      let forgotPassUrl = process.env.VUE_APP_API_BASE_URL + 'api/auth/forgot-password';
      this.$http.post(forgotPassUrl, data).then(
        (response) => {
          this.busy = false;
          this.errorText = '';
          if (response.body && response.body.err) {
            this.errorText = response.body.errdesc;
          } else {
            this.successText = 'Instructions were sent to your email address';
          }
        },
        (response) => {
          this.busy = false;
          // error callback
          let errdesc = '';
          if (response.body && response.body.err) {
            errdesc = response.body.errdesc;
          } else {
            errdesc = 'There has been an error';
          }
          this.errorText = errdesc;
        },
      );
    },
  },
};
</script>
