<template lang="pug">
	div.default-modal
		loading(:active.sync="busy", :is-full-page="false")
		.modal-header
			.modal-title Edit Offer <span v-if="offer">({{ offer.id }})</span>
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			.modal-body-inner
				form(@submit.prevent="submit", v-if="offer")
					.form-group(v-if="!caps_only")
						label Status
						select.form-control(v-model="offer.status", name="status")
							option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
					//.form-group
						label Name (Inner Use)
						input.form-control(type="text", placeholder="", v-model="offer.name")
					.form-group(v-if="!caps_only")
						label Name
						input.form-control(type="text", placeholder="", v-model="offer.name")
					.row
						.col-sm-6
							.form-group
								label Click Cap
								number-input(v-model="offer.daily_click_cap")
						.col-sm-6
							.form-group
								label Impression Cap &nbsp;
									i.la.la-info-circle(v-if="offer.daily_click_cap > 0 && !offer.daily_impression_cap", v-b-tooltip.hover.right, title="There is no cap on impressions, but once the click cap is reached offer will be paused")
								number-input(v-model="offer.daily_impression_cap")
					.row
						.col-sm-6
							.form-group
								label Daily Conversion Cap
								number-input(v-model="offer.daily_conversion_cap")
						.col-sm-6
							.form-group
								label Weekly Conversion Cap
									i.la.la-info-circle.ml-1(v-b-tooltip.hover.right, title="Monday to Sunday")
								number-input(v-model="offer.weekly_conversion_cap")
					.row
						.col-sm-6
							.form-group
								label Daily Install Cap
								number-input(v-model="offer.daily_install_cap")
					.row
						.col-sm-4
							.form-group
								b-form-checkbox(v-model="offer.lock_caps") Lock caps
						.col-sm-4
							.form-group
								b-form-checkbox(v-model="offer.lock_conversion_caps") Lock conversion caps

					.buttons.text-right
						button.btn.btn-primary(type="submit") Save
							i.la.la-fw.la-save
</template>
<script>
import Vue from 'vue';
export default {
  name: 'QuickEditOfferModal',
  props: ['id', 'caps_only'],
  methods: {
    async submit() {
      if (this.busy) {
        return;
      }
      let data = Vue.util.extend({}, this.offer);
      Vue.ovData.offer._booleans.forEach((f) => {
        data[f] = data[f] ? 1 : 0;
      });
      try {
        await this.$ovReq.post('offer/quickSave', data);
        this.$ovNotify.success('Offer has been saved');
        this.$emit('close');
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
  data() {
    return {
      busy: false,
      offer: null,
      statusOptions: [
        { v: 'draft', t: 'Draft' },
        { v: 'live', t: 'Live' },
        { v: 'paused', t: 'Paused' },
        { v: 'cancelled', t: 'Cancelled' },
        // {v: 'ended', t: 'Ended'}
      ],
    };
  },
  async created() {
    this.busy = true;
    try {
      this.offer = await this.$ovData.offer.get(this.id, true);
    } catch (e) {
      console.error(e);
      this.$emit('close');
    }
    this.busy = false;
  },
};
</script>
