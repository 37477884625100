<template lang="pug">
	.priority-container
		i.priority-icon.la(@click="incValue", :class="getClass(content)")
</template>

<script>
export default {
  name: 'Priority',
  props: ['value'],
  watch: {
    value: function (newVal, oldVal) {
      // watch it
      this.content = newVal;
    },
  },
  data() {
    return {
      content: +this.value,
    };
  },
  methods: {
    incValue() {
      this.content = (this.content + 1) % 8;
      this.$emit('input', this.content);
    },
    getClass(val) {
      switch (val) {
        case 0:
          return 'la-times';
        case 1:
          return 'la-angle-double-down';
        case 2:
          return 'la-angle-down';
        case 3:
          return 'la-caret-down';
        case 4:
          return 'la-minus';
        case 5:
          return 'la-caret-up';
        case 6:
          return 'la-angle-up';
        case 7:
          return 'la-angle-double-up';

        default:
          return '';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.priority-container {
  &:hover {
    cursor: pointer;
  }
}
.priority-icon {
  font-size: 22px;
  &.la-angle-double-up,
  &.la-angle-up,
  &.la-caret-up {
    color: #0e6b0e;
  }
  &.la-minus {
  }
  &.la-angle-down,
  &.la-angle-double-down,
  &.la-caret-down {
    color: #ff5252;
  }
}
</style>
