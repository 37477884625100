<template lang="pug">
	.widget
		.widget-header
			h2.title Reported Postbacks
		.widget-body
			table.table.table-bordered.table-striped.table-condensed
				thead
					tr
						th
							.text ID
						th
							.text Status
						th
							.text Type
						th
							.text URL
						th
							.text HTTP Resp.
						th
							.text HTTP Status
						th
							.text Error
						th
							.text Time
				tbody
					tr(v-for="p in postbacks")
						td {{ p.id }}
						td
							.badge.badge-success(v-if="p.status===1") Sent
							.badge.badge-warning(v-if="p.status===2") Retry
							.badge.badge-danger(v-if="p.status===3") Error
						td
							.badge.badge-primary(v-if="p.type==='event'") Event
							.badge.badge-info(v-if="p.type==='conversion'") Install
							.badge.badge-danger(v-if="p.type==='reject'") Rejection
						td
							textarea.form-control(rows="3", readonly="readonly") {{ p.url }}
						td {{ p.http_response }}
						td {{ p.http_status }}
						td {{ p.error_string }}
						td {{ p.last_retry_fmt || p.created_at }}

			.buttons.text-right
				router-link.btn.btn-secondary(href="javascript:void(0);", :to="backLink")
					i.fa.fa-arrow-left
					| &nbsp; Back
</template>

<script>
export default {
  name: 'PostbacksTable',
  props: {
    postbacks: [],
    backLink: {
      type: [String, Object],
    },
  },
};
</script>
