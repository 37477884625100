<template>
  <div id="app">
    <router-view />
    <div class="cat-image-wrapper" v-if="showCatImage">
      <div class="cat-image-box" @click="hideImage()">
        <img :src="selectedImage" />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.cat-image-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  align-items: center;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.cat-image-box {
  width: 500px;
  height: 500px;
  /*background: #cc0000;*/
  margin: auto;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
<script>
export default {
  data() {
    return {
      selectedIndex: 0,
      selectedImage: '/assets/cat-cute.jpg',
      catImages: ['cat-cute.jpg', 'grumpy.jpg', 'wet-cat.jpeg'],
      showCatImage: false,
    };
  },
  computed: {
    USER() {
      return this.$store.state.user;
    },
  },
  methods: {
    showImage() {
      this.selectedIndex = Math.floor(Math.random() * this.catImages.length);
      this.selectedImage = `/assets/` + this.catImages[this.selectedIndex];
      this.showCatImage = true;
    },
    hideImage() {
      this.showCatImage = false;
      setInterval(
        () => {
          this.showImage();
        },
        24 * 60 * 1000,
      );
    },
  },
  mounted() {
    // setTimeout(() => {
    // 	if (this.USER.fun_mode) {
    // 		console.log('Fun mode on');
    // 		setTimeout(() => {
    // 			this.showImage();
    // 		}, 20 * 1000);
    // 	}
    // }, 5000);
  },
};
</script>
