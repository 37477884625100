<script>
import { ref } from 'vue';

export default {
  name: 'MenuItem',
  props: {
    item: {
      type: {
        title: String,
        slug: String,
        children: {
          type: Array,
          required: false,
        },
      },
    },
  },
  data() {
    const isExpanded = ref(false);
    return { isExpanded };
  },
  computed: {
    isActive() {
      const pathSlug = this.$route.path.split('/docs/').pop();
      const isActive = pathSlug === this.$props.item.slug;
      return isActive;
    },
  },
};
</script>

<template lang="pug">
li.pt-3.pl-3.fs-6.item-container
  a.d-inline(v-if="item.children" href="javascript:void(0)" @click="isExpanded = !isExpanded") {{ item.title }}
    span.ml-2.d-inline.toggle-arrow.la(:class="isExpanded ? 'la-angle-up' : 'la-angle-down'")
    
  ul.list-unstyled(v-if="isExpanded && item.children")
    MenuItem(v-for="child in item.children" :item="child" :key="child.slug")

  router-link(v-if="!item.children" :to="item.slug" :class="isActive ? 'font-weight-bold' : ''") {{ item.title }}
</template>

<style lang="scss" scoped>
.item-container a {
  color: #a6b2c1;

  &:hover {
    color: #fff;
  }
}
</style>
