<script>
export default {
  name: 'PasswordInput',
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
      default: 'Password',
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    change(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<template lang="pug">
div(v-if="showPassword")
  input.form-control.d-inline(:value="value", :placeholder="placeholder" , @input="change")
  span.input-group-addon
    i.la.la-eye-slash(@click="togglePassword")
div(v-else)
  input.form-control.d-inline(type="password", :value="value", :placeholder="placeholder", @input="change")
  span.input-group-addon
    i.la.la-eye(@click="togglePassword")
</template>

<style lang="scss">
.input-group-addon {
  cursor: pointer;

  i {
    font-size: 20px;
  }

  position: relative;
  right: -193px;
  top: -25px;
}
</style>