// import moment from "moment";

import { OfferKpi } from "../entities/OfferKpi";

export default {
  install(Vue /*, options*/) {
    let api = {
      // enableSpecialFeatures: !!(+process.env.VUE_APP_SPECIAL_FEATURES),

      common: {
        getSelectedFromString: function (options, str) {
          let selected = [];
          if (str) {
            str += '';
            let itemList = str
              .split(',')
              .map((r) => +r)
              .filter((r) => r);
            options.forEach((o) => {
              if (itemList.indexOf(o.v) > -1) {
                selected.push(o);
              }
            });
          }
          return selected;
        },
      },

      general: {
        rejectReasons: [
          { v: 'ctit_anomalies' },
          { v: 'store_install_validation' },
          { v: 'site_blacklist' },
          { v: 'install_validation_rules' },
          { v: 'bots' },
          { v: 'click_flood' },
          { v: 'behavioral_anomalies' },
          { v: 'install_hijacking' },
          { v: 'validation_bots' },
          { v: 'validation_hijacking' },
        ],

        platformOptions: [
          { v: 'android', t: 'android' },
          { v: 'ios', t: 'ios' },
          { v: 'web', t: 'web' },
        ],
        currencyOptions: [
          { v: 'USD', t: 'USD' },
          { v: 'EUR', t: 'EUR', disabled: true },
        ],

        timezoneOptions: [
          { v: -8, t: '-08:00' },
          { v: -7, t: '-07:00' },
          { v: -6, t: '-06:00' },
          { v: -5, t: '-05:00' },
          { v: -4, t: '-04:00' },
          { v: -3, t: '-03:00' },
          { v: -2, t: '-02:00' },
          { v: -1, t: '-01:00' },
          { v: 0, t: '+00:00 UTC' },
          { v: 1, t: '+01:00' },
          { v: 2, t: '+02:00' },
          { v: 3, t: '+03:00' },
          { v: 4, t: '+04:00' },
          { v: 5, t: '+05:00' },
          { v: 6, t: '+06:00' },
          { v: 7, t: '+07:00' },
          { v: 8, t: '+08:00' },
        ],
        integrationTypeOptions: null,

        getIntegrations: async function () {
          if (!this.integrationTypeOptions) {
            try {
              let resp = await Vue.ovReq.get('settings/getIntegrations');
              console.log(resp);
              this.integrationTypeOptions = resp.integrations;
            } catch (e) {
              console.error(e);
            }
          }
          return this.integrationTypeOptions;
        },

        getUrlMacros: async function () {
          if (!this.urlMacros) {
            try {
              let resp = await Vue.ovReq.get('settings/getMacros');
              this.urlMacros = resp.macros;
            } catch (e) {
              console.error(e);
            }
          }
          return this.urlMacros;
        },
        urlMacros: null,

        payoutTypeOptions: [
          { v: 'CPA' },
          { v: 'CPI' },
          //{v: 'CPR'}, {v: 'CPC'}, {v: 'CPM'}, {v: 'CPS'}
        ],
        // type: [
        // 	{v:'pop', t: 'Pop'},
        // 	{v:'domain', t: 'Domain'},
        // 	{v:'push', t: 'Push'},
        // 	{v:'injection', t: 'Injection'},
        // ],
        // media: [
        // 	{v:'mobile', t: 'Mobile'},
        // 	{v:'desktop', t: 'Desktop'},
        // 	{v:'all', t: 'All'},
        // ],
        // format: [
        // 	{v:'xml', t: 'XML'},
        // 	{v:'json', t: 'JSON'},
        // 	{v:'openrtb', t: 'OpenRTB'},
        // ],
      },

      rule: {
        _booleans: ['active'],
        _options: [],
        _hash: {},
        actionOptions: [
          { v: 'alert', t: 'Alert' },
          // { v: 'pause', t: 'Pause' },
          // { v: 'partial_pause', t: 'Partial Pause' },
          // {v: 'fallback', t:'Fallback'},
        ],
        levelOptions: [
          { v: 'sub1', t: 'Subsource' },
          { v: 'sub2', t: 'Sub2' },
          { v: 'offer', t: 'Offer' },
          { v: 'placement', t: 'Placement' },
          { v: 'bundle', t: 'Bundle' },
        ],
        typeOptions: [
          { v: 'ctr', t: 'CTR' },
          { v: 'cr', t: 'CR' },
          { v: 'custom', t: 'Custom' },
        ],
        mediaTypeOptions: [
          { v: 'all', t: 'All' },
          { v: 'dsp', t: 'DSP' },
          { v: 'rewarded', t: 'Rewarded' },
          { v: 'media_buying', t: 'Media Buying' },
        ],
        /** @param {Array<{ v:string; t: string }>} additionalMetrics */
        metricOptions(additionalMetrics = []) {
          return [
            { v: 'passed_impressions', t: 'Passed Impressions' },
            { v: 'passed_clicks', t: 'Passed Clicks' },
            { v: 'installs', t: 'Installs' },
            { v: 'reject_events', t: 'Rejected Events' },
            { v: 'conversions', t: 'Conversions' },
            { v: 'rejected_and_install', t: 'Rj & Ins' },
            { v: 'blocked_percent', t: 'Blocked Percentage' },
            { v: 'cr', t: 'CR' },
            ...additionalMetrics,
          ];
        },
        rangeOptions: [
          { v: 'gt', t: 'Greater Than' },
          { v: 'lt', t: 'Lower Than' },
        ],
        async getOptions(reload) {
          if (!this._options.length || reload) {
            let resp = await Vue.ovReq.get('rule/getAll');
            this._options = resp.records.map((r) => ({
              v: r.id,
              t: r.name,
            }));
          }
          return this._options;
        },
      },

      user: {
        _booleans: [
          'status',
          'fun_mode',
          'notify_offer_manual_edit',
          'notify_offer_status_change',
          'notify_offer_po_change',
        ],
        _options: [],
        _hash: {},
        newInstance() {
          return {
            id: 0,
            email: '',
            password: '',
            name: '',
            type: 'am',
            status: true,
            permissions: null,
            partners_permissions: {
              advertisers: [],
              publishers: [],
            },
          };
        },
        async get(id, reload) {
          if (!this._hash[id] || reload) {
            let resp = await Vue.ovReq.get('user/get/' + id);
            resp.entity.status = !!resp.entity.status;
            this._hash[id] = resp.entity;
          }
          return this._hash[id];
        },
        async getOptions(reload, types) {
          if (!this._options.length || reload) {
            let resp = await Vue.ovReq.get('user/getOptions');
            this._options = resp.options;
          }
          if (types) {
            return this._options.filter((o) => types.includes(o.type));
          }
          return this._options;
        },
        async getPartnerPermissionGroups() {
          let resp = await Vue.ovReq.get('user/getPartnerPermissionGroups');
          return resp.permissions;
        },
        async getPartnerPermissionStructure() {
          let resp = await Vue.ovReq.get('user/getPartnerPermissionStructure');
          return resp.structure;
        },
        async getPermissionGroups() {
          let resp = await Vue.ovReq.get('user/getPermissionGroups');
          return resp.permissions;
        },
        async getPermissionStructure() {
          let resp = await Vue.ovReq.get('user/getPermissionStructure');
          return resp.structure;
        },
        async getPublisherUsers(publisherId) {
          let params = { page: 1, page_size: 100, type: 'publisher', publisher_id: publisherId };
          let resp = await Vue.ovReq.get('user/getList', { params });
          return resp.records;
        },
      },
      advertiser: {
        _booleans: [
          'status',
          'fetch_api',
          'auto_placements',
          'fetch_api_override_tracking',
          'fetch_api_override_events',
          'require_secret',
          'default_event_convert_payout',
          'default_require_validation',
          'api_load_active_only',
          'default_enable_smartlink',
          'default_hide_offers',
          'auto_connect_pubs',
        ],
        _options: [],
        _hash: {},
        newInstance() {
          return {
            id: 0,
            status: false,
            name: '',
            code: '',
            custom_install_event_name: '',
            auto_placements: false,
            mask_source: true,
            fetch_api: false,
            integration_type: null,
            scan_frequency: -1,
            redirect_type: 302,
            score_offset: 302,
            daily_subsource_limit: 0,
            total_subsource_limit: 0,
            integration_params: {},
            tracking_link_params: [],
            auto_payout: 0,
            payout_mode: null,
            custom_report: 0,
            risk_management: 0,
            report_offer_closed: 0,
            report_on_overcap: 0,
            risk_calc_mode: null,
            risk_calc_from: null,
            block_iframe: 0,
            block_proxies: 0,
            daily_click_cap: 0,
            daily_impression_cap: 0,
            daily_conversion_cap: 0,
            use_publisher_subsource_cap: 0,
            default_subsource_cap: 0,
            click_pacing: 0,
            click_pacing_hour: 0,
            impression_pacing: 0,
            impression_pacing_hour: 0,
            impression_rate: 0,
          };
        },
        async get(id, reload) {
          if (!this._hash[id] || reload) {
            let resp = await Vue.ovReq.get('advertiser/get/' + id);
            resp.entity.status = !!resp.entity.status;
            this._hash[id] = resp.entity;
          }
          return this._hash[id];
        },
        async getOptions(reload) {
          if (!this._options.length || reload) {
            let resp = await Vue.ovReq.get('advertiser/getOptions');
            this._options = resp.options;
          }
          return this._options;
        },
        async getSelectedOptions(str) {
          let selected = [];
          if (str) {
            str += '';
            let itemList = str
              .split(',')
              .map((r) => +r)
              .filter((r) => r);
            if (itemList.length) {
              let resp = await Vue.ovReq.get('advertiser/getOptionsById', {
                params: { advertiser_id: itemList.join(',') },
              });
              selected = resp.options;
            }
          }
          return selected;
        },
        separateCustomNames(advertiser) {
          /** if custom names include "_blank_" - pop and set "custom_install_event_name_blank" to true */
          advertiser.custom_install_event_name_blank = null;
          let customNames = advertiser.custom_install_event_name.split(',');
          let filteredNames = [];
          customNames.forEach((e) => {
            if (e === '_blank_') {
              advertiser.custom_install_event_name_blank = true;
            } else {
              filteredNames.push(e);
            }
          });
          advertiser.custom_install_event_name = filteredNames.join(',');
        },
      },

      publisher: {
        _booleans: [
          'status',
          'auto_report',
          'auto_placements',
          'require_terms',
          'all_demand',
          'auto_clean_feed',
          'report_offer_closed',
          'report_on_overcap',
          'full_transparency',
          'blank_fallback',
          'advanced_privacy_support',
          's2s_support',
          'ignore_freq_cap',
        ],
        _options: [],
        _hash: {},
        newInstance() {
          return {
            id: 0,
            status: false,
            name: '',
            code: '',
            default_placement_payout: 90,
            manual_report_threshold: 0,
            default_smartlink_pixel: 0,
            report_offer_closed: false,
            report_on_overcap: false,
            advanced_privacy_support: true,
            auto_placements: false,
            redirect_type: '302',
            // blocked_advertisers: [],
            postbacks: [],
            auto_report: true,
            auto_report_risk: 100,
            media_type: 'dsp',
            override_tracking_params: {},
          };
        },
        async get(id, reload) {
          if (!this._hash[id] || reload) {
            let resp = await Vue.ovReq.get('publisher/get/' + id);
            resp.entity.status = !!resp.entity.status;
            this._hash[id] = resp.entity;
          }
          return this._hash[id];
        },
        async getOptions(reload) {
          if (!this._options.length || reload) {
            let resp = await Vue.ovReq.get('publisher/getOptions');
            this._options = resp.options;
          }
          return this._options;
        },
        async getSelectedOptions(str) {
          let selected = [];
          if (str) {
            str += '';
            let itemList = str
              .split(',')
              .map((r) => +r)
              .filter((r) => r);
            if (itemList.length) {
              let resp = await Vue.ovReq.get('publisher/getOptionsById', {
                params: { publisher_id: itemList.join(',') },
              });
              selected = resp.options;
            }
          }
          return selected;
        },
      },

      externalOffer: {
        _hash: {},
        async get(id, reload) {
          if (!this._hash[id] || reload) {
            let resp = await Vue.ovReq.get('externalOffer/get/' + id);
            this._hash[id] = resp.entity;
          }
          return this._hash[id];
        },

        prepareOffer(eo) {
          let o = api.offer.newInstance();
          o.external_offer_id = eo.id;
          o.advertiser_id = eo.advertiser_id;

          o.external_offer_id = eo.id;
          o.advertiser_id = eo.advertiser_id;
          o.name = eo.name;
          o.description = eo.description;
          o.payout = eo.payout;
          o.currency = eo.currency;
          o.payout_type = eo.payout_type;
          o.country = eo.country;
          o.platform = eo.platform;
          o.tracking_url = eo.tracking_url;
          o.preview_url = eo.preview_url;
          o.daily_conversion_cap = eo.daily_conversion_cap;
          o.events = [];
          o.geo_targeting = eo.geo_targeting;

          if (eo.events) {
            eo.events.forEach((e) => {
              o.events.push({
                name: '' + e.name,
                label: '' + e.label,
                payout_mode: e.payout ? 'fixed' : 'none',
                payout: e.payout,
              });
            });
          }
          return o;
        },
      },

      subsource: {
        async getInfo(id) {
          return await Vue.ovReq.get('subsource/getInfo/' + id);
        },
      },

      offer: {
        _booleans: [
          'dynamic_payout',
          'auto_update',
          'hard_kpi',
          'default_enable_api',
          'auto_payout',
          'require_validation',
          'enable_smartlink',
          'platform_internal',
          'subsource_auto_generate',
          'allow_incent',
          'event_convert_payout_only',
          'normalize_bundleid',
          'report_retention',
          'lock_tracking_url',
          'lock_caps',
          'is_direct_link',
          'generate_impressions',
          'sub1_encode_prefix',
          'sub1_encode_id',
          'hide_caps',
          'restrict_timeframe',
          'data_enrichment_params',

          'block_proxies',
          'block_iframe',
          'block_pixels',
          'allow_multiple_conversions',
        ],
        _options: [],
        _hash: {},
        newInstance() {
          let emptyObj = { cap: 0, timeframe: 1, fingerprint: 'ip_ua' };
          let freqCapParams = {
            mode: 'combined',
            impression: emptyObj,
            click: emptyObj,
            combined: emptyObj,
          };
          return {
            id: 0,
            name: '',
            status: 'draft',
            type: 'normal',
            geo_targeting_mode: 'digitalelement',
            distribution_format: 'evenly',
            currency: 'USD',
            normalize_bundleid: false,
            lock_tracking_url: false,
            sub1_encode_prefix: false,
            sub1_encode_id: false,
            sub1_encode_prefix_freq: 24,
            sub1_encode_id_freq: 24,
            sub1_modulus: 0,
            frequency_cap: 0,
            frequency_cap_timeframe: 24,
            frequency_cap_fingerprint: 'device',
            complex_distribution_method: 'weights',
            translate_params: [],
            route_params: [],
            events: [],
            creatives: [],
            restriction_list: [],
            restriction_hash: {},
            daily_subsource_limit: 0,
            total_subsource_limit: 0,
            // restrict_timeframe_start: '00:00',
            // restrict_timeframe_end: '23:59',
            restrict_timeframe_schedule: [],
            restrict_timeframe_timezone: 0,
            restrict_timeframe: false,
            click_pacing: 0,
            click_pacing_hour: 0,
            impression_pacing: 0,
            impression_pacing_hour: 0,
            frequency_cap_params: freqCapParams,
          };
        },
        async get(id, reload) {
          if (!this._hash[id] || reload) {
            let resp = await Vue.ovReq.get('offer/get/' + id);
            this._hash[id] = resp.entity;
          }
          return this._hash[id];
        },
        async getFallback() {
          return await Vue.ovReq.get('offer/get/fallback');
        },
        async saveFallback(data) {
          return await Vue.ovReq.post('offer/save/fallback', data);
        },
        async updateFallbackStatus(data) {
          return await Vue.ovReq.post('offer/updateStatus/fallback', data);
        },
        async getOptions(filters) {
          if (!filters) {
            filters = {};
          }
          if (!filters.limit) {
            filters.limit = 20;
          }
          if (!filters.offset) {
            filters.offset = 0;
          }
          try {
            let resp = await Vue.ovReq.get('offer/getOptions', { params: filters });
            return resp.options;
          } catch (e) {
            throw e;
          }
        },
        async getBundleOptions(filters) {
          if (!filters) {
            filters = {};
          }
          if (!filters.limit) {
            filters.limit = 20;
          }
          try {
            let resp = await Vue.ovReq.get('offer/getBundleOptions', { params: filters });
            return resp.options;
          } catch (e) {
            throw e;
          }
        },
        async getPackageOptions(filters) {
          if (!filters) {
            filters = {};
          }
          if (!filters.limit) {
            filters.limit = 20;
          }
          try {
            let resp = await Vue.ovReq.get('offer/getPackageOptions', { params: filters });
            return resp.options;
          } catch (e) {
            throw e;
          }
        },
        async getSelectedOptions(str) {
          let selected = [];
          if (str) {
            str += '';
            let itemList = str
              .split(',')
              .map((r) => +r)
              .filter((r) => r);
            if (itemList.length) {
              let resp = await Vue.ovReq.get('offer/getOptionsById', {
                params: { offer_id: itemList.join(',') },
              });
              selected = resp.options;
            }
          }
          return selected;
        },
        async getDefaults(fullInstance = false) {
          let resp = await Vue.ovReq.get('settings/getOfferDefaults');
          return fullInstance ? resp.offer : resp.fields;
        },
        /**
         * @param {object} filters
         * @param {string} filters.startDate
         * @param {string} filters.endDate
         * @param {number} filters.offerId
         * @param {boolean} filters.isCohort
         */
        async getSupplyManagementData(filters) {
          const url = `offer/getSupplyManagementData/${filters.offerId}`;
          delete filters.offerId;
          const response = await Vue.ovReq.get(url, { params: filters });
          return response;
        },
        /**
         * @param {object} data 
         * @param {number} data.offerId 
         * @param {number[]} data.connectedPublishers
         * @param {OfferKpi[]} data.kpi
         * @param {Record<string, object>} data.changes
         * @param {boolean} data.kpiOnly
         * @returns 
         */
        async saveSupplyManagementData(data) {
          const url = `offer/saveSupplyManagementData/${data.offerId}`;
          delete data.offerId;
          const response = await Vue.ovReq.post(url, data);
          return response;
        },
      },

      placement: {
        _booleans: [
          'auto_payout',
          'custom_report',
          'api_enabled',
          'use_publisher_subsource_cap',
          'block_iframe',
          'block_proxies',
          'validate_redirect',
          'report_offer_closed',
          'sub1_partial_match',
          'allow_multiple_conversions',
          'auto_generated',
        ],
        _options: [],
        _hash: {},
        newInstance() {
          return {
            id: 0,
            currency: 'USD',
            override_name: '',
            api_enabled: false,
            auto_payout: true,
            payout_type: 'CPI',
            payout_mode: 'fixed',
            daily_subsource_limit: 0,
            total_subsource_limit: 0,
            click_pacing: 0,
            click_pacing_hour: 0,
            impression_pacing: 0,
            impression_pacing_hour: 0,
            smartlink_pixel: -1,
            risk_calc_mode: 'daily',
            risk_management: 100,
            impression_rate: 0,
            daily_click_cap: 0,
            daily_conversion_cap: 0,
            daily_impression_cap: 0,
            daily_install_cap: 0,
          };
        },
        async get(id, reload) {
          if (!this._hash[id] || reload) {
            let resp = await Vue.ovReq.get('placement/get/' + id);
            this._hash[id] = resp.entity;
          }
          return this._hash[id];
        },
        async getOptions(filters) {
          if (!filters) {
            filters = {};
          }
          if (!filters.limit) {
            filters.limit = 20;
          }
          if (!filters.offset) {
            filters.offset = 0;
          }
          try {
            let resp = await Vue.ovReq.get('placement/getOptions', { params: filters });
            return resp.options;
          } catch (e) {
            throw e;
          }
        },
        async getSelectedOptions(str) {
          let selected = [];
          if (str) {
            str += '';
            let itemList = str
              .split(',')
              .map((r) => +r)
              .filter((r) => r);
            if (itemList.length) {
              let resp = await Vue.ovReq.get('placement/getOptionsById', {
                params: { placement_id: itemList.join(',') },
              });
              selected = resp.options;
            }
          }
          return selected;
        },
        async getDefaults(fullInstance = false) {
          let resp = await Vue.ovReq.get('settings/getPlacementDefaults');
          return fullInstance ? resp.placement : resp.fields;
        },
      },

      mmpAccount: {
        _options: [],
        _hash: {},
        _booleans: ['datalocker_enabled'],
        newInstance() {
          return {
            id: 0,
            account_name: '',
            type: '',
          };
        },
        async get(id, reload) {
          if (!this._hash[id] || reload) {
            let resp = await Vue.ovReq.get('mmpAccount/get/' + id);
            this._hash[id] = resp.entity;
          }
          return this._hash[id];
        },
        /** @param {number} id */
        getSync(id) {
          return this._hash[id];
        },
        async getOptions(reload) {
          if (reload || this._options.length === 0) {
            let resp = await Vue.ovReq.get('mmpAccount/getList', { params: { page_size: 10000 } });
            this._options = resp.records.map((r) => ({
              account_name: r.account_name,
              t: `${r.account_name} [${r.type}]`,
              v: r.id,
            }));
            for (const r of resp.records) {
              this._hash[r.id] = r;
            }
          }
          return this._options;
        },
      },

      country: {
        _options: [],
        _hash: {},
        async getOptions(reload) {
          if (reload || this._options.length === 0) {
            let resp = await Vue.ovReq.get('country/getList', { params: { page_size: 10000 } });
            this._options = resp.records;
          }
          return this._options;
        },
      },

      region: {
        _options: [],
        _hash: {},
        async getOptions(reload, country = null) {
          if (reload || this._options.length === 0) {
            let resp = country
              ? await Vue.ovReq.get('region/getList', { params: { page_size: 10000, country } })
              : await Vue.ovReq.get('region/getList', { params: { page_size: 10000 } });
            this._options = resp.records;
          }
          return this._options;
        },
        async getOptionsByCountry(countries) {
          try {
            let resp = await Vue.ovReq.get('region/optionsByCountries', { params: countries });
            return resp.records;
          } catch (e) {
            throw e;
          }
        },
      },

      city: {
        _options: [],
        _hash: {},
        async getOptions(reload, country = null) {
          if (reload || this._options.length === 0) {
            let resp = country
              ? await Vue.ovReq.get('city/getList', { params: { page_size: 20000, country } })
              : await Vue.ovReq.get('city/getList', { params: { page_size: 20000 } });
            this._options = resp.records;
          }
          return this._options;
        },
        async getOptionsByCountry(countries) {
          try {
            let resp = await Vue.ovReq.get('city/optionsByCountries', { params: countries });
            return resp.records;
          } catch (e) {
            throw e;
          }
        },
      },

      geo: {
        country: {
          _options: [],
          _hash: {},
          async getOptions(reload) {
            if (reload || this._options.length === 0) {
              let resp = await Vue.ovReq.get('geo/country/getList', {
                params: { page_size: 10000 },
              });
              this._options = resp.records;
            }
            return this._options;
          },
        },

        region: {
          _options: [],
          _hash: {},
          async getOptions(reload, country = null) {
            if (reload || this._options.length === 0) {
              let resp = country
                ? await Vue.ovReq.get('geo/region/getList', {
                    params: { page_size: 10000, country },
                  })
                : await Vue.ovReq.get('geo/region/getList', { params: { page_size: 10000 } });
              this._options = resp.records;
            }
            return this._options;
          },
          async getOptionsByCountry(countries) {
            try {
              let resp = await Vue.ovReq.get('geo/region/optionsByCountries', {
                params: countries,
              });
              return resp.records;
            } catch (e) {
              throw e;
            }
          },
        },

        city: {
          _options: [],
          _hash: {},
          async getOptions(reload, country = null) {
            if (reload || this._options.length === 0) {
              let resp = country
                ? await Vue.ovReq.get('geo/city/getList', { params: { page_size: 20000, country } })
                : await Vue.ovReq.get('geo/city/getList', { params: { page_size: 20000 } });
              this._options = resp.records;
            }
            return this._options;
          },
          async getOptionsByCountry(countries) {
            try {
              let resp = await Vue.ovReq.get('geo/city/optionsByCountries', { params: countries });
              return resp.records;
            } catch (e) {
              throw e;
            }
          },
        },

        isp: {
          _options: [],
          _hash: {},
          async getOptions(reload) {
            if (reload || this._options.length === 0) {
              let resp = await Vue.ovReq.get('geo/isp/getOptions');
              this._options = resp.options;
              this._hash = resp.options.reduce((acc, o) => {  acc[o.v] = o.t; return acc; }, {});
            }
            return this._options;
          },
        },

        carrier: {
          _options: [],
          _hash: {},
          async getOptions(reload) {
            if (reload || this._options.length === 0) {
              let resp = await Vue.ovReq.get('geo/carrier/getOptions');
              this._options = resp.options;
              this._hash = resp.options.reduce((acc, o) => {  acc[o.v] = o.t; return acc; }, {});
            }
            return this._options;
          },
        },
      },

      creative: {
        _options: [],
        _hash: {},
        newInstance() {
          return {
            id: 0,
            name: '',
            url: '',
            type: '',
            w: 0,
            h: 0,
            duration: 0,
          };
        },
        async get(id, reload) {
          if (!this._hash[id] || reload) {
            let resp = await Vue.ovReq.get('creative/get/' + id);
            this._hash[id] = resp.entity;
          }
          return this._hash[id];
        },
        getList(filters) {
          if (!filters) {
            filters = {};
          }
          if (!filters.limit) {
            filters.limit = 20;
          }
          if (!filters.offset) {
            filters.offset = 0;
          }
          try {
            return Vue.ovReq.get('creative/getList', { params: filters });
          } catch (e) {
            throw e;
          }
        },
      },

      legalEntity: {
        _ext_options: [],
        _options: [],
        _hash: {},
        newInstance() {
          return {
            id: 0,
            name: '',
            common_name: '',
            vat_number: '',
            contact_email: '',
            address: '',
            country: '',
            comments: '',
            files: [],
          };
        },
        async get(id, reload) {
          if (!this._hash[id] || reload) {
            let resp = await Vue.ovReq.get('finance/legalEntity/get/' + id);
            this._hash[id] = resp.entity;
          }
          return this._hash[id];
        },
        async getOptions(reload) {
          if (reload || this._options.length === 0) {
            let resp = await Vue.ovReq.get('finance/legalEntity/getOptions');
            this._options = resp.options;
          }
          return this._options;
        },
        async getExternalOptions(reload) {
          if (reload || this._options.length === 0) {
            let resp = await Vue.ovReq.get('finance/legalEntity/getExternalClientList');
            this._ext_options = resp.options;
          }
          return this._ext_options;
        },
      },

      financeAdvertiser: {
        newInstance() {
          return {
            comments: null,
            date_paid: null,
            date_sent: null,
            files: [],
            has_vat: false,
            ils_usd_rate: null,
            invoice_files: [],
            legal_entity_id: null,
            paid_ils: null,
            paid_usd: 0,
            payment_method: null,
            receipt_files: [],
            status: 0,
            sum_ils: 0,
            sum_ils_vat: 0,
            sum_usd: 0,
            sum_usd_vat: 0,
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
          };
        },
      },

      financePublisher: {
        newInstance() {
          return {
            comments: null,
            date_paid: null,
            date_sent: null,
            files: [],
            has_vat: false,
            ils_usd_rate: null,
            invoice_files: [],
            legal_entity_id: null,
            paid_ils: null,
            paid_usd: 0,
            confirmed_usd: 0,
            payment_method: null,
            receipt_files: [],
            status: 0,
            sum_ils: 0,
            sum_ils_vat: 0,
            sum_usd: 0,
            sum_usd_vat: 0,
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
          };
        },
      },

      label: {
        _options: [],
        _hash: {},
        newInstance() {
          return {
            id: 0,
            name: '',
          };
        },
        async get(id, reload) {
          if (!this._hash[id] || reload) {
            let resp = await Vue.ovReq.get('label/get/' + id);
            this._hash[id] = resp.entity;
          }
          return this._hash[id];
        },
        async getOptions(reload) {
          if (!this._options.length || reload) {
            let resp = await Vue.ovReq.get('label/getOptions');
            this._options = resp.options;
          }
          return this._options;
        },
      },

      report_preset: {
        _booleans: ['is_default'],
        _hash: {},
        newInstance() {
          return {
            id: 0,
            name: '',
            metrics: '',
            dimensions: '',
            filters: '',
            sort_by: null,
            date_range: '',
            is_default: false,
            user_id: 0,
          };
        },
        async get(id, reload) {
          if (!this._hash[id] || reload) {
            let resp = await Vue.ovReq.get('reportPreset/get/' + id);
            this._hash[id] = resp.entity;
          }
          return this._hash[id];
        },
        async getUserPresets() {
          let resp = await Vue.ovReq.get('reportPreset/getList', { params: { page_size: 100 } });
          return resp.records;
        },
        getAllFilters() {
          return [
            // { label: 'Dates', field: 'datePicker', defaultValue: {
            // 		timezone: 0,
            // 		dateRange: {
            // 			startDate: moment(moment().subtract(-(new Date().getTimezoneOffset() / 60), 'hours').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm'),
            // 			endDate: moment(moment().subtract(-(new Date().getTimezoneOffset() / 60), 'hours').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm'),
            // 		}
            // 	} },
            { label: 'Group Timeframe', field: 'group_timeframe', defaultValue: 'none' },
            { label: 'Status', field: 'status', defaultValue: 'all' },
            { label: 'Publishers', field: 'publisher_id', defaultValue: [] },
            { label: 'Placements', field: 'placement_id', defaultValue: [] },
            { label: 'Advertisers', field: 'advertiser_id', defaultValue: [] },
            { label: 'Offers', field: 'offer_id', defaultValue: [] },
            { label: 'Complex Offers', field: 'complex_offer_id', defaultValue: [] },
            { label: 'Subsource 1', field: 'sub1_id', defaultValue: [] },
            { label: 'Subsource 2', field: 'sub2_id', defaultValue: [] },
            { label: 'MMP Account', field: 'mmp_account_id', defaultValue: [] },
            { label: 'OS', field: 'os_id', defaultValue: [] },
            { label: 'Device', field: 'device_id', defaultValue: [] },
            { label: 'Browser', field: 'browser_id', defaultValue: [] },
            { label: 'Carrier', field: 'carrier_id', defaultValue: [] },
            { label: 'Country', field: 'country', defaultValue: '' },
            { label: 'Account Managers', field: 'am_id', defaultValue: [] },
            { label: 'Has Conversions', field: 'has_conversions', defaultValue: false },
            { label: 'Cohort', field: 'cohort', defaultValue: false },

            { label: 'User', field: 'user_id', defaultValue: 0, hidden: true },
            {
              label: 'Extended Params',
              field: 'extended_params',
              defaultValue: false,
              hidden: true,
            },
            { label: 'Smartlink Mode', field: 'groups_mode', defaultValue: 0, hidden: true },
            { label: 'Subsource Data', field: 'subsource_data', defaultValue: false, hidden: true },
          ];
        },
      },

      support: {
        Topics: {
          0: 'Other',
          1: "Discrepency with Advertisers / MMP's",
          2: 'Discrepency with Publishers',
          3: 'Offer / Placement - auto update issues',
          4: 'Event configurations (events are not showing in e1, e2, e3 etc)',
          5: 'KPI rules - Auto block not working',
        },

        GeneralStatus: {
          New: 'new',
          PendingClient: 'pending_client',
          Open: 'open',
          Closed: 'closed',
          Cancelled: 'cancelled',
        },

        ProgressStatus: {
          ReadyForDeployment: 'ready_for_deployment',
          Deployed: 'deployed',
          InProgress: 'in_progress',
          New: 'new',
          Qa: 'qa',
          Discussion: 'discussion',
        },

        /**
         * @param {object} o
         * @param {number} o.page
         * @param {number} o.limit
         * @param {object} o.filters
         * @param {string} o.filters.generalStatus
         * @param {string} o.filters.keyword
         * @param {string} o.sort
         */
        getList({ page, limit, filters, sort }) {
          return Vue.ovReq.get('support/getList', { params: { page, limit, ...filters, sort } });
        },
        /**
         * @param {string} id
         */
        get(id) {
          return Vue.ovReq.get('support/get/' + id);
        },
        /**
         * @param {object} data
         * @param {number | null} data.advertiserId
         * @param {string | null} data.text
         * @param {number | null} data.offerId
         * @param {number | null} data.placementId
         * @param {number | null} data.publisherId
         * @param {'bug' | 'feature' | 'call'} data.type
         * @param {'0' | '1' | '2' | '3' | '4' | '5'} data.topic
         * @param {'low' | 'medium' | 'high'} data.priority
         * @param {string} data.title
         */
        create(data) {
          return Vue.ovReq.post('support/create', data);
        },
        /**
         * @param {object} data
         * @param {number} data.ticketId
         * @param {string} data.text
         * @param {'open' | 'closed'} data.status
         */
        reply(data) {
          const { ticketId } = data;
          delete data.ticketId;
          return Vue.ovReq.post(`support/reply/${ticketId}`, data);
        },

        /**
         * Mark ticket as seen.
         * @param {string} id Ticket ID
         */
        seen(id) {
          return Vue.ovReq.post(`support/seen/${id}`);
        },
      },

      kpiRules: {
        async getAll() {
          const response = await Vue.ovReq.get('rule/getAll');
          return response.records;
        },
      },
    };

    Vue.ovData = api;
    Vue.prototype.$ovData = api;
  },
};
