<template lang="pug">
    div.page-reports
        loading(:active.sync="busy", :is-full-page="true")
        .widget(v-if="filters && !date_filters_only")
            .widget-header
                h1.title Reports
                .submit-buttons(v-if="!editCustomPresets")
                    b-button-group
                        b-dropdown(@click="loadData()", variant="primary", right, split, text="Go")
                            b-dropdown-item(@click="loadData(1)")
                                i.la.la-download.mr-2
                                | Export
                            b-dropdown-item(@click="loadData(3)")
                                i.la.la-download.mr-2
                                | Export Page
                            b-dropdown-item(@click="loadData(2)")
                                i.la.la-download.mr-2
                                | Background Report
                            b-dropdown-item(@click="showBackgroundReportsModal(true)")
                                i.la.la-external-link.mr-2
                                | View Background Reports
                            b-dropdown-item(@click="createCustomPreset()")
                                i.la.la-magic.mr-2
                                | Save to presets
            .widget-body

                form.form.ov-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
                    .row
                        .col-md-12.mb-4(v-if="presetEditInstance")
                            .d-flex.justify-content-between
                                .preset.form-inline
                                    .form-group
                                        label Preset Name
                                        input.form-control(type="text", v-model="presetEditInstance.name")
                                    .form-group
                                        label Date Range
                                        select.form-control(v-model="presetEditInstance.date_range", style="width: 120px;")
                                            option(value="") None
                                            option(v-for="o in presetDateRangeOptions", :value="o") {{ o }}

                                .submit-buttons.preset-edit
                                    button.btn.btn-default(type="button", @click="presetEditInstance = null", v-b-tooltip.bottom.hover, title="Discard changes") Cancel
                                    button.btn.btn-primary(type="button", @click="updateCustomPreset()", v-b-tooltip.bottom.hover, title="Save changes") Save
                    .row
                        .col-md-12
                            .form-blocks(:class="{'list-height-auto':showAllDims}")
                                .block.block-dimensions(:class="{'edit-preset':presetEditInstance}")
                                    .block-header
                                        h3 Dimensions
                                        .block-actions
                                            a.mr-2(href="javascript:void(0);", @click="showAllDims = !showAllDims")
                                                i.la(:class="{'la-arrows-alt-v': !showAllDims, 'la-minus-circle': showAllDims}")
                                    .block-body
                                        .selection-list
                                            div.list-item(v-for="d in dimensions", :class="{selected:d._selected}")
                                                //b-checkbox(v-model="d._selected") {{ d.label }}
                                                a.btn-item-left(v-if="d.children", href="javascript:void(0);", @click="d._expanded = !d._expanded")
                                                    i.la(:class="{'la-caret-right': !d._expanded, 'la-caret-down': d._expanded}")
                                                a.btn-item-right(v-if="d.children", href="javascript:void(0)", @click="toggleAllChildren(d)")
                                                    i.la.la-check-square
                                                b-checkbox(:checked="d._selected", :class="{symbolic:d.symbolic}", :disabled="d.symbolic", @change="(value) => changeDimensionSelection(d.field, value)") {{ d.label }}
                                                    =' '
                                                    span(v-if="d.children") ({{ d.children | countSelected }})
                                                    new-feature(v-if="d.new_tag")
                                                div(v-if="d.children", v-show="d._expanded")
                                                    div.child(v-for="cd in d.children", :class="{selected:cd._selected}")
                                                        b-checkbox(v-model="cd._selected") {{ cd.label }}
                                                            new-feature(v-if="d.new_tag")

                                        .selected-items
                                            div(v-for="d in selectedDimensions")
                                                a(href="javascript:void(0);", @click="removeDimension(d)")
                                                    i.la.la-times
                                                span {{ d.label }}

                                                div(v-for="cd in d.children", v-if="cd._selected", class="ml-3")
                                                    a(href="javascript:void(0);", @click="removeDimension(cd)")
                                                        i.la.la-times
                                                    span {{ cd.label }}
                                                

                                .block.block-metrics(:class="{'edit-preset':presetEditInstance}")
                                    .block-header
                                        h3 Metrics
                                    .block-body
                                        .selection-list.selection-list-wide
                                            div.list-item(v-for="d in metrics", :class="{selected:d._selected}")
                                                a.btn-item-left(v-if="d.children", href="javascript:void(0);", @click="d._expanded = !d._expanded")
                                                    i.la(:class="{'la-caret-right': !d._expanded, 'la-caret-down': d._expanded}")
                                                a.btn-item-right(v-if="d.children", href="javascript:void(0)", @click="toggleAllChildren(d)")
                                                    i.la.la-check-square
                                                b-checkbox(v-model="d._selected", :class="{symbolic:d.symbolic}", :disabled="d.symbolic") {{ d.label }}
                                                    =' '
                                                    span(v-if="d.children") ({{ d.children | countSelected }})
                                                div(v-if="d.children", v-show="d._expanded")
                                                    div.child(v-for="cd in d.children", :class="{selected:cd._selected}")
                                                        b-checkbox(v-model="cd._selected") {{ cd.label }}
                                .block.block-presets
                                    .block-header
                                        h3 Presets
                                        .block-actions
                                            // a.mr-2(v-if="!editCustomPresets", href="javascript:void(0);", @click="createCustomPreset()") Add
                                            a.mr-2(v-if="!presetEditInstance", href="javascript:void(0);", @click="editCustomPresets = !editCustomPresets") {{ !editCustomPresets ? 'Edit' : 'Cancel' }}
                                    .block-body
                                        .preset-list(v-if="!editCustomPresets")
                                            button.rounded-pill.preset-btn(v-for="p in customPresets", :class="{'btn-default': p.is_default}", type="button", @click="selectCustomPreset(p)")
                                                span {{ p.name }} &nbsp;
                                                i.la.la-star(v-if="p.is_default")

                                        draggable.selection-list.edit-mode(v-if="editCustomPresets", tag="tbody", v-model="customPresets", handle=".drag-handle", ghost-class="ghost", :group="{name: 'customPresets', pull: 'clone', put: false}", @end="onPresetDrag")
                                            .list-item(v-for="p in customPresets", :class="{'default':p.is_default, 'highlight': presetEditInstance && presetEditInstance.id === p.id}")
                                                a.btn-item-left.drag-handle(type="button", v-b-tooltip.bottom.hover, title="Drag to change order", v-if="!presetEditInstance")
                                                    i.la.la-grip-lines
                                                .btn-item-right(v-if="!presetEditInstance")
                                                    a.icon-btn.mr-1(type="button", @click="editCustomPreset(p)", v-b-tooltip.bottom.hover, title="Edit")
                                                        i.la.la-pencil
                                                    a.icon-btn.mr-1(type="button", @click="togglePresetDefault(p)", v-b-tooltip.bottom.hover, title="Set As Default", v-if="!p.is_default")
                                                        i.la.la-star
                                                    a.icon-btn.mr-1.btn-danger(type="button", @click="deleteCustomPreset(p)", v-b-tooltip.bottom.hover, title="Delete")
                                                        i.la.la-trash
                                                .item-text.pr-5 {{ p.name }}
                        .col-md-12
                            .form-blocks(v-if="filters")
                                .block.block-filters(:class="{'edit-preset':presetEditInstance}")
                                    .block-header
                                        h3 Filters
                                        .block-actions
                                            a.mr-2.mobile-visible(href="javascript:void(0);", @click="toggleFilters()", v-b-tooltip.bottom.hover, :title="filtersExpanded ? 'Show Less' : 'Expand Filters'")
                                                i.la(:class="{'la-plus-circle': !filtersExpanded, 'la-minus-circle': filtersExpanded}")
                                            a.mr-2(href="javascript:void(0);", @click="clearFilters()") Clear
                                    .block-body
                                        .mr-3(v-if="presetEditInstance")
                                            label Preset Filters
                                                i.la.la-info-circle.ml-1(v-b-tooltip.hover, title="Selected filters will reset to the desired option when the preset will be used")
                                            .selection-list(style="width: 200px; height: 240px")
                                                div(v-for="f in filtersOptions.filter(f => !f.hidden && f.field !== 'datePicker')", :class="{selected: !presetEditInstance.activeFilters[f.field]}")
                                                    b-checkbox(v-model="presetEditInstance.activeFilters[f.field]") {{ f.label }}
                                        .form-inline
                                            ov-date-time-picker.date-range-picker(v-model="filters.datePicker", ref="datePickerRef", :minimized="!filtersExpanded", :limitThreeMonths="shouldLimitDateRange")
                                            .form-group
                                                label Group Timeframe
                                                select.form-control(v-model="filters.group_timeframe", style="width: 120px;", @change="onTimegroupChange")
                                                    option(value="none") None
                                                    option(value="hour") Hour
                                                    //- option(value="hourByDays") Hour and Day
                                                    option(value="day") Day
                                                    option(value="month") Month
                                            .form-group
                                                label Status
                                                select.form-control(v-model="filters.status")
                                                    option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
                                            .form-group
                                                label Media Type
                                                select.form-control(v-model="filters.media_type")
                                                    option(v-for="o in mediaTypeOptions", :value="o.v") {{ o.t }}


                                            select-publisher(:class="{'mobile-hidden': !filtersExpanded && !filters.publisher_id.length}", v-model="filters.publisher_id")
                                            select-placement(:class="{'mobile-hidden': !filtersExpanded && !filters.placement_id.length}", v-model="filters.placement_id")
                                            select-advertiser(:class="{'mobile-hidden': !filtersExpanded && !filters.advertiser_id.length}", v-model="filters.advertiser_id")
                                            select-offer(:class="{'mobile-hidden': !filtersExpanded && !filters.offer_id.length}", v-model="filters.offer_id")
                                            select-offer(:class="{'mobile-hidden': !filtersExpanded && !filters.complex_offer_id.length}", v-model="filters.complex_offer_id", :complex="true")

                                            .form-group(:class="{'mobile-hidden': !filtersExpanded && !filters.os_id.length}")
                                                label OS
                                                multiselect(:multiple="true", :options="osOptions", v-model="filters.os_id",
                                                    track-by="v", label="t", deselect-label="", select-label="")
                                            .form-group(:class="{'mobile-hidden': !filtersExpanded && !filters.device_id.length}")
                                                label Device
                                                multiselect(:multiple="true", :options="deviceOptions", v-model="filters.device_id",
                                                    track-by="v", label="t", deselect-label="", select-label="")
                                            .form-group(:class="{'mobile-hidden': !filtersExpanded && !filters.browser_id.length}")
                                                label Browser
                                                multiselect(:multiple="true", :options="browserOptions", v-model="filters.browser_id",
                                                    track-by="v", label="t", deselect-label="", select-label="")
                                            .form-group(:class="{'mobile-hidden': !filtersExpanded && !filters.carrier_id.length}")
                                                label Mobile Carrier
                                                multiselect(:multiple="true", :options="carrierOptions", v-model="filters.carrier_id",
                                                    track-by="v", label="t", deselect-label="", select-label="")
                                            .form-group(:class="{'mobile-hidden': !filtersExpanded && !filters.country}")
                                                label Country
                                                input.form-control(type="text", v-model="filters.country", style="width: 136px")
                                            .form-group(:class="{'mobile-hidden': !filtersExpanded && !filters.groups_mode}")
                                                label Smartlink Traffic
                                                select.form-control(v-model="filters.groups_mode")
                                                    option(:value="0") None
                                                    option(:value="1") Smartlink-Only
                                                    option(:value="2") All
                                            .form-group(:class="{'mobile-hidden': !filtersExpanded && !filters.am_id.length}")
                                                label Account Manager
                                                multiselect(:multiple="true", :options="accountManagerOptions", v-model="filters.am_id",
                                                    track-by="v", label="t", deselect-label="", select-label="")
                                            .form-group(:class="{'mobile-hidden': !filtersExpanded && !filters.mmp_account_id.length}")
                                                label MMP Account
                                                multiselect(:multiple="true", :options="mmpAccountOptions", v-model="filters.mmp_account_id",
                                                    track-by="v", label="t", deselect-label="", select-label="")
                                                    
                                            select-subsource(v-model="filters.sub1_id", type="sub1")
                                            select-subsource(v-model="filters.sub2_id", type="sub2")
                                                    
                                            .form-group.no-label(:class="{'mobile-hidden': !filtersExpanded && !filters.has_conversions}")
                                                label.form-check-label
                                                b-checkbox(type="checkbox", v-model="filters.has_conversions") Has Conversions / Events
                                            .form-group.no-label(v-if="selectedDimensionHash.subsource", :class="{'mobile-hidden': !filtersExpanded && !filters.subsource_data}")
                                                label.form-check-label
                                                b-checkbox(type="checkbox", v-model="filters.subsource_data") Expand Subsources
                                            .form-group.no-label(:class="{'mobile-hidden': !filtersExpanded && !filters.cohort}")
                                                b-checkbox(type="checkbox", v-model="filters.cohort") Cohort


        .widget
            .widget-body
                form.form.ov-filters.minimal-filters.flex-container(@submit.prevent="loadData()", v-if="date_filters_only")
                    .filters-row
                        ov-date-time-picker.date-range-picker(v-model="filters.datePicker", ref="datePickerRef")
                        //a.btn-underline.ml-2(href="javascript:void(0);", @click="date_filters_only=false") Show all filters &nbsp;
                            i.la.la-angle-up
                    .flex-right
                        b-button-group.mt-4
                            b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                                b-dropdown-item(@click="loadData(1)")
                                    i.la.la-download.mr-2
                                    | Export

                p.clearfix.mobile-hidden &nbsp;
                paginate(:paginator="paginate", @update-page-size="loadData", :refresh="true")


                .stats-table-wrapper
                    table.table.table-bordered.tbl-statistics(v-if="resultMeta")
                        thead
                            tr
                                ov-th.col-timeframe(v-if="filters.group_timeframe === 'day' || filters.group_timeframe === 'hour' || filters.group_timeframe === 'month' || filters.group_timeframe==='hourByDays'", @sort="doSort()", :sorter="sortBy", :field="filters.group_timeframe") {{timeframeLabel[filters.group_timeframe] }}
                                template(v-for="d in resultMeta.dimensions")
                                    ov-th.col-dim(:class="'col-'+d.name", @sort="doSort()", :sorter="sortBy", :field="d.name") {{ d.label }}
                                    th.col-info(v-if="d.children", v-for="c in d.children") {{ c.label }}
                                ov-th.col-metric(v-for="m in resultMeta.metrics", :class="'col-'+m.name", @sort="doSort()", :sorter="sortBy", :field="m.name") {{ m.short_label || m.label }}

                        tbody.summary
                            tr(v-if="summary")
                                td.c(v-if="filters.group_timeframe === 'day' || filters.group_timeframe === 'hour' || filters.group_timeframe === 'month' || filters.group_timeframe==='hourByDays'") &nbsp;
                                template(v-for="d in resultMeta.dimensions")
                                    td &nbsp;
                                    td(v-if="d.children", v-for="c in d.children") &nbsp;
                                td.c(v-for="d in resultMeta.metrics", :class="'col-'+d.name")
                                    span {{ summary[d.name].toLocaleString() }}

                        tbody(ref="table")
                            tr(v-if="records.length===0")
                                td(:colspan="numColumns") No matching records were found
                            tr(v-for="r in records", v-bind:key="r.key", :class="getRowClass(r)")
                                td.nowrap(v-if="filters.group_timeframe === 'month'") {{ r.group_month }}
                                td.nowrap(v-if="filters.group_timeframe === 'day'") {{ r.group_date }}
                                td.nowrap(v-if="filters.group_timeframe === 'hour'") {{ r.group_hour }}
                                td.nowrap(v-if="filters.group_timeframe === 'hourByDays'") {{ r.group_hour }}
                                template(v-for="d in resultMeta.dimensions")
                                    td.col-entity(v-bind:key="r.key+'_'+(d.name)", :class="getColClass(r, d)")
                                        entity.no-bg(v-if="r[d.name] && r[d.name].id", :id="r[d.name].id", :name.sync="r[d.name].name", :type="d.name",
                                            :mode="d.mode", :data="r[d.name]",
                                            :max-width="d.width", @click="appendEntity(r,d)", @dblclick="selectEntity(r,d)")
                                        span(v-if="!r[d.name] || !r[d.name].id") {{ r[d.field] }}
                                    td.col-info(v-if="d.children", v-for="c in d.children")
                                        offer-paused-reason(v-if="c.name==='paused_reason'", :offer="r.offer", :placement="d.name === 'placement' ? r.placement : null")
                                        span(v-else-if="r[d.name] && r[d.name][c.name]") {{ r[d.name][c.name] }}
                                            //span(v-else="r[d.name] && r[d.name][c.name]") {{ r[d.name][c.name] }}

                                td.c(v-for="d in resultMeta.metrics", :class="'col-'+d.name + ' flag-'+(r[d.name] ? r[d.name].flag : '')",
                                    v-b-tooltip.hover, :title="r[d.name] && r[d.name].l ? r[d.name].l : ''")
                                    span(v-if="r[d.name]", :class="'flag-'+r[d.name].flag")
                                        router-link(v-if="metricsHash[d.name].link", :to="{name: metricsHash[d.name].link, query: getURLFiltersParams(r, metricsHash[d.name].linkParams)}") {{ r[d.name].v.toLocaleString() }}
                                        span(v-if="!metricsHash[d.name].link") {{ r[d.name].v.toLocaleString() }}

                        tfoot.summary
                            tr(v-if="summary")
                                th.c(v-if="filters.group_timeframe === 'day' || filters.group_timeframe === 'hour' || filters.group_timeframe === 'month' || filters.group_timeframe==='hourByDays'") &nbsp;
                                template(v-for="d in resultMeta.dimensions")
                                    td &nbsp;
                                    td(v-if="d.children", v-for="c in d.children") &nbsp;
                                th.c(v-for="d in resultMeta.metrics", :class="'col-'+d.name")
                                    span {{ summary[d.name].toLocaleString() }}


                paginate(:paginator="paginate", @update-page-size="loadData", :refresh="true")
</template>
<script>
import Vue from 'vue';
import GeneratedReportsModal from '@/views/reports/GeneratedReportsModal';
import CreatePresetModal from '../views/modals/CreatePresetModal';
import moment from 'moment';
import draggable from 'vuedraggable';
import { downloadFile } from '../lib/files';

const DEFAULT_DIMENSIONS = ['advertiser', 'offer'];
const DEFAULT_METRICS = [
  'impressions',
  'blocked_impressions',
  'passed_impressions',
  'clicks',
  'blocked_clicks',
  'passed_clicks',
  'blocked_percent',
  'conversions',
  'reported_conversions',
  'cr',
  'cr_reported',
  'installs',
  'imp_per_install',
  'events',
  'reject_events',
  'revenue',
  'cost',
  'profit',
  'profit_percent',
  ...Array.from({ length: 10 }, (v, i) => 'e' + (i + 1)),
];

function getDefaultSortBy() {
  return {
    field: 'revenue',
    direction: 'desc',
    sort: null,
  };
}

export default {
  name: 'ReportsWidget',
  components: {
    GeneratedReportsModal,
    CreatePresetModal,
    draggable,
  },
  props: {
    date_filters_only: {
      type: Boolean,
      default() {
        return false;
      },
    },
    complex_offer_id: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    offer_id: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    placement_id: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    config: {
      type: Object,
    },
  },
  computed: {
    USER() {
      return this.$store.state.user;
    },
    selectedDimensionHash() {
      let h = {};
      this.dimensions.forEach((d) => {
        if (d._selected) {
          h[d.name] = d;
        }
      });
      return h;
    },
    selectedDimensions() {
      return this.dimensions.filter((d) => d._selected);
    },
    selectedMetrics() {
      return this.metrics.filter(
        (d) => d._selected || (d.children && d.children.filter((c) => c._selected).length),
      );
    },
    visibleAdvertiserOptions() {
      return this.advertiserOptions.filter((a) => {
        return this.advertiserOptionsArchive || a.status;
      });
    },
    visiblePublisherOptions() {
      return this.publisherOptions.filter((a) => {
        return this.publisherOptionsArchive || a.status;
      });
    },
    numColumns() {
      let metrics = this.getSelectedMetrics();
      let dimensions = this.getSelectedDimensions();

      return metrics.length + dimensions.length + (this.filters.group_timeframe !== 'none' ? 1 : 0);
    },
    shouldLimitDateRange() {
      const hasBigDimension = this.selectedDimensions.some((d) => d.level === 3 || d.level === 4);
      return hasBigDimension;
    },
  },
  data() {
    return {
      dimOfferIndex: -1,
      dimPlacementIndex: -1,
      busy: false,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadData();
        },
      },
      filtersExpanded: false,
      filtersOptions: [],
      showAllDims: false,
      sortBy: getDefaultSortBy(),
      dimensions: [],
      metrics: [],
      dimHash: {},
      metricsHash: {},
      ctitSteps: [],
      rejectReasons: [],
      blockedErrors: [],
      columns: [],
      pages: [],
      records: [],
      summary: null,
      resultMeta: null,
      advertiserOptionsArchive: false,
      publisherOptionsArchive: false,
      advertiserOptions: [],
      placementOptionsLoading: false,
      placementOptions: [],
      accountManagerOptions: [],
      mmpAccountOptions: [],
      publisherOptions: [],
      osOptions: [],
      deviceOptions: [],
      browserOptions: [],
      carrierOptions: [],
      statusOptions: [
        { v: 'all', t: 'All' },
        { v: 'live', t: 'Live' },
        { v: 'paused', t: 'Paused' },
        { v: 'click_cap', t: 'Click Cap' },
        { v: 'conversion_cap', t: 'Conversion Cap' },
      ],
      mediaTypeOptions: [
        { v: null, t: 'All' },
        { v: 'dsp', t: 'DSP' },
        { v: 'rewarded', t: 'Rewarded' },
      ],
      filters: {
        cohort: false,
        groups_mode: 0,
        has_conversions: false,
        subsource_data: false,
        group_timeframe: 'none',
        extended_params: false,
        mmp_account_id: [],
        complex_offer_id: [],
        offer_id: [],
        placement_id: [],
        publisher_id: [],
        advertiser_id: [],
        am_id: [],
        os_id: [],
        device_id: [],
        browser_id: [],
        carrier_id: [],
        sub1_id: [],
        sub2_id: [],
        country: '',
        status: 'all',
        media_type: null,

        user_id: 0,
        datePicker: {
          timezone: 0,
          dateRange: {
            startDate: null,
            endDate: null,
          },
        },
      },
      timeframeLabel: {
        month: 'Month',
        day: 'Date',
        hour: 'Hour',
        hourByDays: 'Hour',
      },
      customPresets: [],
      editCustomPresets: false,
      expandCustomPresets: false,
      presetEditInstance: false,
      selectedPreset: false,
    };
  },
  methods: {
    onTimegroupChange() {
      if (this.filters.group_timeframe !== 'none') {
        this.sortBy.field = this.filters.group_timeframe;
      } else {
        this.sortBy.field = 'revenue';
      }
    },

    doSort() {
      console.log('sort');
      this.loadData();
    },
    getColClass(r, dim) {
      if (dim.name === 'offer') {
        return 'col-offer-status-' + r.offer.final_status;
      }
      if (dim.name === 'placement') {
        return 'col-offer-status-' + r.placement.final_status;
      }
      // if (dim.name === 'sub1'){
      // 	return 'col-sub1-status-' + r.sub1.status;
      // }
      return '';
    },
    getRowClass(r) {
      if (this.dimHash.offer._selected) {
        return 'row-placement-status-' + (r.offer ? r.offer.final_status : '');
      }
      if (this.dimHash.placement._selected) {
        return 'row-placement-status-' + (r.placement ? r.placement.final_status : '');
      }
      return '';
    },
    toggleViewMode(c, i) {
      if (c.mode === 'mini') {
        c.mode = 'normal';
      } else {
        c.mode = 'mini';
      }
      this.$set(this.columns, i, c);
    },

    toggleAllChildren(o) {
      o._toggled = !o._toggled;
      o.children.forEach((c) => {
        c._selected = o._toggled;
      });
    },

    clearFilters() {
      Vue.set(this.filters, 'datePicker', {
        timezone: 0,
        dateRange: {
          startDate: moment(
            moment()
              .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
              .format('DD/MM/YYYY') + ' 00:00',
            'DD/MM/YYYY HH:mm',
          ),
          endDate: moment(
            moment()
              .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
              .format('DD/MM/YYYY') + ' 23:59',
            'DD/MM/YYYY HH:mm',
          ),
        },
      });
      this.filtersOptions.forEach((f) => {
        Vue.set(this.filters, f.field, f.defaultValue);
      });
    },

    selectEntity(record, colIndex) {
      // console.log('select entity', record, colIndex);
      let col = this.columns[colIndex];
      switch (col.e) {
        case 'placement':
          //this.filters.placement_ids = '' + record[col.f];
          break;
        case 'offer':
          //this.filters.offer_ids = '' + record[col.f];
          break;
      }
    },

    appendEntity(record, dim) {
      switch (dim.name) {
        case 'placement':
          for (let k in this.filters.placement_id) {
            if (this.filters.placement_id[k].id == record[dim.name].id) {
              return false;
            }
          }
          this.filters.placement_id.push({
            id: record[dim.name].id,
            name: `[${record[dim.name].id}] ${record[dim.name].name}`,
          });
          break;
        case 'offer':
          for (let k in this.filters.offer_id) {
            if (this.filters.offer_id[k].id == record[dim.name].id) {
              return false;
            }
          }
          this.filters.offer_id.push({
            id: record[dim.name].id,
            name: `[${record[dim.name].id}] ${record[dim.name].name}`,
          });
          break;
        case 'complex_offer':
          for (let k in this.filters.complex_offer_id) {
            if (this.filters.complex_offer_id[k].id == record[dim.name].id) {
              return false;
            }
          }
          this.filters.complex_offer_id.push({
            id: record[dim.name].id,
            name: `[${record[dim.name].id}] ${record[dim.name].name}`,
          });
          break;
        case 'advertiser':
          for (let k in this.filters.advertiser_id) {
            if (this.filters.advertiser_id[k].v == record[dim.name].id) {
              return false;
            }
          }
          this.filters.advertiser_id.push({
            v: record[dim.name].id,
            t: `[${record[dim.name].id}] ${record[dim.name].name}`,
          });
          break;
        case 'publisher':
          for (let k in this.filters.publisher_id) {
            if (this.filters.publisher_id[k].v == record[dim.name].id) {
              return false;
            }
          }
          this.filters.publisher_id.push({
            v: record[dim.name].id,
            t: `[${record[dim.name].id}] ${record[dim.name].name}`,
          });
          break;
        case 'mmp_account':
          for (let k in this.filters.mmp_account_id) {
            if (this.filters.mmp_account_id[k].v == record[dim.name].id) {
              return false;
            }
          }
          this.filters.mmp_account_id.push({
            v: record[dim.name].id,
            t: `[${record[dim.name].id}] ${record[dim.name].name}`,
          });
          break;
        case 'sub1':
          for (let k in this.filters.sub1_id) {
            if (this.filters.sub1_id[k].id == record[dim.name].id) {
              return false;
            }
          }
          this.filters.sub1_id.push({
            id: record[dim.name].id,
            name: record[dim.name].name,
          });
          break;
        case 'sub2':
          for (let k in this.filters.sub2_id) {
            if (this.filters.sub2_id[k].id == record[dim.name].id) {
              return false;
            }
          }
          this.filters.sub2_id.push({
            id: record[dim.name].id,
            name: record[dim.name].name,
          });
          break;
      }
      this.$forceUpdate();
    },

    getURLFiltersParams(record, moreParams) {
      let params = {
        page: this.paginate.page,
        page_size: this.paginate.limit,
        keyword: this.filters.keyword,
        country: this.filters.country,

        timezone: this.filters.datePicker.timezone,
        advertiser_id: this.filters.advertiser_id.map((r) => r.v).join(','),
        publisher_id: this.filters.publisher_id.map((r) => r.v).join(','),

        offer_id: this.filters.offer_id.map((r) => r.id).join(','),
        complex_offer_id: this.filters.complex_offer_id.map((r) => r.id).join(','),
        placement_id: this.filters.placement_id.map((r) => r.id).join(','),
        mmp_account_id: this.filters.mmp_account_id.map((r) => r.v).join(','),

        date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
      };

      if (typeof moreParams !== 'undefined' && moreParams) {
        Object.assign(params, moreParams);
      }

      this.selectedDimensions.forEach((d) => {
        params[d.field] = record[d.field];
      });
      return params;
    },
    showBackgroundReportsModal() {
      this.$modal.show(
        GeneratedReportsModal,
        {},
        {
          height: '90%',
        },
      );
    },

    removeMetric: function (d) {
      d._selected = false;
    },
    removeDimension: function (d) {
      d._selected = false;
      if (d.name === this.sortBy.field) {
        /**
         * If the selected dimension is the sort by field, reset the sort by to default
         */
        this.sortBy = getDefaultSortBy();
      }
    },
    changeDimensionSelection(field, value) {
      const d = this.dimensions.find((d) => d.field === field);
      if (!value) {
        this.removeDimension(d);
        return
      }
      d._selected = true;
    },

    toggleFilters: function () {
      this.filtersExpanded = !this.filtersExpanded;
    },
    getSelectedMetrics() {
      let metrics = [];
      this.metrics.forEach((m) => {
        if (m._selected) {
          metrics.push(m.name);
        }
        if (m.children) {
          m.children.forEach((cm) => {
            if (cm._selected) {
              metrics.push(cm.name);
            }
          });
        }
      });
      return metrics;
    },
    getSelectedDimensions() {
      return this.dimensions.filter((d) => d._selected).map((d) => d.name);
    },
    getSelectedInfo() {
      let infoRecords = [];
      this.dimensions.forEach((d) => {
        if (d.children) {
          d.children.forEach((cd) => {
            if (cd._selected) {
              infoRecords.push(`${d.name}__${cd.name}`);
            }
          });
        }
      });
      return infoRecords;
    },
    async loadData(doExport) {
      this.busy = true;

      let metrics = this.getSelectedMetrics();
      let dimensions = this.getSelectedDimensions();
      let info = this.getSelectedInfo();
      let dateStart = moment(this.filters.datePicker.dateRange.startDate);
      let dateEnd = moment(this.filters.datePicker.dateRange.endDate);

      let params = {
        export: doExport ? doExport : 0,
        extended_params: this.filters.extended_params ? 1 : 0,
        page: this.paginate.page,
        page_size: this.paginate.limit,
        group_timeframe: this.filters.group_timeframe,
        timezone: this.filters.datePicker.timezone,
        date_start: dateStart.format('YYYY-MM-DD HH:mm'),
        date_end: dateEnd.format('YYYY-MM-DD HH:mm'),
        sort_col: this.sortBy ? this.sortBy.field : '',
        sort_dir: this.sortBy ? this.sortBy.direction : '',
        // user_id: this.filters.user_id,
        cohort: this.filters.cohort ? 1 : 0,
        groups_mode: this.filters.groups_mode,
        filters: {
          subsource_data: this.filters.subsource_data ? 1 : 0,
          has_conversions: this.filters.has_conversions ? 1 : 0,
          advertiser_id: this.filters.advertiser_id.map((r) => r.v),
          publisher_id: this.filters.publisher_id.map((r) => r.v),
          mmp_account_id: this.filters.mmp_account_id.map((r) => r.v),
          complex_offer_id: this.filters.complex_offer_id.map((r) => r.id),
          offer_id: this.filters.offer_id.map((r) => r.id),
          placement_id: this.filters.placement_id.map((r) => r.id),
          sub1_id: this.filters.sub1_id.map((r) => r.id),
          sub2_id: this.filters.sub2_id.map((r) => r.id),
          am_id: this.filters.am_id.map((r) => r.v),
          os_id: this.filters.os_id.map((r) => r.v),
          device_id: this.filters.device_id.map((r) => r.v),
          browser_id: this.filters.browser_id.map((r) => r.v),
          carrier_id: this.filters.carrier_id.map((r) => r.v),
          country: this.filters.country,
          media_type: this.filters.media_type,
        },
        metrics: metrics,
        dimensions: dimensions,
        info: info,
      };

      let maxDimLevel = Math.max(
        ...this.dimensions.filter((d) => d._selected).map((d) => +d.level),
      );
      let dateSpan = dateEnd.diff(dateStart, 'days') + 1;
      let heavyReport = (maxDimLevel > 3 && dateSpan >= 7) || (maxDimLevel === 3 && dateSpan >= 30);

      /** suggest using doExport=3 (background report) in case of a heavy report */
      if (doExport === 1 && heavyReport) {
        let confirmed = await this.$bvModal.msgBoxConfirm(
          'This report is best advised to be exported via "Background Report" due to its dimensions and date span.\nAre you sure you want to proceed?',
        );
        if (confirmed) {
          await this.fetchData(params, doExport);
        }
      } else {
        await this.fetchData(params, doExport);
      }
    },

    async fetchData(params, doExport) {
      try {
        let resp = await this.$ovReq.post('reports/query', params);
        if (doExport === 1 || doExport === 3) {
          const filename = moment().format('DD-MM-YYYY_HH:mm')
          await downloadFile(resp.path, filename)
        } else if (doExport === 2) {
          this.showBackgroundReportsModal();
        } else {
          this.columns = resp.columns;
          this.columns.forEach((c) => {
            Vue.set(c, 'mode', c.mode);
          });
          this.records = resp.records.slice(0, this.paginate.limit);
          this.summary = resp.summary;
          this.paginate.total = resp.total;
          this.resultMeta = {
            dimensions: resp.dimensions,
            metrics: resp.metrics,
          };
          this.paginate.numPages = Math.max(
            Math.ceil(this.paginate.total / this.paginate.limit),
            1,
          );
        }
      } catch (e) {
        console.log(e);
      }
      this.busy = false;
    },

    async loadCustomPresets() {
      this.customPresets = await Vue.ovData.report_preset.getUserPresets();
    },

    createCustomPreset() {
      let preset = Vue.ovData.report_preset.newInstance();
      preset.dimensions = [...this.getSelectedDimensions(), ...this.getSelectedInfo()];
      preset.metrics = this.getSelectedMetrics();
      preset.sort_by = this.sortBy;
      preset.filters = this.filtersOptions.reduce((acc, f) => {
        acc[f.field] = this.filters[f.field];
        return acc;
      }, {});
      delete preset.filters.datePicker;

      this.$modal.show(
        CreatePresetModal,
        {
          preset,
          dateRangeOptions: Object.keys(this.$refs.datePickerRef.datePickerOptions.ranges),
        },
        null,
        {
          'before-close': (/*event*/) => {
            this.loadCustomPresets();
          },
        },
      );
    },

    async deleteCustomPreset(p) {
      let confirmed = await this.$bvModal.msgBoxConfirm(`Delete "${p.name}" preset?`, { title: 'Delete Preset' });
      if (!confirmed) {
        return;
      }
      await this.$ovReq.post('reportPreset/delete', {
        id: p.id,
      });
      this.$ovNotify.success(`"${p.name}" was deleted`);
      await this.loadCustomPresets();
    },

    async togglePresetDefault(p) {
      if (!p.is_default) {
        await this.$ovReq.post('reportPreset/setDefault', {
          id: p.id,
        });
        this.$ovNotify.success(`"${p.name}" was set to default`);
      } else {
        await this.$ovReq.post('reportPreset/removeDefault', {
          id: p.id,
        });
        this.$ovNotify.success(`"${p.name}" was set to default`);
      }
      await this.loadCustomPresets();
    },

    async editCustomPreset(p) {
      this.selectCustomPreset(p, false);
      this.presetDateRangeOptions = Object.keys(this.$refs.datePickerRef.datePickerOptions.ranges);
      let activeFilters = {};
      this.filtersOptions.forEach((f) => {
        activeFilters[f.field] =
          !p.filters.hasOwnProperty(f.field) || p.filters[f.field] !== 'ignore';
      });
      p.activeFilters = activeFilters;
      this.presetEditInstance = p;
    },

    async onPresetDrag() {
      this.presetOrderChange = true;
      this.customPresets.forEach((o, i) => {
        o.display_order = i;
      });
      await this.updatePresetOrder();
    },

    async updatePresetOrder() {
      if (this.presetOrderChange) {
        let presetOrderList = this.customPresets.map((o) => ({
          id: o.id,
          display_order: o.display_order,
        }));
        try {
          await this.$ovReq.post('reportPreset/updateOrder', { presets: presetOrderList });
          this.presetOrderChange = false;
        } catch (e) {
          console.error(e);
        }
      }
    },

    async updateCustomPreset() {
      if (this.busy) {
        return;
      }
      let preset = Vue.util.extend({}, this.presetEditInstance);
      preset.dimensions = this.getSelectedDimensions();
      preset.metrics = this.getSelectedMetrics();
      preset.sort_by = this.sortBy;
      for (let f in preset.activeFilters) {
        preset.filters[f] = preset.activeFilters[f] ? this.filters[f] : 'ignore';
      }
      if (preset.filters.hasOwnProperty('datePicker')) {
        delete preset.filters.datePicker;
      }

      this.busy = true;
      try {
        await this.$ovReq.post('reportPreset/save', preset);
        this.$ovNotify.success('preset has been saved');
        this.presetEditInstance = null;
        await this.loadCustomPresets();
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    selectCustomPreset(p, loadData = true) {
      /** filters */
      for (const f of this.filtersOptions) {
        if (p.filters.hasOwnProperty(f.field)) {
          if (p.filters[f.field] !== 'ignore') {
            this.filters[f.field] = p.filters[f.field];
          }
        } else {
          this.filters[f.field] = f.defaultValue;
        }
      }

      /** sort by */
      this.sortBy = p.sort_by;

      /** date range */
      if (p.date_range) {
        this.$refs.datePickerRef.setRangeByKey(p.date_range);
      }

      /** dimensions */
      this.dimensions.forEach((d) => {
        let selected = p.dimensions.indexOf(d.name) > -1;
        Vue.set(d, '_selected', selected);
        if (d.children) {
          d.children.forEach((cd) => {
            const childName = `${d.name}__${cd.name}`;
            let selected = p.dimensions.includes(childName);
            Vue.set(cd, '_selected', selected);
          });
        }
      });

      /** metrics */
      this.metrics.forEach((d) => {
        let selected = p.metrics.indexOf(d.name) > -1;
        Vue.set(d, '_selected', selected);
        if (d.children) {
          d.children.forEach((cd) => {
            let selected = p.metrics.indexOf(cd.name) > -1;
            Vue.set(cd, '_selected', selected);
          });
        }
      });

      setTimeout(() => {
        if (loadData) {
          this.loadData(0);
        }
      }, 300);
    },

    copyToClipboard() {
      // console.log(this.$refs.table.innerText);
      this.$copyText(this.$refs.table.innerText).then(
        function (e) {
          // alert('Copied');
          console.log(e);
        },
        function (e) {
          // alert('Can not copy');
          console.log(e);
        },
      );
    },
  },
  async mounted() {
    let vm = this;
    vm.busy = true;
    vm.$nextTick(async () => {
      let formInfo = await Vue.ovReq.get('reports/getFormInfo');
      let filterOptions = Vue.ovData.report_preset.getAllFilters();
      let customPresets = await Vue.ovData.report_preset.getUserPresets();
      let advertiserOptions = await Vue.ovData.advertiser.getOptions(true);
      let publisherOptions = await Vue.ovData.publisher.getOptions(true);
      let accountManagerOptions = await Vue.ovData.user.getOptions(true, ['admin', 'am']);
      let mmpAccountOptions = await Vue.ovData.mmpAccount.getOptions(true);

      let selectedOfferOptions = await Vue.ovData.offer.getSelectedOptions(this.offer_id);
      let selectedComplexOptions = await Vue.ovData.offer.getSelectedOptions(this.complex_offer_id);
      let selectedPlacementOptions = await Vue.ovData.placement.getSelectedOptions(
        this.placement_id,
      );

      this.advertiserOptions = advertiserOptions;
      this.publisherOptions = publisherOptions;
      this.filters.offer_id = selectedOfferOptions;
      this.filters.complex_offer_id = selectedComplexOptions;
      this.filters.placement_id = selectedPlacementOptions;
      this.accountManagerOptions = accountManagerOptions;
      this.mmpAccountOptions = mmpAccountOptions;
      this.customPresets = customPresets;
      this.filtersOptions = filterOptions;
      this.filters.user_id = this.$store.state.user.id;
      this.browserOptions = formInfo.options.browser;
      this.osOptions = formInfo.options.os;
      this.deviceOptions = formInfo.options.device;
      this.carrierOptions = formInfo.options.carrier;
      this.blockedErrors = formInfo.blockedErrors;
      this.rejectReasons = formInfo.rejectReasons;
      this.ctitSteps = formInfo.ctitSteps;
      this.dimensions = formInfo.dimensions;
      this.dimensions.forEach((d) => {
        Vue.set(d, '_selected', DEFAULT_DIMENSIONS.includes(d.name));
        Vue.set(d, '_expanded', false);
        this.dimHash[d.name] = d;
      });
      this.metricsHash = {};
      this.metrics = formInfo.metrics;
      this.metrics.forEach((d) => {
        switch (d.name) {
          case 'installs':
            d.link = 'events';
            d.linkParams = { event_name: 'install' };
            break;
          case 'conversions':
            d.link = 'conversions';
            break;
          case 'publisher_conversions':
            d.link = 'pub_conversions';
            break;
          case 'reported_conversions':
            d.link = 'pub_conversions';
            d.linkParams = { reported: 1 };
            break;
          case 'events':
            d.link = 'events';
            break;
          case 'reject_events':
            d.link = 'rejected';
            break;
        }
        Vue.set(d, '_expanded', false);
        Vue.set(d, '_selected', DEFAULT_METRICS.includes(d.name));
        if (d.children) {
          d.children.forEach((c) => {
            Vue.set(c, '_selected', DEFAULT_METRICS.includes(c.name));
            this.metricsHash[c.name] = c;
          });
        }
        this.metricsHash[d.name] = d;
      });

      this.filters.user_id = this.$store.state.user.id;
      if (vm.config && vm.config.loadOnStart) {
        let openedByLink = this.offer_id || this.complex_offer_id || this.placement_id;
        let defaultPreset = this.customPresets.find((p) => p.is_default);
        if (!openedByLink && defaultPreset && !this.date_filters_only) {
          this.selectCustomPreset(defaultPreset);
        } else {
          this.loadData(0);
        }
      }
    });
  },
};
</script>
