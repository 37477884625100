<template lang="pug">
	b-badge.ml-2(variant="info") New
</template>

<script>
export default {
  name: 'NewFeature',
};
</script>

<style scoped></style>
