<template lang="pug">
	div.default-modal
		loading(:active.sync="busy", :is-full-page="true")
		.modal-header
			.modal-title Select {{ complex ? 'Complex Offers' : 'Offers' }}
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			.modal-body-inner
				form.form.form-inline.ov-filters(@submit.prevent="loadData()")
					.form-row-main
						div
							.form-group
								label Search
								input.form-control(type="text", placeholder="Keyword...", v-model="filters.keyword")
							.form-group.fg-advertiser(v-if="!complex")
								label Advertisers ({{ visibleAdvertiserOptions.length }})
									b-checkbox(switch="", v-model="advertiserOptionsArchive")
								multiselect(:multiple="true", :options="visibleAdvertiserOptions", v-model="filters.advertiser_id",
									track-by="v", label="t", deselect-label="", select-label="")
							.form-group
								label Status
								select.form-control(v-model="filters.status")
									option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
							.form-group
								label OS
								select.form-control(v-model="filters.platform")
									option(v-for="o in platformOptions", :value="o.v") {{ o.t }}
							.form-group
								label Bundle(s)
								multiselect(:multiple="true", :options="bundleOptions", v-model="filters.store_bundle_id",
									track-by="name", label="name", deselect-label="", select-label="", @search-change="debounceBundleSearch",
									:loading="bundleOptionsLoading", :internal-search="false")
							.form-group
								label Countries
								input.form-control(type="text", v-model="filters.countries", placeholder="US, GB...")
							.form-group.no-label
								button.btn.btn-primary(type="submit") Go

						div.advanced-filters(v-show="showAdvancedFilters")
							.form-group
								label Bulk Search
								textarea.form-control(placeholder="ID List...", v-model="filters.offer_ids", rows="4")
							.form-group.fg-advertiser
								label Exclude Advertiser(s)
								multiselect(:multiple="true", :options="advertiserOptions", v-model="filters.exclude_advertiser_id",
									track-by="v", label="t", deselect-label="", select-label="")
						div.advanced-filters-link
							a(href="javascript:void(0);", @click="showAdvancedFilters = !showAdvancedFilters") {{ showAdvancedFilters ? 'Hide' : 'Show' }} advanced filters
								i.la(:class="{'la-angle-down':!showAdvancedFilters, 'la-angle-up': showAdvancedFilters}")

					.form-row-end(v-if="multiple")
						.form-group
							label &nbsp;
							button.btn.btn-primary(type="button", @click="submitSelection()") Submit ({{bucket.length}})

				p.clearfix &nbsp;
				.ov-table-wrapper
					.tabs.table-tabs
						ul.nav.nav-tabs
							li.nav-item
								a.nav-link(:class="{active:selectedTab==='search'}", @click="selectedTab='search'") Search ({{ paginate.total }})
							li.nav-item
								a.nav-link(:class="{active:selectedTab==='bucket', highlight: bucket.length > 0}", @click="selectedTab='bucket'") Selected
									span.bucket-size ({{bucket.length}})
					table.table.table-bordered.table-offer-list
						thead
							tr
								th ID
								th Advertiser
								th Name
								th Payout
								th Model
								th Geo
								th OS
								th Status
								th(v-if="multiple")
									span(v-if="selectedTab==='search'")
										a(href="javascript:void(0);", @click="selectAll()") All
										=' | '
									span
										a(href="javascript:void(0);", @click="clearBucket()") Clear
						tbody
							tr(v-if="records.length === 0")
								td(colspan="11") No matching records were found
							tr(v-for="r in (selectedTab==='bucket'?bucket:records)", v-bind:key="r.id", :class="{'row-selected': bucketHash[r.id]}")
								td
									| {{ r.id }} <span class="text-muted">[{{ r.external_id }}]</span>
								td
									entity(:id="r.advertiser_id", :name="r.advertiser_name", type="advertiser")
								td {{ r.name }}
								td {{ r.payout }} {{ r.currency }}
								td {{ r.payout_type }}
								td {{ r.country }}
								td {{ r.platform }}
								td.col-status {{ r.status }}
								td.col-select(:class="{'bucket-row':bucketHash[r.id]}")
									a(href="javascript:void(0)", v-if="multiple", @click="toggleBucket(r)")
										i.la(:class="{'la-check-square':bucketHash[r.id], 'la-stop':!bucketHash[r.id]}")
									a(href="javascript:void(0)", v-else, @click="submitOneOffer(r)")
										i.la.la-plus
					paginate(v-if="selectedTab==='search'", :paginator="paginate", @update-page-size="loadData")


</template>
<style lang="scss"></style>
<script>
import Vue from 'vue';
import { debounce } from 'vue-debounce';

export default {
	name: 'SelectOffersModal',
	props: {
		selectedOffers: {
			type: [Array],
		},
		complex: {
			type: [Boolean],
			default() {
				return false;
			},
		},
		multiple: {
			type: [Boolean],
		},
	},
	data() {
		return {
			records: [],
			busy: false,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 20,
				onPageChange: () => {
					this.loadData();
				},
			},
			showAdvancedFilters: false,
			selectedTab: 'search',
			bucket: [],
			bucketHash: {},
			filters: {
				advertiser_id: [],
				exclude_advertiser_id: [],
				keyword: '',
				platform: 'all',
				status: 'live',
				validation_status: 'all',
				countries: '',
				payout_model: 'all',
				store_bundle_id: [],
			},
			statusOptions: [
				{ v: 'all', t: 'All' },
				{ v: 'draft', t: 'Draft' },
				{ v: 'live', t: 'Live' },
				{ v: 'paused', t: 'Paused' },
				{ v: 'cancelled', t: 'Cancelled' },
				// {v: 'ended', t: 'Ended'}
			],
			platformOptions: [
				{ v: 'all', t: 'All' },
				{ v: 'android', t: 'Android' },
				{ v: 'ios', t: 'iOS' },
				{ v: 'mobile_web', t: 'MobileWeb' },
				{ v: 'desktop', t: 'Desktop' },
			],
			advertiserOptionsArchive: false,
			advertiserOptions: [],
			sortBy: {
				field: 'id',
				direction: 'desc',
				sort: null,
			},
			bundleOptionsLoading: false,
			bundleOptions: [],
		};
	},
	async mounted() {
		this.advertiserOptions = await Vue.ovData.advertiser.getOptions(false);
		if (this.selectedOffers && this.selectedOffers.length) {
			let offerIds = this.selectedOffers.map((r) => r.id);
			let resp = await this.$ovReq.get('offer/getList', { params: { id: offerIds.join(',') } });
			resp.records.forEach((r) => {
				r.country = r.country.join(', ');
				this.toggleBucket(r);
			});
		}
		// this.loadData();
		this.debounceBundleSearch = debounce(this.searchBundle.bind(this), 300);
	},
	computed: {
		USER() {
			return this.$store.state.user;
		},
		visibleAdvertiserOptions() {
			return this.advertiserOptions.filter((a) => {
				return this.advertiserOptionsArchive || a.status;
			});
		},
	},
	methods: {
		submitSelection() {
			while (this.selectedOffers.length) {
				this.selectedOffers.splice(0, 1);
			}
			this.bucket.forEach((r) => {
				this.selectedOffers.push({
					id: r.id,
					name: r.name,
					advertiser_id: r.advertiser_id,
					advertiser_name: r.advertiser_name,
				});
			});
			this.$emit('close', this.bucket);
		},
		submitOneOffer(r) {
			this.bucket = [r];
			this.submitSelection();
		},
		clearBucket() {
			this.bucket = [];
			this.bucketHash = {};
		},
		selectAll() {
			this.records.forEach((r) => {
				if (!this.bucketHash[r.id]) {
					Vue.set(this.bucketHash, r.id, true);
					this.bucket.push(r);
				}
			});
		},
		toggleBucket(r) {
			if (this.bucketHash[r.id]) {
				Vue.set(this.bucketHash, r.id, false);
				for (let i = 0; i < this.bucket.length; i++) {
					if (this.bucket[i].id === r.id) {
						this.bucket.splice(i, 1);
						return;
					}
				}
			} else {
				Vue.set(this.bucketHash, r.id, true);
				this.bucket.push(r);
			}
		},
		async loadData() {
			this.busy = true;
			let params = {
				page: this.paginate.page,
				page_size: this.paginate.limit,
				keyword: this.filters.keyword,
				advertiser_id: this.filters.advertiser_id.map((r) => r.v).join(','),
				exclude_advertiser_id: this.filters.exclude_advertiser_id.map((r) => r.v).join(','),
				has_blacklist: this.filters.has_blacklist ? 1 : 0,
				has_whitelist: this.filters.has_whitelist ? 1 : 0,
				countries: this.filters.countries,
				status: this.filters.status,
				validation_status: this.filters.validation_status,
				platform: this.filters.platform,
				payout_model: this.filters.payout_model,
				sort_col: this.sortBy.field,
				sort_dir: this.sortBy.direction,
				offer_ids: this.filters.offer_ids,
				offer_type: this.complex ? 'complex' : null,
				store_bundle_id: this.filters.store_bundle_id.map((r) => r.name).join(','),
			};
			try {
				let resp = await this.$ovReq.get('offer/getList', { params });
				this.records = resp.records;
				this.records.forEach((r) => {
					r.country = r.country.join(', ');
				});
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		},
		async searchBundle(query) {
			this.bundleOptionsLoading = true;
			try {
				this.bundleOptions = await Vue.ovData.offer.getBundleOptions({ keyword: query });
			} catch (e) {
				console.error(e);
			}
			this.bundleOptionsLoading = false;
		},
	},
};
</script>
